import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export const DifficultyLevel = (props: SvgProps) => (
  <Svg {...props} width={16} height={16} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="m10.667 4 1.526 1.527L8.94 8.78 6.273 6.113l-4.94 4.947.94.94 4-4 2.667 2.667 4.2-4.194L14.667 8V4h-4Z"
        fill="#A6C1B8"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
