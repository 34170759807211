import { StackScreenProps } from '@react-navigation/stack';
import { useCallback } from 'react';
import { useGlobalSettings } from '../../../../components/global-settings';
import { NextButton, TourScreen } from '../../components';
import { WelcomeNavigatorParamsList } from '../../WelcomeNavigatorParamsList';
import imageSource from './image.png';

export type PsychologyScreenProps = StackScreenProps<
  WelcomeNavigatorParamsList,
  'psychology'
>;

export const PsychologyScreen = ({
  navigation
}: PsychologyScreenProps): JSX.Element => {
  const settings = useGlobalSettings();

  const signIn = useCallback(() => {
    settings.set('tourComplete', true);
    navigation.navigate('signIn');
  }, [settings]);

  return (
    <TourScreen
      pageNumber={3}
      imageSource={imageSource}
      title="Plan your meals"
      body="Life is busy, and staying organised when it comes to your meals is the key to successful weight loss and saving money. Stay on top of what you’re eating and when with our easy to use Planner."
    >
      <NextButton title="Sign up now!" onNext={signIn} />
    </TourScreen>
  );
};
