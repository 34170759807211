export const stringValue = (defaultValue: string | null) => ({
  _type: 'string' as const,
  defaultValue
});
export const stringEnumValue = <T extends string | null>(defaultValue: T) => ({
  _type: 'string' as const,
  defaultValue
});
export const mapValue = <T>(defaultValue: T | null) => ({
  _type: 'map' as const,
  defaultValue
});
export const numberValue = { _type: 'number' as const, defaultValue: 0 };
export const arrayValue = { _type: 'array' as const, defaultValue: [] };
export const boolValue = { _type: 'boolean' as const, defaultValue: false };

export type AnyValue =
  | ReturnType<typeof stringValue>
  | ReturnType<typeof stringEnumValue>
  | ReturnType<typeof mapValue>
  | typeof numberValue
  | typeof arrayValue
  | typeof boolValue;

export interface KeyValueStore<TModel extends Record<string, AnyValue>> {
  get<K extends keyof TModel>(key: K): TModel[K]['defaultValue'] | null;
  set<K extends keyof TModel>(key: K, value: TModel[K]['defaultValue']): void;
  clearAll(): void;
}
