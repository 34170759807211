import { useContext, useMemo } from 'react';
import { ApiContext } from './ApiContext';
import { ApiProviderProps } from './ApiProviderProps';

export const ApiProvider = ({
  children,
  options: newOptions,
  inheritOptions = true
}: ApiProviderProps): JSX.Element => {
  const parent = useContext(ApiContext);

  const value = useMemo(
    () => ({
      options: { ...(inheritOptions ? parent?.options : {}), ...newOptions }
    }),
    [newOptions, inheritOptions, parent]
  );

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};
