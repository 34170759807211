import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export const Cuisine = (props: SvgProps) => (
  <Svg {...props} width={16} height={16} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M13.107 9.334c-.44 1.28-1.494 2.36-2.934 2.927l-.84.333v.74H6.667v-.74l-.847-.333c-1.44-.567-2.493-1.647-2.933-2.927h10.22Zm1.56-8-12 1.327V8H1.333c0 2.46 1.647 4.573 4 5.5v1.166h5.334v-1.166c2.353-.927 4-3.04 4-5.5H7V5.334h7.667v-1H7V3.187l7.667-.846V1.334Zm-9.334 3v-.96L6 3.301v1.033h-.667Zm-1.666 0v-.773l.666-.074v.847h-.666Zm1.666 3.667V5.334H6v2.667h-.667ZM3.667 8V5.334h.666v2.667h-.666Z"
        fill="#A6C1B8"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
