import { useMemo } from 'react';
import {
  RefreshControl,
  ScrollViewProps,
  useWindowDimensions
} from 'react-native';
import Animated from 'react-native-reanimated';
import { Colours } from '../../colours';
import { nameof } from '../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet,
  useSizeClass
} from '../../lib/responsive-style-sheet';
import { thenThrow } from '../../lib/then-throw';
import { useRecipesResourceList } from '../../resources';
import { RecipeTile } from '../recipe-tile/RecipeTile';
import { FilterHint } from './FilterHint';
import { mapRecipeTileData } from './mapRecipeTileData';
import { NoResults } from './NoResults';

const rStyles = ResponsiveStyleSheet.create({
  filterHintContainer: {
    backgroundColor: Colours.white.$
  }
})
  .override(
    { small: true },
    {
      recipes: {
        padding: 8,
        paddingTop: 16
      },
      tile: {
        margin: 8
      }
    }
  )
  .override(
    { medium: true },
    {
      recipes: {
        padding: 60
      },
      tile: {
        flexBasis: '50%'
      }
    }
  )
  .override(
    { large: true },
    {
      recipes: {
        padding: 65
      },
      tile: {
        flexBasis: 338,
        margin: 16
      }
    }
  );

export type RecipeListProps = Omit<ScrollViewProps, 'children'> & {
  onPress: (recipeId: number) => void;
  filterText?: string;
  filterActive?: boolean;
};

export const RecipeList = ({
  style,
  onPress,
  filterText,
  filterActive = false,
  ...rest
}: RecipeListProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const size = useSizeClass();

  const { width } = useWindowDimensions();

  const { inProgress, reload, recipes } = useRecipesResourceList();

  const filteredRecipes = useMemo(() => {
    const terms = filterText
      ? filterText?.toLocaleLowerCase().trim().split(' ')
      : undefined;
    return !terms || terms.none()
      ? []
      : recipes?.filter(r => {
          const searchableContent = [
            r.attributes?.dishName,
            r.attributes?.mealType
          ]
            .compact()
            .join(' ')
            .toLocaleLowerCase();

          return terms.every(t => searchableContent.includes(t));
        }) ?? [];
  }, [recipes, filterText]);

  const numColumns = useMemo(() => {
    if (size === 'medium') return 2;
    if (size === 'large') {
      return Math.floor((width - 346) / 370);
    }

    return 1;
  }, [size, width]);

  if (filterActive && filteredRecipes.length === 0) {
    return (
      <Animated.ScrollView
        testID={nameof({ RecipeList })}
        style={[style, styles.filterHintContainer]}
        contentContainerStyle={styles.recipes}
        refreshControl={
          <RefreshControl
            refreshing={inProgress}
            onRefresh={reload}
            tintColor={Colours.primaryDark75.$}
            colors={[Colours.primaryDark75.$]}
          />
        }
        {...rest}
      >
        {filterText ? <NoResults /> : <FilterHint />}
      </Animated.ScrollView>
    );
  }

  return (
    <Animated.FlatList
      testID={nameof({ RecipeList })}
      style={style}
      contentContainerStyle={styles.recipes}
      data={filterActive ? filteredRecipes : recipes}
      keyExtractor={recipe => String(recipe.id)}
      contentInsetAdjustmentBehavior="automatic"
      numColumns={numColumns}
      key={numColumns}
      refreshControl={
        <RefreshControl
          refreshing={inProgress}
          onRefresh={reload}
          tintColor={Colours.primaryDark75.$}
          colors={[Colours.primaryDark75.$]}
        />
      }
      renderItem={recipe => (
        <RecipeTile
          key={recipe.item.id}
          style={styles.tile}
          recipe={mapRecipeTileData(recipe.item)}
          onPress={() =>
            onPress(recipe.item.id ?? thenThrow('Missing recipe id!'))
          }
        />
      )}
      {...rest}
    />
  );
};
