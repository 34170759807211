import { StyleSheet, Text } from 'react-native';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import { Tag } from '../tag';
import { TagIcon } from '../tag-icons';
import { RecipeTagProps } from './RecipeTagProps';

const styles = StyleSheet.create({
  text: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 12,
    lineHeight: 18
  },
  solid: {
    color: Colours.accentLight75.$
  },
  nonSolid: {
    color: Colours.accentLight35.$
  }
});

export const PrepAndCookingTime = ({
  recipe: { cookTime, prepTime },
  ...rest
}: RecipeTagProps): JSX.Element => {
  const timeValues = [
    prepTime ? `Prep: ${prepTime} mins` : undefined,
    cookTime ? `Cook: ${cookTime} mins` : undefined
  ].compact();

  if (timeValues.none()) return <></>;

  return (
    <Tag iconComponent={TagIcon.Time} {...rest}>
      {timeValues
        .map((v, index) => (
          <Text key={index} style={[styles.text, styles.solid]}>
            {v}
          </Text>
        ))
        .interleave(() => (
          <Text key="join" style={[styles.text, styles.nonSolid]}>
            &nbsp;|&nbsp;
          </Text>
        ))}
    </Tag>
  );
};
