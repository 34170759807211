import { createStackNavigator } from '@react-navigation/stack';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import { MoreInfoNavigatorParamList } from './MoreInfoNavigatorParamList';
import { DeleteAccountScreen } from './screens/delete-account/DeleteAccountScreen';
import { IndexScreen } from './screens/index/IndexScreen';

const Stack = createStackNavigator<MoreInfoNavigatorParamList>();
export const MoreInfoNavigator = (): JSX.Element => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerTitleAlign: 'center',
        headerTitleStyle: {
          fontFamily: Fonts.DMSans_700Bold,
          fontSize: 20,
          color: Colours.accentLight100.$,
          letterSpacing: -0.2
        },
        headerStyle: {
          elevation: 0,
          shadowOpacity: 0,
          borderBottomWidth: 0
        },
        headerBackTitleVisible: false,
        headerTintColor: Colours.accentLight100.$
      }}
    >
      <Stack.Screen
        name="index"
        component={IndexScreen}
        options={{ title: 'More information' }}
      />
      <Stack.Screen
        name="delete-account"
        component={DeleteAccountScreen}
        options={{ title: 'Delete Account' }}
      />
    </Stack.Navigator>
  );
};
