import { useMemo } from 'react';
import { Image, ImageProps } from 'react-native';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { useRecipeContext } from '../context';

export type HeroImageProps = Omit<ImageProps, 'source'>;

const rStyles = ResponsiveStyleSheet.create({})
  .override(
    { small: true },
    {
      image: {
        width: '100%',
        maxWidth: 600,
        aspectRatio: 16 / 9,
        marginBottom: 20,
        alignSelf: 'center'
      }
    }
  )
  .override(
    { medium: true, large: true },
    { image: { height: 320, width: 320, borderRadius: 19 } }
  );

export const HeroImage = ({ style, ...rest }: HeroImageProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const { recipe } = useRecipeContext();

  const formats = useMemo(() => {
    const data = recipe?.attributes?.mainImage?.data?.attributes?.formats;

    if (data && typeof data === 'object' && 'small' in data) {
      return data as {
        small: {
          url: string;
        };
      };
    }

    return undefined;
  }, [recipe]);

  return (
    <Image
      {...rest}
      source={{
        uri: formats?.small.url
      }}
      style={[styles.image, style]}
    />
  );
};
