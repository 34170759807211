import { Cuisine } from './Cuisine';
import { DifficultyLevel } from './DifficultyLevel';
import { FamilyFriendly } from './FamilyFriendly';
import { Freezer } from './Freezer';
import { PriceGuide } from './PriceGuide';
import { ServingSize } from './ServingSize';
import { Time } from './Time';
import { Timer } from './Timer';

export const TagIcon = {
  Cuisine,
  DifficultyLevel,
  FamilyFriendly,
  Freezer,
  PriceGuide,
  ServingSize,
  Time,
  Timer
};
