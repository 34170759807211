import { StackScreenProps } from '@react-navigation/stack';
import { useMemo } from 'react';
import { RefreshControl, ScrollView } from 'react-native';
import { range } from 'ts-array-extensions';
import XDate from 'xdate';
import { Colours } from '../../../../colours';
import { nameof } from '../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet,
  useSizeClass
} from '../../../../lib/responsive-style-sheet';
import { usePlanResourceLoader } from '../../../../resources';
import { PlannerNavigatorParamList } from '../../PlannerNavigatorParamList';
import { PlanDay } from './components';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colours.white.$
  },
  content: {
    alignItems: 'stretch'
  }
}).override(
  { medium: true, large: true },
  {
    content: {
      paddingHorizontal: 52,
      paddingVertical: 16
    },
    day: {
      width: 314,
      marginHorizontal: 8
    }
  }
);

export type PlanScreenProps = StackScreenProps<
  PlannerNavigatorParamList,
  'plan'
>;

export const PlanScreen = ({ route }: PlanScreenProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const size = useSizeClass();

  const { fromDate, toDate, dates } = useMemo(() => {
    const length = size === 'small' ? 0 : 6;

    const from = route.params.date ?? new Date().toISOString().substring(0, 10);

    const to = new XDate(from).addDays(length).toISOString().substring(0, 10);

    const all = range(0, length).map(d =>
      new XDate(from).addDays(d).toISOString().substring(0, 10)
    );

    return { fromDate: from, toDate: to, dates: all };
  }, [size, route]);

  const { reload, inProgress } = usePlanResourceLoader({ fromDate, toDate });

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      testID={nameof({ PlanScreen })}
      horizontal={size !== 'small'}
      refreshControl={
        <RefreshControl
          refreshing={inProgress}
          onRefresh={reload}
          tintColor={Colours.primaryDark75.$}
          colors={[Colours.primaryDark75.$]}
        />
      }
    >
      {dates.map(date => (
        <PlanDay style={styles.day} key={date} date={date} />
      ))}
    </ScrollView>
  );
};
