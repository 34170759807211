import { useContext } from 'react';
import { nameof } from '../../lib/name-of';
import { DeviceInfoManagerContext } from './DeviceInfoManagerContext';

export const useDeviceInfo = () => {
  const hookValue = useContext(DeviceInfoManagerContext);
  if (!hookValue) {
    throw new Error(
      `${nameof({ useDeviceInfo })}() hook requires a ${nameof({
        DeviceInfoManagerContext
      })} within the component hierarchy.`
    );
  }
  return hookValue;
};
