import { assertNever } from '../../../../../../lib/assert-never';
import { PlanResource } from '../../../../../../resources';

export const titleForMeal = (meal: keyof PlanResource['meals']): string => {
  switch (meal) {
    case 'breakfast':
      return 'Breakfast';
    case 'lunch':
      return 'Lunch';
    case 'dinner':
      return 'Dinner';
    default:
      return assertNever(meal);
  }
};
