import { NavigationProp, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useGlobalSettings } from '../../components/global-settings';
import { useSession } from '../../components/user-manager';
import { createLogger } from '../../lib/logging';
import { nameof } from '../../lib/name-of';
import { HeaderBackButton, HeaderSkipButton } from './components';
import {
  ReadyScreen,
  HeadlinesScreen,
  RecipesScreen,
  PsychologyScreen,
  SignInScreen,
  EmailSentScreen,
  SignInMagicScreen
} from './screens';
import { ChoosePlanScreen } from './screens/choose-plan/ChoosePlanScreen';
import { WelcomeNavigatorParamsList } from './WelcomeNavigatorParamsList';

const Stack = createStackNavigator<WelcomeNavigatorParamsList>();

export const WelcomeNavigator = (): JSX.Element => {
  const log = createLogger(nameof({ WelcomeNavigator }));

  const settings = useGlobalSettings();

  const [init, setInit] = useState(true);

  const navigation =
    useNavigation<NavigationProp<WelcomeNavigatorParamsList>>();

  const { currentSession } = useSession();

  useEffect(() => {
    if (!init) {
      return;
    }

    const tourComplete = settings.get('tourComplete');

    if (tourComplete) {
      log.info('Navigating for tour completed');

      navigation.navigate('signIn');
    }

    setInit(false);
  }, [settings, init]);

  return (
    <Stack.Navigator>
      {Platform.OS !== 'web' && !currentSession && (
        <>
          <Stack.Screen
            name="ready"
            component={ReadyScreen}
            options={{ headerShown: false }}
          />
          <Stack.Group
            screenOptions={{
              headerTransparent: true,
              headerTitle: '',
              headerLeft: HeaderBackButton,
              headerRight: HeaderSkipButton
            }}
          >
            <Stack.Screen name="headlines" component={HeadlinesScreen} />
            <Stack.Screen name="recipes" component={RecipesScreen} />
            <Stack.Screen name="psychology" component={PsychologyScreen} />
          </Stack.Group>
        </>
      )}
      <Stack.Group
        screenOptions={{
          headerTransparent: true,
          headerTitle: '',
          headerLeft: HeaderBackButton
        }}
      >
        {!currentSession ? (
          <>
            <Stack.Screen name="signIn" component={SignInScreen} />
            <Stack.Screen name="emailSent" component={EmailSentScreen} />
            <Stack.Screen name="signInMagic" component={SignInMagicScreen} />
          </>
        ) : (
          <Stack.Screen name="choosePlan" component={ChoosePlanScreen} />
        )}
      </Stack.Group>
    </Stack.Navigator>
  );
};
