import { createContext, ReactNode, useContext } from 'react';
import { nameof } from '../name-of';

export const LaunchArgumentsContext = createContext<unknown>(null);

export function LaunchArgumentsContextProvider<T>({
  children,
  value
}: {
  children: ReactNode;
  value: T;
}): JSX.Element {
  return (
    <LaunchArgumentsContext.Provider value={value}>
      {children}
    </LaunchArgumentsContext.Provider>
  );
}

export function useLaunchArguments<T>() {
  const hookValue = useContext(LaunchArgumentsContext);
  if (!hookValue) {
    throw new Error(
      `${nameof({ useLaunchArguments })}() hook requires a ${nameof({
        LaunchArgumentsContext
      })} within the component hierarchy.`
    );
  }
  return hookValue as T;
}
