import { useSizeClass } from '../../lib/responsive-style-sheet';
import { LargeMainNavigator } from './LargeMainNavigator';
import { SmallMainNavigator } from './SmallMainNavigator';

export const MainNavigator = (): JSX.Element => {
  const size = useSizeClass();

  if (size === 'small') {
    return <SmallMainNavigator />;
  }

  return <LargeMainNavigator />;
};
