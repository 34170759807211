import { StyleSheet, Text } from 'react-native';
import { Tag } from '../tag';
import { RecipeTagProps } from './RecipeTagProps';
import { Fonts } from '../../fonts';
import { Colours } from '../../colours';

const styles = StyleSheet.create({
  text: {
    color: Colours.accentLight75.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 12,
    lineHeight: 18
  }
});

export const DietSplit = ({
  recipe: { dietaryRequirementCodes },
  ...rest
}: RecipeTagProps): JSX.Element => {
  if (dietaryRequirementCodes.none()) return <></>;

  return (
    <>
      {dietaryRequirementCodes.map(code => (
        <Tag key={code} {...rest}>
          <Text style={styles.text}>{code}</Text>
        </Tag>
      ))}
    </>
  );
};
