import Svg, { SvgProps, Path } from 'react-native-svg';

export const ChevronIcon = (props: SvgProps) => (
  <Svg {...props} width={9} height={14} fill="none">
    <Path
      d="M7.667 1.666 2.333 6.999l5.334 5.334"
      stroke="#004E34"
      strokeWidth={2}
      translateX={-1}
      translateY={1}
    />
  </Svg>
);
