import {
  Image,
  Pressable,
  PressableProps,
  StyleSheet,
  View,
  ViewStyle
} from 'react-native';
import { Colours } from '../../../../../../colours';
import { SubtractIcon } from '../../../../../../components/icons';
import { RecipeTileData } from '../../../../../../components/recipe-tile';
import { RecipeTileTags } from '../../../../../../components/recipe-tile-tags';
import { Text } from '../../../../../../components/text';
import { nameof } from '../../../../../../lib/name-of';
import { IconBubble } from '../../../../components';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colours.white.$,
    borderRadius: 15,
    padding: 8
  },
  imageAndTitleContainer: {
    flexDirection: 'row'
  },
  image: {
    borderRadius: 10,
    width: 82,
    height: 82,
    margin: 4
  },
  title: {
    color: Colours.primaryDark55.$,
    margin: 8,
    marginVertical: 19,
    flexWrap: 'wrap',
    flex: 1
  },
  tags: {
    margin: 6
  }
});

export type RecipeCardProps = Omit<PressableProps, 'children'> & {
  recipe: RecipeTileData;
  onRemovePressed: () => void;
};

export const RecipeCard = ({
  recipe,
  style,
  onRemovePressed,
  ...rest
}: RecipeCardProps): JSX.Element => {
  return (
    <Pressable
      style={[styles.container, style as ViewStyle]}
      {...rest}
      testID={nameof({ RecipeCard })}
    >
      <View style={styles.imageAndTitleContainer}>
        <Image
          source={{
            uri: recipe.mainImageUrl
          }}
          style={[styles.image]}
        />
        <Text.Body style={styles.title} accessibilityRole="header">
          {recipe.dishName}
        </Text.Body>
        <Pressable testID="SubtractButton" onPress={onRemovePressed}>
          <IconBubble iconComponent={SubtractIcon} />
        </Pressable>
      </View>
      <RecipeTileTags style={styles.tags} recipe={recipe} />
    </Pressable>
  );
};
