import { Pressable, PressableProps, StyleSheet } from 'react-native';
import { CloseIcon } from '../../../../components/icons';
import { IconBubble } from '../../components';
import { nameof } from '../../../../lib/name-of';

const styles = StyleSheet.create({
  icon: {}
});

export type CloseButtonProps = Omit<PressableProps, 'children'>;

export const CloseButton = (props: CloseButtonProps): JSX.Element => (
  <Pressable testID={nameof({ CloseButton })} {...props}>
    <IconBubble style={styles.icon} iconComponent={CloseIcon} />
  </Pressable>
);
