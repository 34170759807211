import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PubSubBrokerContext } from '../pub-sub/PubSubBroker';
import { table } from './table';
import { IdFields } from './types';

export const useTable = <
  TModel extends Record<string, unknown>,
  K extends IdFields<TModel>
>(
  name: string,
  keyField: K
) => {
  const store = useMemo(() => table<TModel>(name, keyField), [name, keyField]);

  const [trigger, setTrigger] = useState(0);

  const rows = useMemo(() => store.readAll(), [store, trigger]);

  const broker = useContext(PubSubBrokerContext);

  if (!broker) {
    throw new Error('No PubSubBrokerContext');
  }

  const subject = useMemo(
    () => broker.getOrCreateSubject(`table-${name}`),
    [broker, name]
  );

  useEffect(
    () =>
      subject.subscribe({
        next: () => setTrigger(t => t + 1)
      }),
    [subject, setTrigger]
  );

  const update = useCallback<typeof store['update']>(
    (key, fn) => {
      const newValue = store.update(key, fn);
      subject.next(null);
      broker.getOrCreateSubject(`table-${name}-${String(key)}`).next(newValue);
      return newValue;
    },
    [subject, store]
  );

  const deleteOne = useCallback<typeof store['delete']>(
    key => {
      store.delete(key);
      subject.next(null);
      broker.getOrCreateSubject(`table-${name}-${String(key)}`).next(null);
    },
    [subject, store]
  );

  return { rows, update, delete: deleteOne };
};
