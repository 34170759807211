import { HeaderButtonProps } from '@react-navigation/elements';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { Colours } from '../../../../colours';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { WelcomeNavigatorParamsList } from '../../WelcomeNavigatorParamsList';
import { MiniButton } from '../../../../components/mini-button';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    marginRight: 24,
    backgroundColor: Colours.primaryLight55.$
  }
}).override(
  { medium: true, large: true },
  {
    container: {
      marginRight: 45,
      backgroundColor: Colours.transparent.$,
      borderColor: Colours.accentLight100.$,
      borderWidth: 1
    }
  }
);

export const HeaderSkipButton = (props: HeaderButtonProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const navigation =
    useNavigation<NavigationProp<WelcomeNavigatorParamsList>>();

  const onSkip = useCallback(() => navigation.navigate('signIn'), [navigation]);

  return (
    <MiniButton
      title="Skip"
      {...props}
      style={styles.container}
      onPress={onSkip}
    />
  );
};
