import { StackScreenProps } from '@react-navigation/stack';
import { Image, Linking, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useCallback } from 'react';
import breakfast from './breakfast.png';
import lunch from './lunch.png';
import dinner from './dinner.png';
import facebook from './facebook.png';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { Text } from '../../../../components/text';
import { MainIconDark } from '../../../../components/icons';
import { PrimaryButton } from '../../../../components/primary-button';
import { HomeNavigatorParamsList } from '../../HomeNavigatorParamList';

export type HomeScreenProps = StackScreenProps<
  HomeNavigatorParamsList,
  'homepage'
>;

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    alignItems: 'center'
  },

  text: {
    fontSize: 18,
    textAlign: 'center',
    color: 'rgba(0, 78, 52, 1)'
  },
  meals: {
    backgroundColor: '#ebfaf2',
    borderWidth: 1,
    borderColor: 'rgba(68, 222, 171, 0.42)',
    borderRadius: 16,
    alignItems: 'center'
  },
  topImages: {
    flexDirection: 'row'
  },
  imageTitle: {
    textAlign: 'center',

    color: 'rgba(0, 78, 52, 1)'
  },
  topImage: { borderRadius: 16 },
  bottomImage: {
    borderRadius: 16
  },
  button: { backgroundColor: '#00de95' },
  facebookContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ebfaf2',
    borderRadius: 16
  },
  facebookLogo: { width: 34, height: 34, marginRight: 10 },
  facebookText: {
    color: 'black'
  }
})
  .override(
    { small: true },

    {
      container: {
        justifyContent: 'center'
      },
      text: {
        maxWidth: '92%',
        marginBottom: '5%'
      },
      meals: {
        flex: 0.96,
        height: '65%',
        width: '93%',
        marginBottom: 20
      },
      mealContent: {
        alignItems: 'center',
        height: '86%'
      },
      topImages: {
        height: '35%',
        marginBottom: '10%'
      },
      topImage: {
        height: '100%',
        marginHorizontal: 6
      },
      button: {
        width: 315,
        paddingVertical: '2%'
      },
      bottomImage: {
        height: '100%'
      },
      bottomImageContainer: {
        height: '40%'
      },
      facebookContainer: {
        width: 310,
        marginTop: 1,
        paddingVertical: 6
      }
    }
  )
  .override(
    {
      medium: true,
      large: true
    },

    {
      container: {
        justifyContent: 'space-evenly'
      },
      meals: { width: '93%' },
      mealContent: {
        flexDirection: 'row',
        padding: 50
      },
      topImage: {
        width: 260,
        height: 280,
        marginHorizontal: 38
      },
      bottomImage: {
        width: 260,
        height: 280,
        marginHorizontal: 38
      },
      button: {
        width: 400,
        marginBottom: 40
      },
      facebookContainer: { width: 310, paddingVertical: 8 }
    }
  );
export const HomeScreen = ({ navigation }: HomeScreenProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);
  const goToPlanner = () => {
    navigation.getParent()?.navigate('planner', { screen: 'plan', params: {} });
  };

  const url = 'https://www.facebook.com/profile.php?id=100088062704555';
  const goToFacebookMobile = useCallback(async () => {
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      await Linking.openURL(url);
    }
  }, [url]);
  const goToFacebook = () => {
    void goToFacebookMobile();
  };

  return (
    <SafeAreaView style={styles.container}>
      <MainIconDark />
      <Text.Body style={styles.text}>
        Plan for a healthier you with hundreds of tasty, healthy, easy to
        prepare recipes.
      </Text.Body>
      <View style={styles.meals}>
        <View style={styles.mealContent}>
          <View style={styles.topImages}>
            <View>
              <Text.Body style={styles.imageTitle}>Breakfast (100+)</Text.Body>
              <Image source={breakfast} style={styles.topImage} />
            </View>
            <View>
              <Text.Body style={styles.imageTitle}>Lunch (120+)</Text.Body>
              <Image source={lunch} style={styles.topImage} />
            </View>
          </View>
          <View style={styles.bottomImageContainer}>
            <Text.Body style={styles.imageTitle}>Dinner (100+)</Text.Body>
            <Image source={dinner} style={styles.bottomImage} />
          </View>
        </View>
        <PrimaryButton
          title="Start planning your meals now"
          style={styles.button}
          onPress={goToPlanner}
        />
      </View>
      <TouchableOpacity style={styles.facebookContainer} onPress={goToFacebook}>
        <Image source={facebook} style={styles.facebookLogo} />
        <Text.Body style={styles.facebookText}>Join our community</Text.Body>
      </TouchableOpacity>
    </SafeAreaView>
  );
};
