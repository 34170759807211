import { StatusBar, StatusBarStyle } from 'expo-status-bar';
import { ReactNode } from 'react';
import { Image, View, ImageSourcePropType } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colours } from '../../../../colours';
import { Text } from '../../../../components/text';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet,
  useSizeClass
} from '../../../../lib/responsive-style-sheet';
import { Progress } from '../progress';

export type TourScreenProps = {
  body: string;
  imageSource: ImageSourcePropType;
  pageNumber: number;
  smallStatusBarStyle?: StatusBarStyle;
  title: string;
  children: ReactNode;
};

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    backgroundColor: Colours.primaryLight35.$
  },
  toolbarContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  toolbar: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    color: Colours.accentLight100.$,
    textAlign: 'center'
  },
  body: {
    color: Colours.accentLight75.$,
    textAlign: 'center',
    marginBottom: 40
  },
  card: {
    backgroundColor: Colours.primaryLight5.$,
    paddingHorizontal: 50,
    paddingVertical: 30,
    display: 'flex',
    alignItems: 'stretch'
  },
  button: {
    backgroundColor: Colours.primaryLight100.$
  },
  image: {
    flexGrow: 1,
    resizeMode: 'cover'
  }
})
  .override(
    { small: true },
    {
      button: {
        alignSelf: 'stretch',
        marginHorizontal: 28
      },
      image: {
        flexShrink: 1,
        marginBottom: -50,
        width: '100%',
        position: 'relative'
      },
      card: {
        flexGrow: 0,
        flexShrink: 0,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
      }
    }
  )
  .override(
    { medium: true, large: true },
    {
      container: {
        flexDirection: 'row',
        padding: 150
      },
      image: {
        flexBasis: 50,
        marginRight: -20,
        resizeMode: 'cover',
        height: '100%',
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20
      },
      card: {
        borderRadius: 20,
        flexBasis: 50,
        flexGrow: 1,
        justifyContent: 'center'
      }
    }
  );

export const TourScreen = ({
  body,
  children,
  imageSource,
  pageNumber,
  smallStatusBarStyle = 'light',
  title
}: TourScreenProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);
  const size = useSizeClass();

  return (
    <View style={styles.container}>
      <StatusBar style={size === 'small' ? smallStatusBarStyle : 'dark'} />
      <Image source={imageSource} style={styles.image} />
      <View style={styles.card}>
        <SafeAreaView edges={['bottom']}>
          <Progress pageNumber={pageNumber} />
          <Text.Title style={styles.title}>{title}</Text.Title>
          <Text.Body style={styles.body}>{body}</Text.Body>
          {children}
        </SafeAreaView>
      </View>
    </View>
  );
};
