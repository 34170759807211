import { AsyncResult, fail, success } from '@laurence79/ts-results';
import { createLogger } from '../logging';
import { nameof } from '../name-of';
import { retryPromise } from '../retry-promise';
import { timeout } from '../timeout-promise';
import { TimeoutError } from '../timeout-promise/TimeoutError';

export type ResilientFailureType = 'TIMEOUT' | 'ABORTED' | Error;

export const resilient = async <TResponse>(
  fn: () => Promise<TResponse>,
  options?: {
    retries?: number;
    timeoutMs?: number;
    signal?: AbortSignal;
  }
): Promise<AsyncResult<TResponse, ResilientFailureType>> => {
  const log = createLogger(nameof({ resilient }));

  try {
    const result = await retryPromise(
      () => timeout(options?.timeoutMs ?? 5000, fn()),
      {
        maxAttempts: options?.retries,
        signal: options?.signal
      }
    );

    return success(result);
  } catch (e: unknown) {
    if (options?.signal?.aborted) {
      return fail('ABORTED');
    }

    if (e instanceof TimeoutError) {
      log.debug('Fail timeout', { e });
      return fail('TIMEOUT');
    }
    if (e instanceof Error) {
      log.debug('Fail error', { e });
      return fail(e);
    }

    log.debug('Fail other', { e });
    throw e;
  }
};
