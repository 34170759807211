/* eslint-disable no-console */
import chalk, { Chalk } from 'chalk';
import { Platform } from 'react-native';
import { LogLevel } from './LogLevel';
import { Logger } from './Logger';

const ctx = new chalk.Instance({
  level: process.env.NODE_ENV === 'development' ? 3 : 0
});

const colourForLogLevel: Record<LogLevel, Chalk> = {
  [LogLevel.debug]: ctx.blue,
  [LogLevel.info]: ctx.white,
  [LogLevel.warn]: ctx.yellow,
  [LogLevel.error]: ctx.red
};

export const createLogger = (
  component: string,
  staticFields?: Record<string, unknown>
): Logger => {
  const log = (
    level: LogLevel,
    message: string,
    additionalFields?: Record<string, unknown>
  ) => {
    const output = JSON.stringify({
      time: new Date().toISOString(),
      severity: level,
      platform: Platform.OS,
      component,
      message,
      ...(staticFields || {}),
      ...(additionalFields || {})
    });

    const colour = colourForLogLevel[level];

    if (level === LogLevel.debug || level === LogLevel.info) {
      console.log(colour(output));
    } else {
      console[level](colour(output));
    }
  };
  return {
    staticFields,
    debug: (message, additionalFields) =>
      log(LogLevel.debug, message, additionalFields),
    info: (message, additionalFields) =>
      log(LogLevel.info, message, additionalFields),
    warn: (message, additionalFields) =>
      log(LogLevel.warn, message, additionalFields),
    error: (messageOrError: string | Error, additionalFields) => {
      if (messageOrError instanceof Error) {
        const {
          stack = 'No stack available',
          name: errorName,
          message: errorMessage
        } = messageOrError;
        return log(LogLevel.error, stack, {
          ...additionalFields,
          errorName,
          errorMessage
        });
      }
      return log(LogLevel.error, messageOrError, additionalFields);
    }
  };
};
