import { RouteProp } from '@react-navigation/native';
import { StackHeaderProps } from '@react-navigation/stack';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colours } from '../../../../../../colours';
import { PlannerNavigatorParamList } from '../../../../PlannerNavigatorParamList';
import { DateSelector } from '../date-selector';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colours.white.$,
    paddingVertical: 12,
    paddingHorizontal: 29,
    flexDirection: 'column',
    alignItems: 'center'
  }
});

const isPlanRouteParams = (
  params: unknown
): params is RouteProp<PlannerNavigatorParamList, 'plan'>['params'] => {
  return !!params && typeof params === 'object' && 'date' in params;
};

export const PlanScreenHeader = ({
  route: { params },
  navigation
}: StackHeaderProps): JSX.Element => {
  const date = isPlanRouteParams(params) ? params?.date : undefined;

  return (
    <SafeAreaView edges={['top', 'left', 'right']} style={styles.container}>
      {date && (
        <DateSelector
          date={date}
          onSelect={d => navigation.setParams({ date: d })}
        />
      )}
    </SafeAreaView>
  );
};
