import { ComponentType } from 'react';

export type TabbedPageProps = {
  title?: string;
  badge?: number;
  component: ComponentType<unknown>;
  lazy?: boolean;
};

export const TabbedPage = (_: TabbedPageProps): JSX.Element => <></>;
