import { ReactNode, useContext, useMemo } from 'react';
import { ApiProvider } from '../../lib/api-hooked';
import { UserSessionContext } from './UserSessionContext';

export const UserSessionApiProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const context = useContext(UserSessionContext);

  const options = useMemo(
    () => ({
      authorizer: {
        getUserId() {
          return context?.currentSession?.userId ?? null;
        },

        getToken() {
          return context?.currentSession?.token ?? null;
        },
        notifyAuthFailed() {
          if (context) {
            context.setSessionAndStore(null);
          }
        }
      }
    }),
    [context]
  );

  return <ApiProvider options={options}>{children}</ApiProvider>;
};
