import { Platform } from 'react-native';

export const getServerUrl = (): string => {
  if (Platform.OS === 'web') {
    return '';
  }

  if (process.env.NODE_ENV === 'development') {
    return 'https://app-gateway.dev.daemon-cooks.com';
  }

  return 'https://dev.daemon-cooks.com';
};
