import { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { MountProps } from './MountProps';

export const LazyMount = ({
  visible,
  component: Component
}: MountProps): JSX.Element => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (visible && !mounted) {
      setMounted(true);
    }
  }, [visible]);

  const memoizedChildren = useMemo(
    () => (mounted ? <Component /> : <></>),
    [mounted]
  );

  return (
    <View style={{ display: visible ? 'flex' : 'none' }}>
      {memoizedChildren}
    </View>
  );
};
