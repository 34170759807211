import { createContext, ReactNode, useState } from 'react';

export type StickyDrawerContextValue = {
  isSticky: boolean;
  setIsSticky(sticky: boolean): void;
};

export const StickyDrawerContext =
  createContext<StickyDrawerContextValue | null>(null);

export const StickyDrawerContextProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [isSticky, setIsSticky] = useState(false);

  const value: StickyDrawerContextValue = {
    isSticky,
    setIsSticky
  };

  return (
    <StickyDrawerContext.Provider value={value}>
      {children}
    </StickyDrawerContext.Provider>
  );
};
