import { useContext } from 'react';
import { ConfigContext } from './ConfigContext';

export const useConfig = () => {
  const hookValue = useContext(ConfigContext);
  if (!hookValue) {
    throw new Error(
      'useConfig() hook requires a ConfigContext within the component hierarchy.'
    );
  }
  return hookValue;
};
