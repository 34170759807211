export class Colours {
  static readonly accentLight100 = new Colours('004E34');
  static readonly accentLight35 = new Colours('A6C1B8');
  static readonly accentLight5 = new Colours('D9E5E1');
  static readonly accentLight55 = new Colours('739E90');
  static readonly accentLight75 = new Colours('407A67');
  static readonly background = new Colours('222222');
  static readonly black = new Colours('000000');
  static readonly cellBackground = new Colours('FFFFFF', 0.05);
  static readonly errorDark = new Colours('F14040');
  static readonly foreground = new Colours('FFFFFF');
  static readonly primaryDark100 = new Colours('00DE95');
  static readonly primaryDark55 = new Colours('004E34');
  static readonly primaryDark75 = new Colours('00A670');
  static readonly primaryLight100 = new Colours('00DE95');
  static readonly primaryLight35 = new Colours('A6F4DA');
  static readonly primaryLight5 = new Colours('F2FDF9');
  static readonly primaryLight55 = new Colours('73EDC5');
  static readonly primaryLight75 = new Colours('40E6B0');
  static readonly textDark71Plus = new Colours('FFFFFF');
  static readonly transparent = new Colours('808080', 0);
  static readonly white = new Colours('FFFFFF');

  private constructor(
    private readonly rgb: string,
    private readonly opacity: number = 1
  ) {}

  private opacityAsHex(): string {
    return Math.ceil(this.opacity * 255)
      .toString(16)
      .padStart(2, '0');
  }

  o(o: number): Colours {
    return new Colours(this.rgb, o);
  }

  get $() {
    return `#${this.rgb}${this.opacityAsHex()}`;
  }
}
