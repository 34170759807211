import { useContext } from 'react';
import { nameof } from '../../lib/name-of';
import { UserSubscriptionContext } from './UserSubscriptionContext';

export const useSubscription = () => {
  const hookValue = useContext(UserSubscriptionContext);
  if (!hookValue) {
    throw new Error(
      `${nameof({
        useSubscription
      })}() hook requires a ${nameof({
        UserSubscriptionContext
      })} within the component hierarchy.`
    );
  }
  return hookValue;
};
