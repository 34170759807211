import { useCallback, useMemo, useState } from 'react';
import {
  Modal,
  Pressable,
  Text,
  TouchableWithoutFeedback,
  View,
  ViewProps
} from 'react-native';
import { Calendar } from 'react-native-calendars';
import { Theme } from 'react-native-calendars/src/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import XDate from 'xdate';
import { Colours } from '../../../../../../colours';
import { Fonts } from '../../../../../../fonts';
import { nameof } from '../../../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../../../lib/responsive-style-sheet';
import { ClosedIcon } from './ClosedIcon';
import { Icon } from './Icon';
import { OpenedIcon } from './OpenedIcon';

const rStyles = ResponsiveStyleSheet.create({
  border: {
    borderColor: Colours.accentLight5.$,
    borderRadius: 15,
    borderWidth: 2
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 12,
    height: 46
  },
  text: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 14,
    color: Colours.accentLight100.$,
    marginHorizontal: 10,
    flexGrow: 1
  },
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  calendar: {
    backgroundColor: Colours.white.$,
    elevation: 50,
    padding: 10,
    marginHorizontal: 29,
    shadowOpacity: 0.12,
    shadowColor: Colours.black.$,
    shadowRadius: 30,
    shadowOffset: { width: 0, height: 15 }
  }
})
  .override(
    { medium: true, large: true },
    {
      calendar: {
        width: 300,
        alignSelf: 'center'
      },
      container: {
        width: 300
      }
    }
  )
  .override(
    { medium: true },
    {
      calendar: {
        left: 50
      }
    }
  )
  .override(
    { large: true },
    {
      calendar: {
        left: 108
      }
    }
  )
  .override(
    { small: true },
    {
      container: {
        alignSelf: 'stretch'
      }
    }
  );

const THEME_OVERRIDE = {
  'stylesheet.calendar.header': {
    dayHeader: {
      textTransform: 'uppercase',
      marginTop: 2,
      marginBottom: 7,
      width: 32,
      textAlign: 'center',
      fontSize: 12,
      fontFamily: Fonts.DMSans_700Bold,
      color: Colours.accentLight75.$
    }
  }
} as Theme;

export type DateSelectorProps = {
  date: string;
  onSelect: (date: string) => void;
} & Omit<ViewProps, 'children'>;

export const DateSelector = ({
  date,
  onSelect,
  style,
  ...rest
}: DateSelectorProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const { top } = useSafeAreaInsets();

  const [opened, setOpened] = useState(false);

  const toggleModal = useCallback(() => setOpened(o => !o), [setOpened]);

  const dateStr = useMemo(() => {
    return new XDate(date).toString('dS MMMM yyyy');
  }, [date]);

  return (
    <>
      <Pressable
        style={[styles.container, styles.border, style]}
        {...rest}
        onPress={toggleModal}
      >
        <Icon />
        <Text style={styles.text}>{dateStr}</Text>
        {opened ? <OpenedIcon /> : <ClosedIcon />}
      </Pressable>
      <Modal
        visible={opened}
        onRequestClose={toggleModal}
        transparent
        animationType="fade"
      >
        <TouchableWithoutFeedback onPress={toggleModal}>
          <View style={styles.modalOverlay} />
        </TouchableWithoutFeedback>

        <View style={[styles.calendar, styles.border, { top: top + 62 }]}>
          <Calendar
            testID={nameof({ Calendar })}
            current={date}
            firstDay={1}
            theme={{
              arrowColor: Colours.accentLight55.$,
              monthTextColor: Colours.accentLight100.$,
              textMonthFontFamily: Fonts.DMSans_500Medium,
              textMonthFontSize: 20,
              dayTextColor: Colours.accentLight100.$,
              textDayFontSize: 12,
              textDayFontFamily: Fonts.DMSans_700Bold,
              selectedDayBackgroundColor: Colours.accentLight100.$,
              selectedDayTextColor: Colours.white.$,
              ...THEME_OVERRIDE
            }}
            onDayPress={e => {
              if (e?.dateString) {
                onSelect(e.dateString);
                setOpened(false);
              }
            }}
          />
        </View>
      </Modal>
    </>
  );
};
