import { useContext, useEffect } from 'react';
import { RetainSplashContext } from './RetainSplashContextProvider';

export const RetainSplash = (): JSX.Element => {
  const context = useContext(RetainSplashContext);

  useEffect(() => {
    context?.retain();

    return () => context?.release();
  }, [context]);

  return <></>;
};
