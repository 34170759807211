import Svg, { SvgProps, G, Path } from 'react-native-svg';

export const RecipesIcon = ({ fill, ...rest }: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...rest}>
    <G>
      <Path
        d="M16 6v8h3v8h2V2c-2.76 0-5 2.24-5 4Zm-5 3H9V2H7v7H5V2H3v7c0 2.21 1.79 4 4 4v9h2v-9c2.21 0 4-1.79 4-4V2h-2v7Z"
        fill={fill}
      />
    </G>
  </Svg>
);
