import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export const Icon = (props: SvgProps) => (
  <Svg {...props} width={24} height={24} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M19.182 3.91h-.955V2h-1.909v1.91H6.773V2h-1.91v1.91H3.91C2.86 3.91 2 4.767 2 5.817v15.273C2 22.14 2.86 23 3.91 23h15.272c1.05 0 1.909-.86 1.909-1.91V5.819c0-1.05-.86-1.909-1.91-1.909Zm0 17.18H3.909v-10.5h15.273v10.5Zm0-12.408H3.909V5.818h15.273v2.864Z"
        fill="#D9E5E1"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
