import { StackScreenProps } from '@react-navigation/stack';
import { Meal } from '@showme-fit/smf-app-gateway-client';
import { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Platform } from 'react-native';
import { useSmfApi } from '../../../../api';
import { RecipeList } from '../../../../components/recipe-list';
import { useToast } from '../../../../components/toast-config/useToast';
import { useSession } from '../../../../components/user-manager';
import { newRandomUuid } from '../../../../lib/base-62';
import { nameof } from '../../../../lib/name-of';
import { usePlanResource, useRecipesResourceList } from '../../../../resources';
import { useShoppingList } from '../../../../resources/shopping-list/useShoppingList';
import { PlannerNavigatorParamList } from '../../PlannerNavigatorParamList';
import { AddRecipeScreenHeader } from './AddRecipeScreenHeader';
import { mapRecipeForPlanner } from './mapRecipeForPlanner';
import { mapRecipeForShoppingList } from './mapRecipeForShoppingList';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F2FDF9'
  }
});

export type AddRecipeScreenProps = StackScreenProps<
  PlannerNavigatorParamList,
  'addRecipe'
>;

const newCommandId = () => `cmd_${newRandomUuid()}`;

export const AddRecipeScreen = ({
  navigation,
  route: {
    params: { date, meal, search }
  }
}: AddRecipeScreenProps): JSX.Element => {
  if (!date || !meal) {
    throw new Error('Date and meal required');
  }

  const { currentSession } = useSession();

  if (!currentSession?.userId) {
    throw new Error('User ID required');
  }

  const [selected, setSelected] = useState<number | null>(null);

  const setPlan = usePlanResource(date)[1];

  const { recipes } = useRecipesResourceList();

  const { call: callAssign, result: assignResult } = useSmfApi(
    'putApiPlannerCommandsAssignRecipeByCommandId'
  );

  const { call: getFullRecipeDetails, result: getFullRecipeDetailsResult } =
    useSmfApi('get/recipes/{id}');

  useEffect(() => {
    if (selected === null) {
      return;
    }

    callAssign({
      commandId: newCommandId(),
      body: {
        userId: currentSession.userId,
        date,
        meal: meal.toUpperCase() as Meal,
        recipeId: selected
      }
    });

    getFullRecipeDetails({
      id: selected,
      populate: [
        'ingredients.product',
        'ingredients.product.product_group'
      ].join()
    });
  }, [selected]);

  useEffect(() => {
    if (!assignResult?.success) {
      return;
    }

    if (selected === null) {
      return;
    }

    const recipe = recipes?.filter(r => r.id === selected)[0] ?? null;

    if (recipe === null) {
      return;
    }

    const mapped = mapRecipeForPlanner(recipe);

    setPlan(v =>
      v
        ? { ...v, meals: { ...v.meals, [meal]: mapped } }
        : {
            userId: currentSession.userId,
            date,
            meals: {
              [meal]: mapped
            },
            activities: []
          }
    );

    useToast(Platform.OS, 'add');

    navigation.goBack();
  }, [assignResult]);

  const { update: updateShoppingList } = useShoppingList();

  useEffect(() => {
    if (
      !getFullRecipeDetailsResult?.success ||
      getFullRecipeDetailsResult.data.response.status !== 200 ||
      selected === null ||
      !date
    ) {
      return;
    }

    const recipe = getFullRecipeDetailsResult.data.response.body.data;

    if (!recipe || !date || !meal) {
      return;
    }

    mapRecipeForShoppingList(recipe, date, meal).forEach(
      ({
        compositeKey,
        productId,
        productName,
        productGroupId,
        productGroupName,
        unit,
        quantityRequired
      }) => {
        updateShoppingList(compositeKey, existing => {
          return {
            ...(existing ?? {}),
            compositeKey,
            date,
            meal,
            productId,
            productName,
            productGroupId,
            productGroupName,
            unit,
            quantityRequired:
              existing?.quantityRequired ?? 0 + quantityRequired,
            quantityChecked: existing?.quantityChecked ?? 0
          };
        });
      }
    );
  }, [getFullRecipeDetailsResult]);

  const [searchActive, setSearchActive] = useState(false);

  const searchValue = useMemo(() => search ?? '', [search]);

  useEffect(() => {
    navigation.setOptions({
      header: props => (
        <AddRecipeScreenHeader {...props} onFocussedChanged={setSearchActive} />
      )
    });
  }, []);

  return (
    <RecipeList
      style={styles.container}
      testID={nameof({ AddRecipeScreen })}
      onPress={setSelected}
      filterActive={searchActive || !!searchValue}
      filterText={searchValue}
    />
  );
};
