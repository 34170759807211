import baseX from 'base-x';
import { v4 as random } from 'uuid';

const base62 = baseX(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
);

// implementation from crypto-js
const parseHex = (hex: string): Uint8Array => {
  const bytes = hex.match(/.{1,2}/g)?.map(byte => parseInt(byte, 16)) ?? [];
  return Uint8Array.from(bytes);
};

const encode = (uuid: string): string => {
  const data = parseHex(uuid.replace(/-/g, ''));
  return base62.encode(data).padStart(22, '0');
};

export const newRandomUuid = (): string => {
  return encode(random());
};
