import { ActivityIndicator, StyleSheet } from 'react-native';
import { useActivity } from './useActivity';

const styles = StyleSheet.create({
  activity: {
    marginHorizontal: 1
  }
});

export const ActivityContextIndicator = (): JSX.Element => {
  const { hasActivity } = useActivity();

  return <>{hasActivity && <ActivityIndicator style={styles.activity} />}</>;
};
