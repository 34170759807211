import { Text, View, ViewProps } from 'react-native';
import XDate from 'xdate';
import { Colours } from '../../../../../../colours';
import { Fonts } from '../../../../../../fonts';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../../../lib/responsive-style-sheet';
import { MealSlot } from '../meal-slot/MealSlot';

export type PlanDayProps = Omit<ViewProps, 'children'> & { date: string };

const rStyles = ResponsiveStyleSheet.create({})
  .override(
    { medium: true, large: true },
    {
      container: {
        backgroundColor: '#F2FDF9',
        borderRadius: 20,
        padding: 10
      },
      dayTitle: {
        fontFamily: Fonts.DMSans_700Bold,
        fontSize: 20,
        color: Colours.accentLight100.$,
        textAlign: 'center',
        marginVertical: 10
      }
    }
  )
  .override(
    { small: true },
    {
      container: {
        padding: 26
      },
      dayTitle: {
        display: 'none'
      }
    }
  );

export const PlanDay = ({
  style,
  date,
  ...rest
}: PlanDayProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <View style={[styles.container, style]} {...rest}>
      <Text style={styles.dayTitle}>{new XDate(date).toString('dddd dS')}</Text>
      <MealSlot date={date} meal="breakfast" />
      <MealSlot date={date} meal="lunch" />
      <MealSlot date={date} meal="dinner" />
    </View>
  );
};
