import Svg, { SvgProps, Path } from 'react-native-svg';

export const CheckedIcon = (props: SvgProps) => (
  <Svg {...props} width={27} height={27} fill="none">
    <Path
      d="m19.767 9.322-7.898 8.355-3.59-3.798"
      stroke="#004E34"
      strokeWidth={2.089}
    />
  </Svg>
);
