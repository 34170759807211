import { createContext, ReactNode } from 'react';
import { useLaunchArguments } from '../../lib/launch-arguments';
import { createLogger } from '../../lib/logging';
import { nameof } from '../../lib/name-of';
import { Configuration } from './Configuration';
import { getServerUrl } from './getServerUrl';

const defaultConfig: Configuration = {
  serverUrl: getServerUrl(),
  fetchTimeoutMs: 10000,
  fetchRetries: 3
};

type ConfigContextValue = Configuration;

export const ConfigContext = createContext<ConfigContextValue | null>(null);

export const ConfigManager = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const log = createLogger(nameof({ ConfigManager }));

  const { serverUrl } = useLaunchArguments<LaunchArguments>();

  const value: Configuration = {
    ...defaultConfig,
    ...(serverUrl ? { serverUrl } : {})
  };

  log.info('Configuration', value);

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};
