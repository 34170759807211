import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Meal } from '@showme-fit/smf-app-gateway-client';
import { useCallback, useMemo } from 'react';
import { Platform, Text, View, ViewProps } from 'react-native';
import { useSmfApi } from '../../../../../../api';
import { Colours } from '../../../../../../colours';
import { useToast } from '../../../../../../components/toast-config';
import { useSession } from '../../../../../../components/user-manager';
import { Fonts } from '../../../../../../fonts';
import { newRandomUuid } from '../../../../../../lib/base-62';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../../../lib/responsive-style-sheet';
import { PlanResource, usePlanResource } from '../../../../../../resources';
import { useShoppingList } from '../../../../../../resources/shopping-list/useShoppingList';
import { PlannerNavigatorParamList } from '../../../../PlannerNavigatorParamList';
import { AddRecipeCard } from '../add-recipe-card';
import { RecipeCard } from '../recipe-card';
import { titleForMeal } from './titleForMeal';

export type MealSlotProps = Omit<ViewProps, 'children'> & {
  date: string;
  meal: keyof PlanResource['meals'];
};

const rStyles = ResponsiveStyleSheet.create({
  mealTitle: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 16,
    color: Colours.accentLight100.$,
    marginVertical: 12,
    textAlign: 'center'
  },
  recipeTile: {
    backgroundColor: Colours.white.$,
    borderRadius: 15,
    borderColor: Colours.primaryLight75.$,
    borderWidth: 2
  }
}).override(
  { medium: true, large: true },
  {
    mealTitle: {
      textAlign: 'left',
      fontFamily: Fonts.DMSans_500Medium,
      color: Colours.accentLight55.$
    }
  }
);

const newCommandId = () => `cmd_${newRandomUuid()}`;

export const MealSlot = ({
  style,
  date,
  meal,
  ...rest
}: MealSlotProps): JSX.Element => {
  const { currentSession } = useSession();

  if (!currentSession?.userId) {
    throw new Error('User ID required');
  }

  const [data, setPlan] = usePlanResource(date);

  const styles = useResponsiveStyleSheet(rStyles);

  const navigation = useNavigation<NavigationProp<PlannerNavigatorParamList>>();

  const recipe = useMemo(() => data?.meals[meal], [meal, data]);

  const { rows, delete: deleteOne } = useShoppingList();

  const { call } = useSmfApi('putApiPlannerCommandsUnassignRecipeByCommandId');

  const onRemovePressed = useCallback(() => {
    setPlan(v =>
      v
        ? { ...v, meals: { ...v.meals, [meal]: undefined } }
        : {
            userId: currentSession.userId,
            date,
            meals: {},
            activities: []
          }
    );

    rows.forEach(row => {
      if (row.date === date && row.meal === meal) {
        deleteOne(row.compositeKey);
      }
    });

    call({
      commandId: newCommandId(),
      body: {
        userId: currentSession.userId,
        date,
        meal: meal.toUpperCase() as Meal
      }
    });

    useToast(Platform.OS, 'remove');
  }, [call, setPlan, currentSession, rows, deleteOne]);

  return (
    <View style={style} {...rest}>
      <Text style={styles.mealTitle}>{titleForMeal(meal)}</Text>
      {recipe ? (
        <RecipeCard
          style={styles.recipeTile}
          recipe={recipe}
          onRemovePressed={onRemovePressed}
          onPress={() =>
            navigation.navigate('recipe', { recipeId: recipe.recipeId })
          }
        />
      ) : (
        <AddRecipeCard
          onPress={() =>
            navigation.navigate('addRecipe', { date, meal, search: meal })
          }
        />
      )}
    </View>
  );
};
