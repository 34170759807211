import { ShowmeFitAppGatewayClient } from '@showme-fit/smf-app-gateway-client';
import { useMemo } from 'react';
import { useApiHook } from '../lib/api-hooked';
import { ApiOptions } from '../lib/api-hooked/ApiOptions';
import { useSmfApiClient } from './useSmfApiClient';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type FunctionType<TArgs extends any[] = any[]> = (...args: TArgs) => any;

export type ApiMethods<T> = {
  [K in keyof T]-?: T[K] extends FunctionType<[any, RequestInit]>
    ? T[K]
    : never;
};

type MethodArgs<K extends keyof ApiMethods<ShowmeFitAppGatewayClient>> =
  Parameters<ApiMethods<ShowmeFitAppGatewayClient>[K]>[0];

type MethodResponse<K extends keyof ApiMethods<ShowmeFitAppGatewayClient>> =
  Awaited<ReturnType<ApiMethods<ShowmeFitAppGatewayClient>[K]>>;

export const useSmfApi = <
  K extends keyof ApiMethods<ShowmeFitAppGatewayClient>
>(
  method: K,
  options?: ApiOptions
) => {
  const client = useSmfApiClient() as ApiMethods<ShowmeFitAppGatewayClient>;

  const call = useMemo(
    () =>
      client[method].bind(client) as (
        args: MethodArgs<K>,
        fetchOptions?: RequestInit
      ) => Promise<MethodResponse<K>>,
    [client, method]
  );

  const combinedOptions: ApiOptions = useMemo(
    () => ({
      cacheStrategy: method.startsWith('get') ? 'CACHE_ALL_USERS' : 'NO_CACHE',
      ...options
    }),
    [options, method]
  );

  return useApiHook<MethodArgs<K>, MethodResponse<K>>(
    call,
    method,
    combinedOptions
  );
};
