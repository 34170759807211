import { useFormikContext } from 'formik';
import { useState, forwardRef } from 'react';
import { Text, TextInput, TextInputProps, View } from 'react-native';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';
import { useForwardRef } from '../../lib/use-forward-ref';
import { Error } from '../error';
import { normalise } from './normalise';
import { Normaliser } from './Normaliser';
import { webStyle } from './webStyle';

export type TextFieldProps = Omit<TextInputProps, 'value'> & {
  errorMessage?: string;
  hideErrorMessage?: boolean;
  name: string;
  normaliser?: Normaliser | Normaliser[];
};

const rStyles = ResponsiveStyleSheet.create({
  input: {
    backgroundColor: Colours.textDark71Plus.$,
    borderColor: Colours.accentLight35.$,
    borderRadius: 15,
    borderStyle: 'solid',
    borderWidth: 2,
    color: Colours.accentLight100.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 16,
    paddingHorizontal: 24,
    paddingVertical: 16
  },
  inputWithTitle: {
    paddingTop: 24,
    paddingBottom: 8
  },
  title: {
    color: Colours.accentLight35.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 12,
    left: 24,
    position: 'absolute',
    top: 9
  },
  focussed: {
    borderColor: Colours.primaryDark100.$
  },
  errored: {
    borderColor: Colours.errorDark.$
  },
  errorMessage: {
    marginHorizontal: 22,
    marginVertical: 4
  }
});

export const TextField = forwardRef<TextInput, TextFieldProps>(
  (
    {
      errorMessage,
      hideErrorMessage,
      name,
      normaliser = v => v,
      onBlur,
      onChangeText,
      onFocus,
      style,
      placeholder,
      ...rest
    },
    parentRef
  ): JSX.Element => {
    const styles = useResponsiveStyleSheet(rStyles);

    const [focussed, setFocussed] = useState(false);

    const { setFieldValue, values, errors, setFieldTouched } =
      useFormikContext<Record<string, string>>();

    const internalRef = useForwardRef(parentRef);

    const showTitle = !!values[name];

    const showError = (!!errors[name] || !!errorMessage) && !hideErrorMessage;

    return (
      <View style={style}>
        <TextInput
          testID={`${name}FieldInput`}
          {...rest}
          style={[
            styles.input,
            focussed && styles.focussed,
            showError && styles.errored,
            showTitle && styles.inputWithTitle,
            webStyle
          ]}
          onFocus={e => {
            setFocussed(true);
            onFocus?.(e);
          }}
          onBlur={e => {
            setFocussed(false);
            onBlur?.(e);
            setFieldTouched(name);
          }}
          onChangeText={e => {
            onChangeText?.(e);
            setFieldValue(name, normalise(e, normaliser));
          }}
          placeholder={placeholder}
          placeholderTextColor={Colours.accentLight35.$}
          ref={internalRef}
          value={values[name]}
        />
        {showTitle && <Text style={styles.title}>{placeholder}</Text>}
        {showError && (
          <Error testID={`${name}FieldError`} style={styles.errorMessage}>
            {errorMessage ?? errors[name]}
          </Error>
        )}
      </View>
    );
  }
);
