import {
  Pressable,
  Text,
  TextProps,
  TouchableHighlightProps
} from 'react-native';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import { nameof } from '../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colours.white.$,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 15
  },
  destructive: {
    backgroundColor: Colours.errorDark.$,
    color: Colours.white.$
  },
  title: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 16,
    color: Colours.accentLight100.$,
    lineHeight: 24
  },
  icon: {
    marginLeft: 12
  }
}).override(
  { medium: true, large: true },
  {
    container: {
      paddingVertical: 17,
      paddingHorizontal: 34
    },
    title: {
      fontSize: 20,
      lineHeight: 30
    }
  }
);

export type PrimaryButtonProps = TouchableHighlightProps & {
  title: string;
  titleStyle?: TextProps['style'];
  destructive?: boolean;
};

export const PrimaryButton = ({
  title,
  titleStyle,
  style: styleProp,
  destructive = false,
  ...props
}: PrimaryButtonProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <Pressable
      testID={nameof({ PrimaryButton })}
      accessibilityRole="button"
      style={[styles.container, destructive && styles.destructive, styleProp]}
      {...props}
    >
      <Text
        style={[styles.title, destructive && styles.destructive, titleStyle]}
      >
        {title}
      </Text>
    </Pressable>
  );
};
