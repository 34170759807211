import { RecipeListResponseDataItem } from '@showme-fit/smf-app-gateway-client';
import { RecipeTileData } from '../recipe-tile/RecipeTileData';
import { thenThrow } from '../../lib/then-throw';

const hasThumbnail = (
  formats: unknown
): formats is {
  thumbnail: {
    url: string;
  };
} => {
  return !!formats && typeof formats === 'object' && 'thumbnail' in formats;
};

export const mapRecipeTileData = (
  recipe: RecipeListResponseDataItem
): RecipeTileData => {
  const recipeId = recipe.id ?? thenThrow('id is missing');
  const dishName = recipe.attributes?.dishName;
  const prepTime = recipe.attributes?.prepTime;
  const cookTime = recipe.attributes?.cookTime;
  const priceGuide = recipe.attributes?.priceGuide;
  const dietaryRequirementCodes =
    recipe.attributes?.dietary_requirements?.data?.compactMap(
      d => d.attributes?.code
    ) ?? [];

  const formats = recipe.attributes?.mainImage?.data?.attributes?.formats;
  const mainImageUrl = hasThumbnail(formats)
    ? formats.thumbnail?.url
    : undefined;

  return {
    recipeId,
    mainImageUrl,
    dishName,
    prepTime,
    cookTime,
    priceGuide,
    dietaryRequirementCodes
  };
};
