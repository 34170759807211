import { RecipeListResponseDataItem } from '@showme-fit/smf-app-gateway-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSmfApi } from '../../api';
import { useSharedState } from '../../lib/use-shared-state';

type RecipeResource = RecipeListResponseDataItem;

const recipesUrl = () => `api/recipes` as const;

const debouncePeriod = 5 * 60 * 1000;

export function useRecipesResourceList() {
  const { call, inProgress, result } = useSmfApi('get/recipes');

  const urlKey = recipesUrl();
  const callTimeKey = `time:${urlKey}`;

  const [recipes, setRecipes] = useSharedState<RecipeResource[] | null>(
    urlKey,
    null
  );

  const [callTime, setCallTime] = useSharedState<number | null>(
    callTimeKey,
    null
  );

  const reload = useCallback(() => {
    setCallTime(new Date().getTime());
    call({
      populate: 'mainImage,dietary_requirements',
      paginationPageSize: 500
    });
  }, []);

  useEffect(() => {
    const now = new Date().getTime();
    if (!callTime || callTime + debouncePeriod < now) {
      reload();
    }
  }, [callTime, reload]);

  useEffect(() => {
    if (result?.success && result.data.response.status === 200) {
      setRecipes(result.data.response.body.data ?? null);
    }
  }, [result]);

  const error = useMemo(() => {
    if (!result) return null;

    if (!result.success) return result.reason;

    if (result.data.response.status !== 200) return result.data.response;

    return null;
  }, [result]);

  return { recipes, inProgress, reload, error };
}

export function useRecipeResource(args: { id: number }) {
  const [recipe, setRecipe] = useState<RecipeResource | null>(null);

  const list = useRecipesResourceList();

  const { call, inProgress, result } = useSmfApi('get/recipes/{id}');

  useEffect(() => {
    setRecipe(
      r => r || (list.recipes?.find(({ id }) => id === args.id) ?? null)
    );
    call({
      id: args.id,
      populate: [
        'ingredients.product',
        'mainImage',
        'mealTypes',
        'equipment',
        'dietary_requirements',
        'chef',
        'video',
        'cuisines',
        'collaborator',
        'methodLine'
      ].join()
    });
  }, [list.recipes]);

  const error = useMemo(() => {
    if (!result) return null;

    if (!result.success) return result.reason;

    if (result.data.response.status !== 200) return result.data.response;

    return null;
  }, [result]);

  useEffect(() => {
    if (result?.success && result.data.response.status === 200) {
      setRecipe(result.data.response.body.data ?? null);
    }
  }, [result]);

  return {
    recipe,
    inProgress: inProgress || list.inProgress,
    error: error ?? list.error
  };
}
