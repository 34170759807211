import {
  Text,
  ViewProps,
  GestureResponderEvent,
  Pressable
} from 'react-native';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import { nameof } from '../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';

export type TabProps = ViewProps & {
  title: string;
  isActive: boolean;
  onPress?: (event: GestureResponderEvent) => void;
};

const rStyles = ResponsiveStyleSheet.create({
  tab: {
    paddingVertical: 14,
    width: 168,
    alignItems: 'center',
    borderRadius: 15
  },
  active: {
    backgroundColor: Colours.primaryLight100.$
  },
  tabText: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 16,
    color: Colours.accentLight100.$,
    letterSpacing: -0.3
  }
});

export const Tab = ({
  title,
  isActive,
  children,
  style,
  ...rest
}: TabProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <Pressable
      style={[style, styles.tab, isActive && styles.active]}
      disabled={isActive}
      testID={nameof({ Tab })}
      {...rest}
    >
      <Text style={styles.tabText}>{title}</Text>
      {children}
    </Pressable>
  );
};
