import {
  createContext,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { nameof } from '../name-of';
import { Subject, Subscriber } from '../pub-sub';

interface SharedStateContextValue {
  subscribe<T>(key: string, subscriber: Subscriber<T>): () => void;
  publish<T>(key: string, value: T): void;
  getCurrentValue<T>(key: string): T | null;
}

export const SharedStateContext = createContext<SharedStateContextValue | null>(
  null
);

export const SharedStateScope = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [subjects] = useState<Map<string, Subject<unknown>>>(new Map());

  const getOrCreateSubject = useCallback(
    (key: string) => {
      let subject = subjects.get(key);
      if (!subject) {
        subject = new Subject();
        subjects.set(key, subject);
      }
      return subject;
    },
    [subjects]
  );

  const contextValue: SharedStateContextValue = useMemo(
    () => ({
      subscribe<T>(key: string, subscriber: Subscriber<T>) {
        return getOrCreateSubject(key).subscribe(
          subscriber as Subscriber<unknown>
        );
      },
      publish(key, value) {
        getOrCreateSubject(key).next(value);
      },
      getCurrentValue<T>(key: string) {
        return getOrCreateSubject(key).current as T | null;
      }
    }),
    [getOrCreateSubject]
  );

  return (
    <SharedStateContext.Provider value={contextValue}>
      {children}
    </SharedStateContext.Provider>
  );
};

function getInitialState<T>(value: T | (() => T)): T {
  if (typeof value === 'function') return (value as () => T)();
  return value;
}

export function useSharedState<T>(
  key: string,
  initialState: T | (() => T)
): [T, (value: SetStateAction<T>) => void] {
  const contextValue = useContext(SharedStateContext);

  if (!contextValue) {
    throw new Error(
      `${nameof({ useSharedState })}() hook requires a ${nameof({
        SharedStateContext
      })} within the component hierarchy.`
    );
  }

  const { subscribe, publish, getCurrentValue } = contextValue;

  const [internalValue, setInternalValue] = useState<T>(
    () => getCurrentValue(key) ?? getInitialState(initialState)
  );

  useEffect(() => {
    return subscribe(key, { next: setInternalValue });
  }, [key]);

  const setValue = useCallback(
    (value: SetStateAction<T>) => {
      const newValue =
        typeof value === 'function'
          ? (value as (arg: T) => T)(internalValue)
          : value;
      setInternalValue(newValue);
      publish(key, newValue);
    },
    [publish, setInternalValue]
  );

  return [internalValue, setValue];
}
