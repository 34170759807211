import { Platform, StyleSheet } from 'react-native';
import {
  ResizeMode,
  Video as ExpoVideo,
  VideoProps as ExpoVideoProps
} from 'expo-av';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import { useRecipeContext } from '../context';
import { HeroImage } from '../hero-image';

export type VideoProps = Omit<ExpoVideoProps, 'source'>;

const styles = StyleSheet.create({
  player: {
    width: '100%',
    maxWidth: 600,
    aspectRatio: 16 / 9,
    marginBottom: 20
  },

  heroImageNoVid: {
    alignSelf: 'center',
    maxWidth: 600,
    marginBottom: 20,
    marginTop: -10
  }
});

export const Video = ({ style, ...rest }: VideoProps): JSX.Element => {
  const { recipe } = useRecipeContext();

  const [poster, setPoster] = useState(true);

  const attrs = recipe?.attributes?.video?.data?.attributes as
    | {
        title: string;
        upload_id: string;
        asset_id: string;
        playback_id: string;
        error_message: string | null;
        isReady: boolean;
        duration: number;
        aspect_ratio: string;
        createdAt: string;
        updatedAt: string;
      }
    | undefined;

  const playbackId = attrs?.playback_id;

  if (Platform.OS === 'web') {
    if (!playbackId)
      return <HeroImage style={styles.heroImageNoVid}></HeroImage>;
    return (
      <ReactPlayer
        url={`https://stream.mux.com/${playbackId}.m3u8`}
        controls
        style={{
          width: '100%',
          flexShrink: 1,
          maxWidth: 600,
          aspectRatio: 16 / 9,
          marginBottom: 20
        }}
      />
    );
  }

  if (poster) {
    if (!playbackId) {
      return <></>;
    }

    return (
      <ExpoVideo
        source={{
          uri: `https://stream.mux.com/${playbackId}.m3u8`
        }}
        useNativeControls
        resizeMode={ResizeMode.CONTAIN}
        style={[styles.player, style]}
        usePoster
        onReadyForDisplay={() => setPoster(false)}
        posterSource={{
          uri: recipe?.attributes?.mainImage?.data?.attributes?.url
        }}
        posterStyle={{
          backgroundColor: 'black'
        }}
        {...rest}
      />
    );
  }
  if (playbackId) {
    return (
      <ExpoVideo
        source={{
          uri: `https://stream.mux.com/${playbackId}.m3u8`
        }}
        useNativeControls
        resizeMode={ResizeMode.CONTAIN}
        style={[styles.player, style]}
        posterSource={{
          uri: recipe?.attributes?.mainImage?.data?.attributes?.url
        }}
        {...rest}
      />
    );
  }

  return <></>;
};
