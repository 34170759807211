import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export const ServingSize = (props: SvgProps) => (
  <Svg {...props} width={16} height={16} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M6 9.167c-1.56 0-4.667.78-4.667 2.334v1.166h9.334v-1.166c0-1.554-3.107-2.334-4.667-2.334Zm-3.107 2.167c.56-.387 1.914-.833 3.107-.833 1.193 0 2.547.446 3.107.833H2.893ZM6 8.001a2.336 2.336 0 0 0 2.333-2.334A2.336 2.336 0 0 0 6 3.334a2.336 2.336 0 0 0-2.333 2.333A2.336 2.336 0 0 0 6 8.001Zm0-3.334c.553 0 1 .447 1 1 0 .554-.447 1-1 1-.553 0-1-.446-1-1 0-.553.447-1 1-1Zm4.693 4.54c.774.56 1.307 1.307 1.307 2.294v1.166h2.667v-1.166c0-1.347-2.334-2.114-3.974-2.294ZM10 8.001a2.336 2.336 0 0 0 2.333-2.334A2.336 2.336 0 0 0 10 3.334c-.36 0-.693.087-1 .233.42.594.667 1.32.667 2.1S9.42 7.174 9 7.767c.307.147.64.234 1 .234Z"
        fill="#A6C1B8"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
