import Svg, { SvgProps, Path } from 'react-native-svg';

export const ExpiredIcon = (props: SvgProps) => (
  <Svg {...props} width={50} height={50} fill="none">
    <Path
      d="M30 4.102c-12.398 0-18.898 25.3-20.699 33.3 2.8 1.598 7 2.598 15.7 2.598 8.698 0 12.698-.898 15.597-2.5-3.7-11.5-9-24.3-9-24.402V13l-.195-.402c-.102-.297-.102-.899.097-1.196.102-.101.2-.304.598-.304 1.902 0 2 3.101 2 4.101 0 1.102.902 1.899 2 1.899.8 0 1.402-.5 1.703-1 1.5-2.598 1.7-5.797.297-8.2-1-1.699-3.2-3.796-8.098-3.796ZM22 19l.598 1.3 1.5.2-1.098 1 .2 1.402-1.2-.601-1.3.699.203-1.402-1.102-1 1.5-.297.7-1.301Zm6.7 5c.699 0 1.398.2 2 .5-2 .2-3.602 1.898-3.602 4 0 2.102 1.602 3.8 3.602 4-.602.3-1.301.5-2 .5-2.5 0-4.5-2-4.5-4.5S26.3 24 28.7 24ZM19 28l.598 1.3 1.5.2-1.098 1 .2 1.402-1.2-.601-1.3.699.203-1.402-1.102-1 1.5-.196.7-1.402ZM7.9 38.902c-.098.098-.297.297-.399.5C7 40 6.9 40.801 7 41.598c.2.8.602 1.504 1.301 1.902.899.602 4.7 2.5 16.7 2.5s15.8-1.898 16.698-2.5c.7-.398 1.102-1.102 1.301-1.902.2-.797 0-1.598-.402-2.297-.2-.2-.297-.301-.5-.399C39 40.902 34.7 42 25 42c-9.5 0-14-1.2-17.101-3.098Z"
      fill="#004E34"
    />
  </Svg>
);
