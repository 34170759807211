import Svg, {
  SvgProps,
  Circle,
  G,
  Path,
  Defs,
  ClipPath
} from 'react-native-svg';

export const ClearIcon = (props: SvgProps) => (
  <Svg {...props} width={28} height={28} fill="none">
    <Circle opacity={0.5} cx={14} cy={14} r={14} fill="#D9E5E1" />
    <G clipPath="url(#a)">
      <Path
        d="m18.333 9.94-.94-.94-3.726 3.727L9.94 9 9 9.94l3.727 3.727L9 17.393l.94.94 3.727-3.726 3.726 3.726.94-.94-3.726-3.726 3.726-3.727Z"
        fill="#A6C1B8"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(6 6)" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
