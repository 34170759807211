import Svg, { SvgProps, Path } from 'react-native-svg';

export const ClearListIcon = ({ fill = '#004E34', ...rest }: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...rest}>
    <Path
      d="M14 8H3v2h11V8Zm0-4H3v2h11V4ZM3 14h7v-2H3v2Zm11.41 6L17 17.41 19.59 20 21 18.59 18.41 16 21 13.41 19.59 12 17 14.59 14.41 12 13 13.41 15.59 16 13 18.59 14.41 20Z"
      fill={fill}
    />
  </Svg>
);
