import { createStackNavigator } from '@react-navigation/stack';
import { useSizeClass } from '../../lib/responsive-style-sheet';
import { HeaderBackButton } from './components';
import { RecipesNavigatorParamList } from './RecipesNavigatorParamList';
import { RecipeListScreen, RecipeShowScreen } from './screens';
import { RecipeListScreenHeader } from './screens/recipe-list/RecipeListScreenHeader';

const Stack = createStackNavigator<RecipesNavigatorParamList>();

export const RecipesNavigator = (): JSX.Element => {
  const size = useSizeClass();
  return (
    <Stack.Navigator
      screenOptions={{
        headerTitle: ''
      }}
    >
      <Stack.Screen
        name="list"
        component={RecipeListScreen}
        options={{ header: () => <RecipeListScreenHeader /> }}
      />
      {size === 'small' ? (
        <Stack.Screen
          name="show"
          component={RecipeShowScreen}
          options={{
            headerLeft: HeaderBackButton,
            headerTransparent: true,
            headerLeftContainerStyle: {
              position: 'relative',
              top: '23%',
              left: -15
            }
          }}
        />
      ) : (
        <Stack.Screen
          name="show"
          component={RecipeShowScreen}
          options={{
            headerLeft: HeaderBackButton,
            headerTransparent: true
          }}
        />
      )}
    </Stack.Navigator>
  );
};
