import {
  Pressable,
  StyleSheet,
  Text,
  TouchableHighlightProps
} from 'react-native';
import { Colours } from '../../../../colours';
import { Fonts } from '../../../../fonts';
import { nameof } from '../../../../lib/name-of';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colours.white.$,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 10,
    borderColor: Colours.primaryLight35.$,
    borderWidth: 2,
    alignSelf: 'flex-start'
  },
  title: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 12,
    letterSpacing: -0.25,
    color: Colours.accentLight100.$,
    lineHeight: 18
  }
});

export type ShowButtonProps = TouchableHighlightProps & {
  title: string;
};

export const ShowButton = ({
  style: styleProp,
  ...props
}: ShowButtonProps): JSX.Element => {
  return (
    <Pressable
      testID={nameof({ ShowButton })}
      accessibilityRole="button"
      style={[styles.container, styleProp]}
      {...props}
    >
      <Text style={styles.title}>{props.title}</Text>
    </Pressable>
  );
};
