import Svg, { SvgProps, Path } from 'react-native-svg';

export const ProblemIcon = (props: SvgProps) => (
  <Svg {...props} width={60} height={60} fill="none">
    <Path
      d="M51.966 26.684c.01-.228.034-.452.034-.684 0-8.836-7.164-16-16-16-6.22 0-11.6 3.558-14.246 8.742A9.978 9.978 0 0 0 18 18c-5.06 0-9.198 3.77-9.864 8.648C3.406 28.258 0 32.726 0 38c0 6.628 5.372 12 12 12h36c6.628 0 12-5.372 12-12 0-5.236-3.358-9.676-8.034-11.316ZM39 26a3.001 3.001 0 0 1 0 6 3.001 3.001 0 0 1 0-6Zm-16 0a3.001 3.001 0 0 1 0 6 3.001 3.001 0 0 1 0-6Zm20.25 18.562c-.37.296-.81.438-1.248.438a1.996 1.996 0 0 1-1.564-.75C40.302 44.084 36.9 40 31 40c-5.968 0-9.404 4.208-9.438 4.25a2 2 0 1 1-3.124-2.5C18.626 41.516 23.128 36 31 36c7.872 0 12.374 5.516 12.562 5.75.69.864.55 2.122-.312 2.812Z"
      fill="#fff"
    />
  </Svg>
);
