import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { View } from 'react-native';
import { nameof } from '../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { DrawerItemList } from '../drawer-item-list';
import { Logo } from '../logo';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  logo: {
    width: 75,
    height: 24,
    top: 34,
    position: 'absolute',
    alignSelf: 'center'
  }
}).override(
  { large: true },
  {
    container: {
      alignItems: 'stretch'
    },
    logo: {
      width: 119,
      height: 38
    }
  }
);

export const DrawerContent = (
  props: DrawerContentComponentProps
): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <View style={styles.container} testID={nameof({ DrawerContent })}>
      <Logo style={styles.logo} />
      <DrawerItemList {...props} />
    </View>
  );
};
