import { StackScreenProps } from '@react-navigation/stack';
import { useCallback } from 'react';
import { NextButton, TourScreen } from '../../components';
import { WelcomeNavigatorParamsList } from '../../WelcomeNavigatorParamsList';
import imageSource from './image.png';

export type RecipesScreenProps = StackScreenProps<
  WelcomeNavigatorParamsList,
  'recipes'
>;

export const RecipesScreen = ({
  navigation
}: RecipesScreenProps): JSX.Element => {
  const onNext = useCallback(() => navigation.push('psychology'), [navigation]);

  return (
    <TourScreen
      pageNumber={2}
      imageSource={imageSource}
      title="Easy, healthy recipes & nutritional advice"
      body="We have hundreds of delicious, healthy and easy to make recipes, all checked by registered nutritionist, Kat Bright. There’s no calorie counting, we have done the hard work for you so you can just enjoy our meals!"
    >
      <NextButton onNext={onNext} />
    </TourScreen>
  );
};
