import Svg, { SvgProps, Path } from 'react-native-svg';

export const MenuIcon = (props: SvgProps) => (
  <Svg {...props} width={30} height={31} fill="none">
    <Path
      d="M6.186 9.633a.725.725 0 0 0-.682.45.738.738 0 0 0 .4.964c.09.036.186.054.282.053h17.49a.725.725 0 0 0 .683-.45.736.736 0 0 0-.4-.964.725.725 0 0 0-.283-.053H6.186Zm0 5.134a.725.725 0 0 0-.682.45.737.737 0 0 0 .4.963c.09.037.186.055.282.053h17.49a.723.723 0 0 0 .683-.45.737.737 0 0 0-.4-.963.725.725 0 0 0-.283-.053H6.186Zm0 5.133a.725.725 0 0 0-.682.45.737.737 0 0 0 .4.964c.09.036.186.054.282.053h17.49a.727.727 0 0 0 .683-.45.737.737 0 0 0-.4-.964.725.725 0 0 0-.283-.053H6.186Z"
      fill="#fff"
    />
  </Svg>
);
