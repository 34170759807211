import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useCallback
} from 'react';

export type ActivityContextValue = {
  readonly hasActivity: boolean;
  readonly enrollActivity: () => () => void;
  readonly activity: <T>(promise: Promise<T>) => Promise<T>;
};

export const ActivityContext = createContext<ActivityContextValue | null>(null);

export const ActivityManager = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [activityCount, setActivityCount] = useState<number>(0);
  const [hasActivity, setHasActivity] = useState<boolean>(false);

  useEffect(() => {
    if (hasActivity && activityCount === 0) {
      setHasActivity(false);
    }
    if (!hasActivity && activityCount > 0) {
      setHasActivity(true);
    }
  }, [activityCount]);

  const enrollActivity = useCallback(() => {
    setActivityCount(c => c + 1);
    return () => setActivityCount(c => c - 1);
  }, [setActivityCount]);

  const activity = useCallback<ActivityContextValue['activity']>(
    async promise => {
      const release = enrollActivity();
      try {
        const result = await promise;
        return result;
      } finally {
        release();
      }
    },
    [enrollActivity]
  );

  const value: ActivityContextValue = {
    hasActivity,
    enrollActivity,
    activity
  };

  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  );
};
