import { useCallback, useRef, useState } from 'react';
import {
  Pressable,
  TextInput,
  TextInputProps,
  View,
  ViewProps
} from 'react-native';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';
import { ClearIcon } from './ClearIcon';
import { SearchIcon } from './SearchIcon';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flexDirection: 'row'
  },
  input: {
    borderColor: Colours.accentLight5.$,
    borderRadius: 15,
    borderWidth: 2,
    color: Colours.accentLight100.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 14,
    height: 46,
    paddingLeft: 46,
    paddingRight: 16,
    width: '100%'
  },
  searchIcon: {
    position: 'absolute',
    alignSelf: 'center',
    left: 12
  },
  clearIcon: {
    position: 'absolute',
    alignSelf: 'center',
    right: 12
  }
});

export type SearchBarProps = {
  onFocussedChanged?: (focussed: boolean) => void;
  onClear: () => void;
} & Pick<TextInputProps, 'onChangeText' | 'placeholder' | 'value'> &
  Pick<ViewProps, 'style'>;

export const SearchBar = ({
  placeholder,
  style,
  onFocussedChanged,
  onClear: onClearExt,
  onChangeText,
  value
}: SearchBarProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const [focussed, setFocussed] = useState(false);

  const onFocus = useCallback(() => {
    setFocussed(true);
    onFocussedChanged?.(true);
  }, [setFocussed, onFocussedChanged]);

  const onBlur = useCallback(() => {
    setFocussed(false);
    onFocussedChanged?.(false);
  }, [setFocussed, onFocussedChanged]);

  const ref = useRef<TextInput>(null);

  const onClear = useCallback(() => {
    onClearExt();
    ref.current?.blur();
  }, [ref, onClearExt]);

  return (
    <View style={[styles.container, style]}>
      <TextInput
        ref={ref}
        style={styles.input}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChangeText={onChangeText}
        placeholderTextColor={Colours.accentLight35.$}
        value={value}
      />
      <SearchIcon style={styles.searchIcon} />
      {(!!value || focussed) && (
        <Pressable style={styles.clearIcon} onPress={onClear}>
          <ClearIcon />
        </Pressable>
      )}
    </View>
  );
};
