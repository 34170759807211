import { StackScreenProps } from '@react-navigation/stack';
import {
  LargeRecipeShowScreen,
  RecipeContextProvider,
  SmallRecipeShowScreen
} from '../../../../components/recipe-show-screen';
import { useSizeClass } from '../../../../lib/responsive-style-sheet';
import { RecipesNavigatorParamList } from '../../RecipesNavigatorParamList';

export type RecipeShowScreenProps = StackScreenProps<
  RecipesNavigatorParamList,
  'show'
>;

export const RecipeShowScreen = ({
  route: {
    params: { recipeId }
  }
}: RecipeShowScreenProps): JSX.Element => {
  const size = useSizeClass();

  if (size === 'small') {
    return (
      <RecipeContextProvider recipeId={recipeId}>
        <SmallRecipeShowScreen />
      </RecipeContextProvider>
    );
  }

  return (
    <RecipeContextProvider recipeId={recipeId}>
      <LargeRecipeShowScreen />
    </RecipeContextProvider>
  );
};
