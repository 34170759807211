import { CacheProvider } from './CacheProvider';
import { CacheResult } from './CacheResult';

export const inMemoryCacheProvider: () => CacheProvider = () => {
  const database: Record<string, CacheResult<unknown>> = {};

  return {
    write(key, userId, data, expiresAt) {
      database[key] = {
        userId,
        data,
        expiresAt,
        cachedAt: new Date().getTime()
      };
    },
    read<T>(key: string) {
      const value = key in database ? database[key] : null;

      if (!value) {
        return null;
      }

      if (value.expiresAt > new Date().getTime()) {
        delete database[key];
        return null;
      }

      return value as CacheResult<T>;
    }
  };
};
