import { createDrawerNavigator } from '@react-navigation/drawer';
import {
  HomeIcon,
  MoreInfoIcon,
  PlannerIcon,
  RecipesIcon
} from '../../components/icons';
import { ShoppingListIcon } from '../../components/icons/ShoppingListIcon';
import { useSizeClass } from '../../lib/responsive-style-sheet';
import { HomeNavigator } from '../home';
import { MoreInfoNavigator } from '../more-info';
import { PlannerNavigator } from '../planner/PlannerNavigator';
import { RecipesNavigator } from '../recipes';
import { DrawerContent } from './components';
import { MainNavigatorParamList } from './MainNavigatorParamList';
import { ShoppingListScreen } from './screens/shopping-list/ShoppingListScreen';

const Drawer = createDrawerNavigator<MainNavigatorParamList>();

export const LargeMainNavigator = (): JSX.Element => {
  const size = useSizeClass();

  return (
    <Drawer.Navigator
      drawerContent={props => <DrawerContent {...props} />}
      screenOptions={{
        drawerType: 'permanent',
        drawerStyle: {
          width: size === 'large' ? 216 : 100,
          borderRightWidth: 0,
          shadowOpacity: 0.3,
          shadowColor: '#34715D',
          shadowRadius: 36,
          shadowOffset: { width: 0, height: 6 }
        },
        headerShown: false
      }}
    >
      <Drawer.Screen
        name="home"
        component={HomeNavigator}
        options={{
          title: 'Home',
          drawerIcon: ({ color, size: iconSize }) => (
            <HomeIcon fill={color} width={iconSize} height={iconSize} />
          )
        }}
      />
      <Drawer.Screen
        name="planner"
        component={PlannerNavigator}
        options={{
          title: 'Planner',
          drawerIcon: ({ color, size: iconSize }) => (
            <PlannerIcon fill={color} width={iconSize} height={iconSize} />
          )
        }}
      />
      <Drawer.Screen
        name="recipes"
        component={RecipesNavigator}
        options={{
          title: 'Recipes',
          drawerIcon: ({ color, size: iconSize }) => (
            <RecipesIcon fill={color} width={iconSize} height={iconSize} />
          )
        }}
      />
      <Drawer.Screen
        name="shopping-list"
        component={ShoppingListScreen}
        options={{
          title: 'Shopping',
          drawerIcon: ({ color, size: iconSize }) => (
            <ShoppingListIcon fill={color} width={iconSize} height={iconSize} />
          )
        }}
      />
      <Drawer.Screen
        name="more-info"
        component={MoreInfoNavigator}
        options={{
          title: 'More',
          drawerIcon: ({ color, size: iconSize }) => (
            <MoreInfoIcon fill={color} width={iconSize} height={iconSize} />
          )
        }}
      />
    </Drawer.Navigator>
  );
};
