/* eslint-disable no-restricted-syntax */
import { RecipeListResponseDataItem } from '@showme-fit/smf-app-gateway-client';
import { ComponentType } from 'react';
import { range } from 'ts-array-extensions';
import { TagProps } from '../../../tag';
import { TagIcon } from '../../../tag-icons';

const map = <T>(
  data: T | undefined,
  format: (value: T) => string | null,
  iconComponent: ComponentType
): TagProps | null => {
  if (!data) {
    return null;
  }

  const value = format(data);

  if (!value) {
    return null;
  }

  return {
    iconComponent,
    values: [value]
  };
};

const mapDietary = (recipe: RecipeListResponseDataItem): TagProps[] => {
  return (
    recipe.attributes?.dietary_requirements?.data?.compactMap(d => {
      if (d.attributes && d.attributes.code) {
        return {
          iconText: d.attributes.code,
          values: [d.attributes.name].compact()
        };
      }

      return null;
    }) ?? []
  );
};

const cuisinesNamesWorkaround = (
  recipe: RecipeListResponseDataItem
): string[] => {
  if (!recipe.attributes?.cuisines?.data) {
    return [];
  }

  return recipe.attributes.cuisines.data.compactMap(({ attributes }) => {
    if (attributes && 'name' in attributes) {
      return (attributes as { name: string }).name;
    }

    return null;
  });
};

const mapCuisines = (recipe: RecipeListResponseDataItem): TagProps[] =>
  cuisinesNamesWorkaround(recipe).map(c => {
    return {
      iconComponent: TagIcon.Cuisine,
      values: [c]
    };
  });

export function mapTags(recipe: RecipeListResponseDataItem): TagProps[] {
  return [
    map(recipe.attributes?.prepTime, v => `Prep time: ${v} mins`, TagIcon.Time),

    map(
      recipe.attributes?.cookTime,
      v => `Cooking time: ${v} mins`,
      TagIcon.Time
    ),

    map(
      recipe.attributes?.servingSize,
      v => `Serves: ${v}`,
      TagIcon.ServingSize
    ),

    map(recipe.attributes?.difficultyLevel, v => v, TagIcon.DifficultyLevel),

    ...mapDietary(recipe),

    map(
      recipe.attributes?.familyFriendly,
      v => (v ? 'Family friendly' : null),
      TagIcon.FamilyFriendly
    ),

    map(
      recipe.attributes?.freezer,
      v => (v ? 'Suitable for freezing' : null),
      TagIcon.Freezer
    ),

    map(
      recipe.attributes?.slowCooker,
      v => (v ? 'Slow cooker' : null),
      TagIcon.Timer
    ),

    map(
      recipe.attributes?.priceGuide,
      v =>
        range(1, v)
          .map(() => '£')
          .join(''),
      TagIcon.PriceGuide
    ),

    ...mapCuisines(recipe)
  ].compact();
}
