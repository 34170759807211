import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export const PriceGuide = (props: SvgProps) => (
  <Svg {...props} width={16} height={16} fill="none">
    <G clipPath="url(#a)" fill="#A6C1B8">
      <Path d="M14.273 7.607 8.387 1.721a1.332 1.332 0 0 0-.94-.387h-4.78c-.734 0-1.334.6-1.334 1.333v4.78c0 .354.14.694.394.94l5.886 5.887c.52.52 1.367.52 1.887 0l4.78-4.78c.52-.52.52-1.36-.007-1.887Zm-5.72 5.727L2.667 7.447v-4.78h4.78l5.886 5.887-4.78 4.78Z" />
      <Path d="M4.333 5.334a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
