import { ComponentType } from 'react';
import { View, StyleSheet } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { Colours } from '../../colours';
import { Text } from '../text';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colours.primaryLight5.$,
    borderRadius: 20,
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 29,
    marginHorizontal: 29
  },
  element: {
    marginVertical: 4
  },
  text: {
    fontSize: 14,
    textAlign: 'center'
  }
});

export type HintProps = {
  icon: ComponentType<SvgProps>;
  text: string;
};

export const Hint = ({ icon: Icon, text }: HintProps): JSX.Element => (
  <View style={styles.container}>
    <Icon style={styles.element} fill={Colours.accentLight55.$} />
    <Text.Hint style={[styles.element, styles.text]}>{text}</Text.Hint>
  </View>
);
