import { HeaderBackButtonProps } from '@react-navigation/elements';
import { Platform } from 'react-native';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';
import { MiniButton } from '../mini-button';
import { ChevronIcon } from './ChevronIcon';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    marginLeft: 24,
    paddingHorizontal: 12,
    backgroundColor: '#F2FDF9'
  }
}).override(
  { medium: true, large: true },
  {
    container: {
      marginLeft: 45
    }
  }
);

export const HeaderBackButton = (props: HeaderBackButtonProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  if (!props.canGoBack) return <></>;

  if (Platform.OS === 'web') return <></>;

  return (
    <>
      <MiniButton {...props} component={ChevronIcon} style={styles.container} />
    </>
  );
};
