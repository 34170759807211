import 'ts-array-extensions';
import '@expo/match-media';
import 'react-native-get-random-values';

import { StatusBar } from 'react-native';
import * as Linking from 'expo-linking';
import * as SplashScreen from 'expo-splash-screen';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import Toast from 'react-native-toast-message';
import { AppNavigator } from './navigators/app/AppNavigator';
import { AppNavigatorParamList } from './navigators/app/AppNavigatorParamList';
import { Colours } from './colours';
import { AppContext } from './AppContext';
import { createLogger } from './lib/logging';
import { nameof } from './lib/name-of';
import { useGlobalSettings } from './components/global-settings';
import { toastConfig } from './components/toast-config/ToastConfig';
import { usePurchaseUpdate } from './lib/in-app-purchase/usePurchaseUpdate';

void SplashScreen.preventAutoHideAsync();

const prefix = Linking.createURL('/');

export type AppProps = LaunchArguments;

export const App = (props: AppProps): JSX.Element => {
  const log = createLogger(nameof({ App }));

  const [init, setInit] = useState(true);

  const globalSettings = useGlobalSettings();

  usePurchaseUpdate();

  useEffect(() => {
    if (!init) return;

    if (props.reset) {
      log.info('Resetting');
      globalSettings.clearAll();
    }

    setInit(false);
  }, [init]);

  const linking: LinkingOptions<AppNavigatorParamList> = {
    prefixes: [prefix],
    config: {
      screens: {
        app: {
          path: 'app',
          screens: {
            welcome: {
              path: 'welcome',
              screens: {
                signInMagic: {
                  path: 'sign-in-magic'
                }
              }
            },
            main: {
              screens: {
                home: {
                  screens: {
                    homepage: {
                      path: 'home'
                    }
                  }
                },
                planner: {
                  path: 'plan',
                  screens: {
                    plan: ':date',
                    addRecipe: {
                      path: ':date/add-recipe'
                    }
                  }
                },
                recipes: {
                  screens: {
                    show: {
                      path: 'recipes/:recipeId'
                    },
                    list: {
                      path: 'recipes'
                    }
                  }
                },
                'shopping-list': 'shopping-list',
                'more-info': {
                  path: 'more-info',
                  screens: {
                    index: '',
                    'delete-account': 'delete-account'
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <>
      <StatusBar
        backgroundColor={Colours.transparent.$}
        barStyle="light-content"
        translucent
      />
      <NavigationContainer linking={linking}>
        <AppContext launchArguments={props}>
          <AppNavigator />
        </AppContext>
      </NavigationContainer>
      <Toast config={toastConfig} />
    </>
  );
};
