import { Image, Pressable, StyleSheet, View } from 'react-native';
import { Colours } from '../../colours';
import { Text } from '../text';
import { nameof } from '../../lib/name-of';
import { RecipeTileTags } from '../recipe-tile-tags/RecipeTileTags';
import { RecipeTileProps } from './RecipeTileProps';

const styles = StyleSheet.create({
  tile: {
    backgroundColor: Colours.white.$,
    borderRadius: 15,
    flexDirection: 'row',
    padding: 4,
    margin: 16
  },
  image: {
    borderRadius: 10,
    width: 130,
    height: 130,
    margin: 4
  },
  titleAndTags: {
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 1,
    margin: 4
  },
  title: {
    color: Colours.primaryDark55.$,
    margin: 8,
    marginVertical: 8,
    flex: 1,
    flexWrap: 'wrap'
  }
});

export const MediumRecipeTile = ({
  recipe,
  style,
  ...rest
}: RecipeTileProps): JSX.Element => {
  return (
    <Pressable style={style} {...rest} testID={nameof({ MediumRecipeTile })}>
      <View style={styles.tile}>
        <Image
          source={{
            uri: recipe.mainImageUrl
          }}
          style={[styles.image]}
        />
        <View style={styles.titleAndTags}>
          <Text.Body style={styles.title} accessibilityRole="header">
            {recipe.dishName}
          </Text.Body>
          <RecipeTileTags recipe={recipe} />
        </View>
      </View>
    </Pressable>
  );
};
