import { RecipeResponseDataObject } from '@showme-fit/smf-app-gateway-client';
import { ShoppingListItem } from '../../../../resources/shopping-list/ShoppingListItem';

export const mapRecipeForShoppingList = (
  recipe: RecipeResponseDataObject,
  date: string,
  meal: 'breakfast' | 'lunch' | 'dinner'
): ShoppingListItem[] => {
  return (
    recipe.attributes?.ingredients?.compactMap(({ quantity, product }) => {
      const productId = product?.data?.id;
      const attributes = product?.data?.attributes;

      if (!quantity || !attributes || !productId) return null;

      const { unit, name: productName, product_group: group } = attributes;

      if (!productName) {
        return null;
      }

      const productGroupId = group?.data?.id;
      const productGroupName = group?.data?.attributes?.name;

      return {
        compositeKey: `${date}-${meal}-${productId}`,
        date,
        meal,
        productId,
        productName,
        productGroupId,
        productGroupName,
        unit,
        quantityRequired: quantity,
        quantityChecked: 0
      };
    }) ?? []
  );
};
