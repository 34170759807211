import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export const Time = (props: SvgProps) => (
  <Svg {...props} width={17} height={16} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M8.493 1.333A6.663 6.663 0 0 0 1.833 8c0 3.68 2.98 6.666 6.66 6.666A6.67 6.67 0 0 0 15.167 8a6.67 6.67 0 0 0-6.674-6.667Zm.007 12A5.332 5.332 0 0 1 3.167 8 5.332 5.332 0 0 1 8.5 2.666 5.332 5.332 0 0 1 13.833 8 5.332 5.332 0 0 1 8.5 13.333Zm.333-8.667h-1v4l3.5 2.1.5-.82-3-1.78v-3.5Z"
        fill="#A6C1B8"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(.5)" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
