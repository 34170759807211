import { ReactNode } from 'react';
import {
  useFonts,
  DMSans_400Regular,
  DMSans_500Medium,
  DMSans_700Bold
} from '@expo-google-fonts/dm-sans';
import { RetainSplash } from '../../lib/retain-splash';

export type FontsContainerProps = { children: ReactNode };

export const FontsContainer = ({
  children
}: FontsContainerProps): JSX.Element => {
  const [fontsLoaded] = useFonts({
    DMSans_400Regular,
    DMSans_500Medium,
    DMSans_700Bold
  });

  if (!fontsLoaded) {
    return <RetainSplash />;
  }

  return <>{children}</>;
};
