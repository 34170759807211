import { View, Image, ViewProps } from 'react-native';
import imageOne from './image_one.png';
import imageTwo from './image_two.png';
import imageThree from './image_three.png';
import imageFour from './image_four.png';
import imageFive from './image_five.png';
import imageSix from './image_six.png';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flexDirection: 'row'
  },
  image: {
    margin: 5,
    width: 120,
    height: 120
  }
}).override(
  { small: true },
  {
    container: {
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    image: {
      width: 100,
      height: 100
    }
  }
);

export const Carousel = ({
  style,
  ...rest
}: Omit<ViewProps, 'children'>): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <View style={[style, styles.container]} {...rest}>
      <Image source={imageOne} style={styles.image} />
      {[imageTwo, imageThree, imageFour, imageFive, imageSix].map(
        (source, index) => (
          <Image key={index} source={source} style={styles.image} />
        )
      )}
    </View>
  );
};
