import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import { useEffect, useMemo, useState } from 'react';
import {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useSharedValue
} from 'react-native-reanimated';
import { Colours } from '../../../../colours';
import { nameof } from '../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { RecipesNavigatorParamList } from '../../RecipesNavigatorParamList';
import { RecipeList } from '../../../../components/recipe-list';
import { RecipeListScreenHeader } from './RecipeListScreenHeader';

export type RecipeListScreenProps = StackScreenProps<
  RecipesNavigatorParamList,
  'list'
>;

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colours.primaryLight5.$
  }
});

export const RecipeListScreen = ({
  navigation,
  route: { params }
}: RecipeListScreenProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const elevation = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler(event => {
    elevation.value = interpolate(
      event.contentOffset.y,
      [0, 30],
      [0, 1],
      Extrapolation.CLAMP
    );
  });

  const [searchActive, setSearchActive] = useState(false);

  const searchValue = useMemo(() => params?.search ?? '', [params]);

  useEffect(() => {
    navigation.setOptions({
      header: () => (
        <RecipeListScreenHeader
          elevation={elevation}
          onFocussedChanged={setSearchActive}
        />
      )
    });
  }, []);

  return (
    <>
      <StatusBar style="dark" />
      <RecipeList
        filterActive={searchActive || !!searchValue}
        filterText={searchValue}
        style={styles.container}
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        testID={nameof({ RecipeListScreen })}
        onPress={id =>
          navigation.navigate('show', {
            recipeId: id
          })
        }
      />
    </>
  );
};
