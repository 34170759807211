import { useContext } from 'react';
import { nameof } from '../../lib/name-of';
import { UserSessionContext } from './UserSessionContext';

export const useSession = () => {
  const hookValue = useContext(UserSessionContext);
  if (!hookValue) {
    throw new Error(
      `${nameof({
        useSession
      })}() hook requires a ${nameof({
        UserSessionContext
      })} within the component hierarchy.`
    );
  }
  return hookValue;
};
