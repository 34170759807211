import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useSession } from '../../components/user-manager';
import { Subject, Subscriber } from '../../lib/pub-sub';
import { table } from '../../lib/table-store';
import { PlanResource } from './PlanResource';

export type PlansResourceContextValue = {
  subscribe(date: string, subscriber: Subscriber<PlanResource>): () => void;
  publish(date: string, value: PlanResource): void;
  getCurrentValue(date: string): PlanResource | null;
};

export const PlansResourceContext =
  createContext<PlansResourceContextValue | null>(null);

export const PlansResourceContextProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const { currentSession } = useSession();

  if (!currentSession) {
    throw new Error('No current session');
  }

  const { userId } = currentSession;

  const store = useMemo(
    () => table<PlanResource>(`plans-${userId}`, 'date'),
    [userId]
  );

  const [subjects] = useState<Map<string, Subject<PlanResource>>>(new Map());

  const getOrCreateSubject = useCallback(
    (date: string) => {
      let subject = subjects.get(date);
      if (!subject) {
        subject = new Subject();
        subjects.set(date, subject);
      }
      return subject;
    },
    [subjects]
  );

  const contextValue: PlansResourceContextValue = useMemo(
    () => ({
      getCurrentValue(date) {
        return store.read(date);
      },
      publish(date, value) {
        store.update(date, () => value);
        getOrCreateSubject(date).next(value);
      },
      subscribe(date, subscriber) {
        return getOrCreateSubject(date).subscribe(subscriber);
      }
    }),
    [store, userId]
  );

  return (
    <PlansResourceContext.Provider value={contextValue}>
      {children}
    </PlansResourceContext.Provider>
  );
};
