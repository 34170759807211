import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSmfApi } from '../../api';
import { useSession } from '../../components/user-manager';
import { PlansResourceContext } from './PlansResourceContext';

export type DateRange = {
  fromDate: string;
  toDate: string;
};

export const usePlanResourceLoader = ({ fromDate, toDate }: DateRange) => {
  const context = useContext(PlansResourceContext);

  if (!context) {
    throw new Error('No PlansResourceContext');
  }

  const { currentSession } = useSession();

  if (!currentSession) {
    throw new Error('No current session');
  }

  const { userId } = currentSession;

  const { call, inProgress, result } = useSmfApi('getApiPlannerViewsPlanDay', {
    cacheStrategy: 'NO_CACHE'
  });

  const reload = useCallback(() => {
    call({ fromDate, toDate, userId });
  }, [fromDate, toDate, userId]);

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    if (result?.success && result.data.response.status === 200) {
      result.data.response.body.data.forEach(data =>
        context.publish(data.date, data)
      );
    }
  }, [result]);

  const error = useMemo(() => {
    if (!result) return null;

    if (!result.success) return result.reason;

    if (result.data.response.status !== 200) return result.data.response;

    return null;
  }, [result]);

  return { reload, inProgress, error };
};
