import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { BaseToast, ToastProps } from 'react-native-toast-message';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';

import { IconBubble } from '../../navigators/planner/components';

const ToastCheckIcon = (props: SvgProps) => (
  <Svg {...props} width={21} height={20} fill="none">
    <Path
      d="M20.484.992a.833.833 0 0 0-.573.252L7.167 13.988 1.923 8.744A.833.833 0 1 0 .744 9.923l5.833 5.833a.833.833 0 0 0 1.179 0L21.089 2.423a.833.833 0 0 0-.605-1.431Z"
      stroke="black"
      strokeWidth={2.375}
    />
  </Svg>
);

export const toastConfig = {
  mobile: (props: ToastProps) => (
    <View pointerEvents="none">
      <BaseToast
        {...props}
        style={{
          backgroundColor: Colours.white.$,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 8,
          height: 80,
          width: '92%',
          shadowColor: 'rgba(52, 113, 93, 0.3)',
          shadowOffset: { width: 0, height: 6 },
          shadowOpacity: 1,
          shadowRadius: 36,
          borderRadius: 15,
          marginTop: 16,
          borderWidth: 2,
          borderLeftWidth: 2,
          borderLeftColor: Colours.primaryLight35.$,
          borderColor: Colours.primaryLight35.$
        }}
        contentContainerStyle={{
          height: '100%',
          width: '100%',
          flex: 0.8
        }}
        text1Style={{
          fontFamily: Fonts.DMSans_500Medium,
          textAlign: 'center',
          fontWeight: '700',
          fontSize: 14
        }}
        renderLeadingIcon={() => <IconBubble iconComponent={ToastCheckIcon} />}
      />
    </View>
  ),
  web: (props: ToastProps) => (
    <View
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: '8%'
      }}
    >
      <BaseToast
        {...props}
        style={{
          backgroundColor: Colours.white.$,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 8,
          height: 80,
          width: 363,
          shadowColor: 'rgba(52, 113, 93, 0.3)',
          shadowOffset: { width: 0, height: 6 },
          shadowOpacity: 1,
          shadowRadius: 36,
          borderWidth: 2,
          borderLeftWidth: 2,
          borderLeftColor: Colours.primaryLight35.$,
          borderColor: Colours.primaryLight35.$,
          borderRadius: 15
        }}
        contentContainerStyle={{
          height: '100%',
          width: '100%',
          flex: 0.8
        }}
        text1Style={{
          fontFamily: Fonts.DMSans_500Medium,
          textAlign: 'center',
          fontWeight: '700',
          fontSize: 14
        }}
        renderLeadingIcon={() => <IconBubble iconComponent={ToastCheckIcon} />}
      />
    </View>
  ),
  removeMobile: (props: ToastProps) => (
    <View pointerEvents="none">
      <BaseToast
        {...props}
        style={{
          backgroundColor: Colours.white.$,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 8,
          height: 80,
          width: '92%',
          shadowColor: 'rgba(52, 113, 93, 0.3)',
          shadowOffset: { width: 0, height: 6 },
          shadowOpacity: 1,
          shadowRadius: 36,
          borderRadius: 15,
          marginTop: 16,
          borderWidth: 2,
          borderLeftWidth: 2,
          borderLeftColor: Colours.primaryLight35.$,
          borderColor: Colours.primaryLight35.$
        }}
        contentContainerStyle={{
          height: '100%',
          width: '100%',
          flex: 0.8
        }}
        text1Style={{
          fontFamily: Fonts.DMSans_500Medium,
          textAlign: 'center',
          fontWeight: '700',
          fontSize: 14
        }}
        renderLeadingIcon={() => <IconBubble iconComponent={ToastCheckIcon} />}
      />
    </View>
  ),
  removeWeb: (props: ToastProps) => (
    <View
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: '8%'
      }}
    >
      <BaseToast
        {...props}
        style={{
          backgroundColor: Colours.white.$,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 8,
          height: 80,
          width: 363,
          shadowColor: 'rgba(52, 113, 93, 0.3)',
          shadowOffset: { width: 0, height: 6 },
          shadowOpacity: 1,
          shadowRadius: 36,
          borderWidth: 2,
          borderLeftWidth: 2,
          borderLeftColor: Colours.primaryLight35.$,
          borderColor: Colours.primaryLight35.$,
          borderRadius: 15
        }}
        contentContainerStyle={{
          height: '100%',
          width: '100%',
          flex: 0.8
        }}
        text1Style={{
          fontFamily: Fonts.DMSans_500Medium,
          textAlign: 'center',
          fontWeight: '700',
          fontSize: 14
        }}
        renderLeadingIcon={() => <IconBubble iconComponent={ToastCheckIcon} />}
      />
    </View>
  )
};
