import { Pressable, PressableProps, Text, ViewStyle } from 'react-native';
import { Colours } from '../../../../../../colours';
import { AddIcon } from '../../../../../../components/icons';
import { Fonts } from '../../../../../../fonts';
import { nameof } from '../../../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../../../lib/responsive-style-sheet';
import { IconBubble } from '../../../../components/icon-bubble';

export type AddRecipeCardProps = Omit<PressableProps, 'children'>;

const rStyles = ResponsiveStyleSheet.create({
  card: {
    backgroundColor: Colours.white.$,
    borderRadius: 15,
    borderColor: Colours.primaryLight75.$,
    borderWidth: 2,
    borderStyle: 'dashed',
    padding: 12,
    alignItems: 'center'
  },
  title: {
    fontFamily: Fonts.DMSans_500Medium,
    fontSize: 16,
    color: Colours.primaryDark55.$,
    margin: 6
  },
  addBubble: {
    margin: 6
  }
});

export const AddRecipeCard = ({
  style,
  ...rest
}: AddRecipeCardProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <Pressable
      testID={nameof({ AddRecipeCard })}
      style={[styles.card, style as ViewStyle]}
      {...rest}
    >
      <Text style={styles.title}>Add recipe</Text>
      <IconBubble style={styles.addBubble} iconComponent={AddIcon} />
    </Pressable>
  );
};
