import { StyleSheet, Text } from 'react-native';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import { Tag } from '../tag';
import { TagIcon } from '../tag-icons';
import { RecipeTagProps } from './RecipeTagProps';

const styles = StyleSheet.create({
  text: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 12,
    lineHeight: 18
  },
  solid: {
    color: Colours.accentLight75.$
  },
  nonSolid: {
    color: Colours.accentLight35.$
  }
});

export const PriceGuide = ({
  recipe: { priceGuide },
  ...rest
}: RecipeTagProps): JSX.Element => {
  if (!priceGuide) return <></>;

  const solidText = '£'.repeat(priceGuide);

  const nonSolidText =
    priceGuide >= 1 && priceGuide <= 3 ? '£'.repeat(3 - priceGuide) : null;

  return (
    <Tag iconComponent={TagIcon.PriceGuide} {...rest}>
      <Text style={[styles.text, styles.solid]}>{solidText}</Text>
      {nonSolidText ? (
        <Text style={[styles.text, styles.nonSolid]}>{nonSolidText}</Text>
      ) : null}
    </Tag>
  );
};
