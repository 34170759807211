import { useContext } from 'react';
import { nameof } from '../../../../lib/name-of';
import { RecipeContext } from './RecipeContext';

export const useRecipeContext = () => {
  const hookValue = useContext(RecipeContext);
  if (!hookValue) {
    throw new Error(
      `${nameof({ useRecipeContext })}() hook requires a ${nameof({
        RecipeContext
      })} within the component hierarchy.`
    );
  }
  return hookValue;
};
