import Svg, { SvgProps, Path } from 'react-native-svg';

export const SubtractIcon = (props: SvgProps) => (
  <Svg {...props} width={20} height={20} fill="none">
    <Path
      d="M5 10h10"
      stroke="#004E34"
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </Svg>
);
