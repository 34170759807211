import Svg, { SvgProps, Path } from 'react-native-svg';

export const AddIcon = ({ color = '#004E34', ...rest }: SvgProps) => (
  <Svg {...rest} width={21} height={20} fill="none">
    <Path
      d="M16.393 10H4.607M10.5 4.107v11.786"
      stroke={color}
      strokeWidth={2}
    />
  </Svg>
);
