import { useSizeClass } from '../../lib/responsive-style-sheet';
import { LargeRecipeTile } from './LargeRecipeTile';
import { MediumRecipeTile } from './MediumRecipeTile';
import { RecipeTileProps } from './RecipeTileProps';
import { SmallRecipeTile } from './SmallRecipeTile';

export const RecipeTile = (props: RecipeTileProps): JSX.Element => {
  const size = useSizeClass();

  switch (size) {
    case 'medium':
      return <MediumRecipeTile {...props} />;
    case 'large':
      return <LargeRecipeTile {...props} />;
    default:
      return <SmallRecipeTile {...props} />;
  }
};
