import Toast from 'react-native-toast-message';

export const useToast = (os: string, action: string) => {
  if (os !== 'web' && action === 'add')
    Toast.show({
      type: 'mobile',
      text1: `Recipe added`,
      visibilityTime: 1500
    });
  else if (action === 'add') {
    Toast.show({
      type: 'web',
      text1: `Recipe added`,
      visibilityTime: 1500
    });
  } else if (os !== 'web' && action === 'remove') {
    Toast.show({
      type: 'removeMobile',
      text1: 'Recipe removed',
      visibilityTime: 1500
    });
  } else {
    Toast.show({
      type: 'removeWeb',
      text1: 'Recipe removed',
      visibilityTime: 1500
    });
  }
};
