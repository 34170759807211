import { createStackNavigator } from '@react-navigation/stack';
import { HomeNavigatorParamsList } from './HomeNavigatorParamList';
import { HomeScreen } from './screens';

const Stack = createStackNavigator<HomeNavigatorParamsList>();
export const HomeNavigator = (): JSX.Element => {
  return (
    <Stack.Navigator
      screenOptions={{ headerTransparent: true, headerTitle: '' }}
    >
      <Stack.Screen name="homepage" component={HomeScreen} />
    </Stack.Navigator>
  );
};
