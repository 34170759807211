import { createContext, ReactNode, useCallback, useState } from 'react';
import { Subject } from '.';

export type PubSubBrokerContextValue = {
  getOrCreateSubject<T>(topic: string): Subject<T>;
};

export const PubSubBrokerContext =
  createContext<PubSubBrokerContextValue | null>(null);

export const PubSubBroker = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [subjects] = useState<Map<string, Subject<unknown>>>(new Map());

  const getOrCreateSubject = useCallback(
    (topic: string) => {
      let subject = subjects.get(topic);
      if (!subject) {
        subject = new Subject();
        subjects.set(topic, subject);
      }
      return subject;
    },
    [subjects]
  );

  return (
    <PubSubBrokerContext.Provider
      value={{
        getOrCreateSubject<T>(topic: string) {
          return getOrCreateSubject(topic) as Subject<T>;
        }
      }}
    >
      {children}
    </PubSubBrokerContext.Provider>
  );
};
