import { ReactNode } from 'react';
import { View } from 'react-native';
import { Colours } from '../../colours';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';

export type TabBarProps = {
  children?: ReactNode;
};

const rStyles = ResponsiveStyleSheet.create({
  bar: {
    backgroundColor: Colours.primaryLight5.$,
    borderRadius: 21,
    flexDirection: 'row',
    padding: 8,
    marginBottom: 18,
    alignSelf: 'flex-start'
  }
}).override({ small: true }, { bar: { width: '100%' } });

export const TabBar = ({ children }: TabBarProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return <View style={styles.bar}>{children}</View>;
};
