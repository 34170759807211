import { StackScreenProps } from '@react-navigation/stack';
import { useCallback } from 'react';
import { NextButton, TourScreen } from '../../components';
import { WelcomeNavigatorParamsList } from '../../WelcomeNavigatorParamsList';
import imageSource from './image.png';

export type HeadlinesScreenProps = StackScreenProps<
  WelcomeNavigatorParamsList,
  'headlines'
>;

export const HeadlinesScreen = ({
  navigation
}: HeadlinesScreenProps): JSX.Element => {
  const onNext = useCallback(() => navigation.push('recipes'), [navigation]);

  return (
    <TourScreen
      pageNumber={1}
      smallStatusBarStyle="dark"
      imageSource={imageSource}
      title="Eat healthily, lose weight & save money"
      body="Losing weight isn’t about dieting, it’s about making changes over time that equal big results. I can show you how to lose weight, gain health and save money eating big, hearty and tasty meals!"
    >
      <NextButton onNext={onNext} />
    </TourScreen>
  );
};
