export type Subscriber<T> = { next: (v: T) => void };

export class Subject<T> {
  private readonly subscribers: Map<number, Subscriber<T>> = new Map();

  private _current: T | null = null;

  public get current() {
    return this._current;
  }

  private static lastId = 0;

  private static nextId() {
    this.lastId += 1;
    return this.lastId;
  }

  public next(value: T) {
    this._current = value;
    this.subscribers.forEach(subscriber => {
      subscriber.next(value);
    });
  }

  public subscribe(subscriber: Subscriber<T>) {
    const id = Subject.nextId();
    this.subscribers.set(id, subscriber);
    return () => {
      this.subscribers.delete(id);
    };
  }
}
