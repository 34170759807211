import { useCallback } from 'react';
import { Text, View } from 'react-native';
import { Colours } from '../../../../colours';
import { Modal, ModalProps } from '../../../../components/modal';
import { PrimaryButton } from '../../../../components/primary-button';
import { Fonts } from '../../../../fonts';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { useShoppingList } from '../../../../resources/shopping-list/useShoppingList';
import { ClearListIcon } from './ClearListIcon';

export type AddActivityModalProps = Omit<ModalProps, 'children'>;

const rStyles = ResponsiveStyleSheet.create({
  button: {
    backgroundColor: Colours.primaryLight100.$,
    margin: 30,
    alignSelf: 'center',
    paddingHorizontal: '12.5%'
  },
  text: {
    fontFamily: Fonts.DMSans_700Bold,
    color: Colours.accentLight55.$,
    fontSize: 14,
    letterSpacing: -0.2,
    textAlign: 'center',
    marginHorizontal: 30
  },
  iconContainer: {
    alignSelf: 'center',
    backgroundColor: '#D5FAEE',
    padding: 9,
    margin: 30,
    borderRadius: 10
  }
}).override(
  { medium: true, large: true },
  {
    modal: {
      width: 392,
      alignSelf: 'center'
    }
  }
);

export const ConfirmClearModal = ({
  onClose,
  style,
  ...rest
}: AddActivityModalProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const { rows, delete: deleteOne } = useShoppingList();

  const onClearPress = useCallback(() => {
    rows.forEach(row => {
      if (row.quantityChecked >= row.quantityRequired) {
        deleteOne(row.compositeKey);
      }
    });

    onClose();
  }, [rows, deleteOne]);

  return (
    <Modal onClose={onClose} style={[styles.modal, style]} {...rest}>
      <View>
        <View style={styles.iconContainer}>
          <ClearListIcon fill={Colours.accentLight55.$} />
        </View>
        <Text style={styles.text}>Remove all ticked items from this list?</Text>
        <PrimaryButton
          title="Clear"
          style={styles.button}
          onPress={onClearPress}
        />
      </View>
    </Modal>
  );
};
