import { RecipeListResponseDataItem } from '@showme-fit/smf-app-gateway-client';
import { createContext, ReactNode } from 'react';
import { useRecipeResource } from '../../../../resources';

type RecipeContextValue = {
  recipe: RecipeListResponseDataItem | null;
  inProgress: boolean;
  error: unknown;
};

export const RecipeContext = createContext<RecipeContextValue | null>(null);

export type RecipeContextProviderProps = {
  children: ReactNode;
  recipeId: number;
};

export const RecipeContextProvider = ({
  children,
  recipeId
}: RecipeContextProviderProps): JSX.Element => {
  const value = useRecipeResource({ id: recipeId });

  return (
    <RecipeContext.Provider value={value}>{children}</RecipeContext.Provider>
  );
};
