import { useMemo, useState } from 'react';
import { Text, View, ViewProps } from 'react-native';
import { Colours } from '../../../../colours';
import { Fonts } from '../../../../fonts';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { Tag } from '../../../tag';
import { useRecipeContext } from '../context';
import { ShowButton } from '../show-button/ShowButton';
import { mapEquipment } from './mapEquipment';
import { mapTags } from './mapTags';

export type TagsProps = Omit<ViewProps, 'children'>;

const rStyles = ResponsiveStyleSheet.create({
  container: {
    alignItems: 'flex-start'
  },
  tag: {
    marginVertical: 4
  },
  equipmentHeading: {
    color: Colours.accentLight55.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 14,
    lineHeight: 50,
    letterSpacing: -0.25
  },
  showButton: {
    marginTop: 16
  }
});

export const Tags = (rest: TagsProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const { recipe } = useRecipeContext();

  const tags = useMemo(() => (recipe ? mapTags(recipe) : []), [recipe]);

  const equipment = useMemo(
    () => (recipe ? mapEquipment(recipe) : []),
    [recipe]
  );

  const rows = useMemo(
    () =>
      [
        ...tags.map((props, index) => (
          <Tag key={index} style={styles.tag} {...props} />
        )),

        equipment.any() ? (
          <Text
            key={tags.length}
            style={styles.equipmentHeading}
            accessibilityRole="header"
          >
            Equipment needed
          </Text>
        ) : null,

        ...equipment.map((props, index) => (
          <Tag key={tags.length + index + 1} style={styles.tag} {...props} />
        ))
      ].compact(),
    [tags, equipment]
  );

  const showToggleButton = useMemo(
    () => tags.length + equipment.length > 5,
    [tags, equipment]
  );

  const [toggled, setToggled] = useState(false);

  const slice = toggled ? rows : rows.slice(0, 5);

  return (
    <View {...rest}>
      <View style={styles.container}>{slice}</View>
      {showToggleButton && (
        <ShowButton
          style={styles.showButton}
          title={toggled ? 'Show less' : 'Show more'}
          onPress={() => setToggled(t => !t)}
        />
      )}
    </View>
  );
};
