import { TimeoutError } from './TimeoutError';

export const timeoutPromise = <T>(timeoutMs: number): Promise<T> =>
  new Promise<T>((_, reject) => {
    setTimeout(() => reject(new TimeoutError()), timeoutMs);
  });

export const timeout = <T>(
  timeoutMs: number,
  promise: Promise<T>
): Promise<T> => Promise.race([promise, timeoutPromise<T>(timeoutMs)]);
