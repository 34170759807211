import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { createLogger } from '../../lib/logging';
import { nameof } from '../../lib/name-of';
import { RetainSplash } from '../../lib/retain-splash';
import { useGlobalSettings } from '../global-settings';
import { UserSession } from '../global-settings/UserSession';

export const UserSessionContext = createContext<{
  currentSession: UserSession | null;
  setSessionAndStore: (session: UserSession | null) => void;
} | null>(null);

export const UserSessionContextProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const log = createLogger(nameof({ UserSessionContextProvider }));

  const [currentSession, setCurrentSession] = useState<UserSession | null>(
    null
  );

  const [init, setInit] = useState(true);

  const settings = useGlobalSettings();

  useEffect(() => {
    if (!init) {
      return;
    }

    const storedSession = settings.get('userSession');

    if (storedSession) {
      log.info('Using stored user session', {
        storedSession
      });

      setCurrentSession(storedSession);
    }

    setInit(false);
  }, []);

  const setSessionAndStore = useCallback(
    (session: UserSession | null) => {
      settings.set('userSession', session);
      setCurrentSession(session);
    },
    [setCurrentSession]
  );

  const value = useMemo(
    () => ({
      currentSession,
      setSessionAndStore
    }),
    [currentSession, setSessionAndStore]
  );

  if (init) {
    return <RetainSplash />;
  }

  return (
    <UserSessionContext.Provider value={value}>
      {children}
    </UserSessionContext.Provider>
  );
};
