import Svg, { SvgProps, Path } from 'react-native-svg';

export const PinIcon = (props: SvgProps) => (
  <Svg {...props} width={30} height={30} fill="none">
    <Path
      d="M15 3C8.373 3 3 8.373 3 15s5.373 12 12 12 12-5.373 12-12S21.627 3 15 3Zm-4.293 17.707a1 1 0 0 1-1.414-1.414l3.584-3.584 1.414 1.414-3.584 3.584Zm10.5-7.47-2.544 1.755A2.5 2.5 0 0 1 18.267 18L12 11.734a2.498 2.498 0 0 1 3.008-.396l1.755-2.544a1.815 1.815 0 0 1 2.777-.253l1.919 1.919a1.814 1.814 0 0 1-.252 2.777Z"
      fill="#fff"
    />
  </Svg>
);
