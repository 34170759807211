/* eslint-disable no-await-in-loop */
import { SubscriptionType } from '@showme-fit/smf-app-gateway-client';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useSmfApiClient } from '../../api/useSmfApiClient';
import { ApiContext } from '../../lib/api-hooked/ApiContext';
import { createLogger } from '../../lib/logging';
import { nameof } from '../../lib/name-of';
import { RetainSplash } from '../../lib/retain-splash';
import { sleep } from '../../lib/sleep-as-promised';
import { useGlobalSettings } from '../global-settings';
import { useSession } from './useSession';

export type UserSubscriptionContextValue = {
  subscriptionType: SubscriptionType | null;
  polling: boolean;
  pollForValue(
    acceptCandidate: (type: SubscriptionType) => boolean,
    signal: AbortSignal
  ): void;
};

export const UserSubscriptionContext =
  createContext<UserSubscriptionContextValue | null>(null);

export const UserSubscriptionContextProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [init, setInit] = useState(true);

  const settings = useGlobalSettings();

  const { currentSession } = useSession();

  const [polling, setPolling] = useState(true);

  const [subscriptionType, setSubscriptionType] =
    useState<SubscriptionType | null>(null);

  const client = useSmfApiClient();

  const log = createLogger(nameof({ UserSubscriptionContextProvider }));

  useEffect(() => {
    if (!init) {
      return;
    }

    const stored = settings.get('userSubscription');

    if (stored) {
      const { subscriptionType: type } = stored;

      log.info('Using stored subscription', {
        type
      });

      setSubscriptionType(type);
    }

    setInit(false);
  }, []);

  const context = useContext(ApiContext);

  const pollForValueAsync = useCallback(
    async (
      acceptCandidate: (type: SubscriptionType) => boolean,
      signal: AbortSignal
    ): Promise<void> => {
      if (!currentSession) {
        return;
      }

      setPolling(true);

      while (!signal.aborted) {
        try {
          const result =
            await client.getApiSubscriptionViewsUserSubscriptionStateByUserId(
              {
                userId: currentSession.userId,
                authorization: `Bearer ${
                  context?.options.authorizer?.getToken() ?? ''
                }`
              },
              { signal }
            );

          if (
            result.status === 401 ||
            (result.status === 400 &&
              result.body.fields.some(f =>
                f.path.startsWith('headers/Authorization')
              ))
          ) {
            context?.options.authorizer?.notifyAuthFailed();
          }

          if (
            result.status === 200 &&
            acceptCandidate(result.body.subscriptionType)
          ) {
            settings.set('userSubscription', result.body);

            setSubscriptionType(result.body.subscriptionType);
            setPolling(false);
            break;
          }

          log.debug('Subscription check failed', { result });
        } catch (error: unknown) {
          log.error('Error checking subscription type', { error });
        }

        log.debug('Waiting to check subscription again');
        await sleep(500, { signal });
      }
    },
    [setSubscriptionType, setPolling, currentSession]
  );

  useEffect(() => {
    if (init || !currentSession) {
      return () => {};
    }

    const abort = new AbortController();

    void pollForValueAsync(() => true, abort.signal);

    return () => abort.abort();
  }, [currentSession, init]);

  const value = useMemo<UserSubscriptionContextValue>(
    () => ({
      subscriptionType,
      polling,
      pollForValue: (
        acceptCandidate: (type: SubscriptionType) => boolean,
        signal: AbortSignal
      ) => {
        void pollForValueAsync(acceptCandidate, signal);
      }
    }),
    [subscriptionType, polling, pollForValueAsync]
  );

  if (init) {
    return <RetainSplash />;
  }

  return (
    <UserSubscriptionContext.Provider value={value}>
      {children}
    </UserSubscriptionContext.Provider>
  );
};
