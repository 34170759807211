import { useCallback } from 'react';
import {
  Linking,
  Pressable,
  StyleSheet,
  Text,
  TextProps,
  ViewProps
} from 'react-native';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';

export type WebLinkProps = Omit<ViewProps, 'children'> & {
  text: string;
  textStyle?: TextProps['style'];
  href: string;
};

const styles = StyleSheet.create({
  text: {
    color: Colours.black.$,
    textDecorationLine: 'underline',
    fontFamily: Fonts.DMSans_500Medium,
    fontSize: 12,
    lineHeight: 24
  }
});

export const WebLink = ({
  text,
  textStyle,
  href,
  style,
  ...rest
}: WebLinkProps): JSX.Element => {
  const onPress = useCallback(() => {
    void (async () => {
      const supported = await Linking.canOpenURL(href);

      if (supported) {
        await Linking.openURL(href);
      }
    })();
  }, [href]);

  return (
    <Pressable onPress={onPress} style={style} {...rest}>
      <Text style={[styles.text, textStyle]}>{text}</Text>
    </Pressable>
  );
};
