import { ShowmeFitAppGatewayClient } from '@showme-fit/smf-app-gateway-client';
import { useMemo } from 'react';
import { createLogger } from '../lib/logging';
import { nameof } from '../lib/name-of';
import { useConfig } from '../components/config';

export const useSmfApiClient = (): ShowmeFitAppGatewayClient => {
  const { serverUrl } = useConfig();

  return useMemo(() => {
    const log = createLogger(nameof({ useSmfApiClient }));
    return new ShowmeFitAppGatewayClient(serverUrl, log.info);
  }, [serverUrl]);
};
