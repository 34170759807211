import { StatusBar } from 'expo-status-bar';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colours } from '../../colours';
import { nameof } from '../../lib/name-of';
import { HorizontalRule } from '../horizontal-rule';
import {
  Heading,
  Body,
  Tags,
  Video,
  useRecipeContext,
  HeroImage
} from './components';

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colours.white.$
  },
  content: {
    alignItems: 'stretch'
  },
  panes: {
    flexDirection: 'row',
    paddingHorizontal: 60,
    paddingVertical: 30,
    alignItems: 'stretch',
    flexShrink: 1
  },
  leftPane: {
    maxWidth: 600
  },
  video: {
    marginBottom: 24
  },
  rightPane: {
    paddingLeft: 37,
    alignItems: 'flex-start',
    flexGrow: 1
  },
  divider: {
    marginVertical: 24,
    alignSelf: 'stretch'
  },
  bodyContainer: { maxWidth: width / 4 }
});

export const LargeRecipeShowScreen = (): JSX.Element => {
  const { recipe } = useRecipeContext();

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      contentInsetAdjustmentBehavior="automatic"
      testID={nameof({ LargeRecipeShowScreen })}
    >
      <StatusBar style="auto" />
      <SafeAreaView>
        <View style={styles.panes}>
          <View style={styles.leftPane}>
            {recipe?.attributes?.video?.data?.attributes ? (
              <Video style={styles.video} />
            ) : (
              <HeroImage style={styles.video} />
            )}
            <Tags />
          </View>
          <View style={styles.rightPane}>
            <Heading />
            <HorizontalRule
              color={Colours.accentLight5.$}
              style={styles.divider}
            />
            <View style={styles.bodyContainer}>
              <Body />
            </View>
          </View>
        </View>
      </SafeAreaView>
    </ScrollView>
  );
};
