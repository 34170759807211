import { BlurView } from 'expo-blur';
import {
  Modal as BuiltInModal,
  Pressable,
  Text,
  TouchableWithoutFeedback,
  ViewProps
} from 'react-native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';
import { CloseIcon } from '../icons';

export type ModalProps = ViewProps & {
  opened: boolean;
  onClose: () => void;
  hideCloseButton?: boolean;
  title?: string;
};

const rStyles = ResponsiveStyleSheet.create({
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  modal: {
    backgroundColor: Colours.white.$,
    borderRadius: 15,
    elevation: 50,
    padding: 10,
    shadowColor: Colours.black.$,
    shadowOffset: { width: 0, height: 15 },
    shadowOpacity: 0.4,
    shadowRadius: 30
  },
  title: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 20,
    textAlign: 'center',
    color: Colours.accentLight100.$,
    margin: 22
  },
  closeButton: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 12,
    right: 12,
    height: 38,
    width: 38
  }
});

export const Modal = ({
  opened,
  onClose,
  style,
  children,
  hideCloseButton = false,
  title,
  ...rest
}: ModalProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <BuiltInModal
      visible={opened}
      onRequestClose={onClose}
      transparent
      animationType="fade"
    >
      <SafeAreaProvider>
        <TouchableWithoutFeedback onPress={onClose}>
          <BlurView intensity={15} style={styles.modalOverlay} />
        </TouchableWithoutFeedback>

        <SafeAreaView
          edges={['top']}
          mode="margin"
          style={[styles.modal, style]}
          {...rest}
        >
          {title && <Text style={styles.title}>{title}</Text>}

          {children}

          {!hideCloseButton && (
            <Pressable onPress={onClose} style={styles.closeButton}>
              <CloseIcon />
            </Pressable>
          )}
        </SafeAreaView>
      </SafeAreaProvider>
    </BuiltInModal>
  );
};
