import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import { useCallback, useEffect, useMemo } from 'react';
import { ActivityIndicator, View } from 'react-native';
import {
  SafeAreaView,
  useSafeAreaInsets
} from 'react-native-safe-area-context';
import { useSmfApi } from '../../../../api';
import { Colours } from '../../../../colours';
import { useDeviceInfo } from '../../../../components/device-info-manager';
import { UserSession } from '../../../../components/global-settings/UserSession';
import { PrimaryButton } from '../../../../components/primary-button';
import { Text } from '../../../../components/text';
import { useSession } from '../../../../components/user-manager';
import { createLogger } from '../../../../lib/logging';
import { nameof } from '../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { WelcomeNavigatorParamsList } from '../../WelcomeNavigatorParamsList';
import { ExpiredIcon } from './ExpiredIcon';
import { SuccessIcon } from './SuccessIcon';

export type SignInMagicScreenProps = StackScreenProps<
  WelcomeNavigatorParamsList,
  'signInMagic'
>;

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    backgroundColor: Colours.primaryLight35.$,
    paddingVertical: 24
  },
  content: {
    flex: 1,
    alignItems: 'center',
    marginTop: 80
  },
  title: {
    textAlign: 'center',
    color: Colours.accentLight100.$,
    marginVertical: 20
  },
  body: {
    textAlign: 'center',
    color: Colours.accentLight75.$
  },
  form: {
    flexGrow: 1
  },
  button: {
    position: 'absolute'
  }
})
  .override(
    { medium: true, large: true },
    {
      container: {
        paddingVertical: 98
      },
      carousel: {
        alignSelf: 'center',
        marginTop: 50
      }
    }
  )
  .override(
    { small: true },
    {
      carousel: {
        display: 'none'
      }
    }
  )
  .override(
    { medium: true },
    {
      button: {
        width: 400,
        marginBottom: 100
      }
    }
  );

export const SignInMagicScreen = ({
  navigation,
  route: { params }
}: SignInMagicScreenProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const { args, call, inProgress, result } = useSmfApi('postApiSessions');

  const device = useDeviceInfo();

  const { setSessionAndStore } = useSession();

  const { bottom } = useSafeAreaInsets();

  const log = createLogger(nameof({ SignInMagicScreen }));

  useEffect(() => {
    log.debug('Params', params);

    if (!params?.id || !params.secret) {
      return;
    }

    const { id, secret } = params;

    call({
      body: { magicLinkId: id, secret, platformType: device.platformType }
    });
  }, [params]);

  const session = useMemo(() => {
    if (
      !args ||
      !result ||
      !result.success ||
      result.data.response.status !== 201
    ) {
      return undefined;
    }

    const s: UserSession = {
      ...result.data.response.body
    };

    return s;
  }, [result]);

  const finish = useCallback(() => {
    if (session) {
      setSessionAndStore(session);

      navigation.navigate('choosePlan');
    }
  }, [session]);

  const sendNewLink = useCallback(
    () => navigation.navigate('signIn'),
    [navigation]
  );

  return (
    <View style={styles.container} testID={nameof({ SignInMagicScreen })}>
      <StatusBar style="dark" />
      <SafeAreaView style={styles.content}>
        {inProgress ? (
          <ActivityIndicator />
        ) : (
          <>
            {session ? (
              <>
                <SuccessIcon />
                <Text.Title style={styles.title}>Signed in</Text.Title>
                <PrimaryButton
                  style={[styles.button, { bottom }]}
                  title="Continue"
                  onPress={finish}
                />
              </>
            ) : (
              <>
                <ExpiredIcon />
                <Text.Title style={styles.title}>Link expired</Text.Title>
                <Text.Body>
                  That ‘magic link’ for signing in has expired.
                </Text.Body>
                <PrimaryButton
                  style={[styles.button, { bottom }]}
                  title="Send a new link"
                  onPress={sendNewLink}
                />
              </>
            )}
          </>
        )}
      </SafeAreaView>
    </View>
  );
};
