import { createStackNavigator } from '@react-navigation/stack';
import { HeaderBackButton } from '../../components/header-back-button';
import { PlansResourceContextProvider } from '../../resources';
import { PlannerNavigatorParamList } from './PlannerNavigatorParamList';
import { PlanScreen } from './screens';
import { AddRecipeScreen } from './screens/add-recipe';
import { AddRecipeScreenHeader } from './screens/add-recipe/AddRecipeScreenHeader';
import { PlanScreenHeader } from './screens/plan/components';
import { RecipeShowScreen } from './screens/recipe-show';

const Stack = createStackNavigator<PlannerNavigatorParamList>();

const getDefaultDate = () => new Date().toISOString().substring(0, 10);

export const PlannerNavigator = (): JSX.Element => (
  <PlansResourceContextProvider>
    <Stack.Navigator>
      <Stack.Screen
        name="plan"
        component={PlanScreen}
        initialParams={{ date: getDefaultDate() }}
        options={{ header: props => <PlanScreenHeader {...props} /> }}
      />
      <Stack.Screen
        name="recipe"
        component={RecipeShowScreen}
        options={{
          headerTransparent: true,
          headerTitle: '',
          headerLeft: HeaderBackButton
        }}
      />

      <Stack.Group
        screenOptions={{
          presentation: 'modal'
        }}
      >
        <Stack.Screen
          name="addRecipe"
          component={AddRecipeScreen}
          options={{ header: () => <AddRecipeScreenHeader /> }}
        />
      </Stack.Group>
    </Stack.Navigator>
  </PlansResourceContextProvider>
);
