import { useCallback, useContext, useEffect, useState } from 'react';
import { nameof } from '../../lib/name-of';
import { PlanResource } from './PlanResource';
import { PlansResourceContext } from './PlansResourceContext';

export function usePlanResource(
  date: string
): [
  PlanResource | null,
  (fn: (value: PlanResource | null) => PlanResource) => void
] {
  const contextValue = useContext(PlansResourceContext);

  if (!contextValue) {
    throw new Error(
      `${nameof({ usePlanResource })}() hook requires a ${nameof({
        PlansResourceContext
      })} within the component hierarchy.`
    );
  }

  const { subscribe, publish, getCurrentValue } = contextValue;

  const [internalValue, setInternalValue] = useState<PlanResource | null>(() =>
    getCurrentValue(date)
  );

  useEffect(() => {
    return subscribe(date, { next: setInternalValue });
  }, [date]);

  const setValue = useCallback(
    (value: (value: PlanResource | null) => PlanResource) => {
      const newValue = value(internalValue);
      setInternalValue(newValue);
      publish(date, newValue);
    },
    [publish, internalValue, setInternalValue]
  );

  return [internalValue, setValue];
}
