import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Colours } from '../../colours';
import {
  HomeIcon,
  MoreInfoIcon,
  PlannerIcon,
  RecipesIcon
} from '../../components/icons';
import { ShoppingListIcon } from '../../components/icons/ShoppingListIcon';
import { Fonts } from '../../fonts';
import { HomeNavigator } from '../home/HomeNavigator';
import { MoreInfoNavigator } from '../more-info';
import { PlannerNavigator } from '../planner/PlannerNavigator';
import { RecipesNavigator } from '../recipes';
import { TabBarButton } from './components';
import { MainNavigatorParamList } from './MainNavigatorParamList';
import { ShoppingListScreen } from './screens/shopping-list/ShoppingListScreen';

const Tab = createBottomTabNavigator<MainNavigatorParamList>();

export const SmallMainNavigator = (): JSX.Element => {
  const { bottom } = useSafeAreaInsets();

  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarShowLabel: false,
        tabBarStyle: {
          paddingTop: 4,
          paddingBottom: bottom ? bottom / 2 : 8,
          height: 76 + bottom / 2,
          borderTopWidth: 0,
          shadowOpacity: 0.3,
          shadowColor: '#34715D',
          shadowRadius: 36,
          shadowOffset: { width: 0, height: 6 }
        }
      }}
    >
      <Tab.Screen
        name="home"
        component={HomeNavigator}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarButton icon={HomeIcon} focussed={focused} />
          )
        }}
      />
      <Tab.Screen
        name="planner"
        component={PlannerNavigator}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarButton icon={PlannerIcon} focussed={focused} />
          )
        }}
      />
      <Tab.Screen
        name="recipes"
        component={RecipesNavigator}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarButton icon={RecipesIcon} focussed={focused} />
          )
        }}
      />
      <Tab.Screen
        name="shopping-list"
        component={ShoppingListScreen}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarButton icon={ShoppingListIcon} focussed={focused} />
          ),
          headerShown: true,
          title: 'Your shopping list',
          headerTitleStyle: {
            fontFamily: Fonts.DMSans_700Bold,
            fontSize: 20,
            color: Colours.accentLight100.$,
            letterSpacing: -0.2
          },
          headerStyle: {
            elevation: 0,
            shadowOpacity: 0,
            borderBottomWidth: 0
          }
        }}
      />
      <Tab.Screen
        name="more-info"
        component={MoreInfoNavigator}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarButton icon={MoreInfoIcon} focussed={focused} />
          )
        }}
      />
    </Tab.Navigator>
  );
};
