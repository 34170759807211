import Svg, { SvgProps, Path } from 'react-native-svg';

export const SuccessIcon = (props: SvgProps) => (
  <Svg {...props} width={56} height={55} fill="none">
    <Path
      d="M28 5.5c-12.15 0-22 9.85-22 22s9.85 22 22 22 22-9.85 22-22-9.85-22-22-22Zm12.296 17.796-13.86 13.86a1.832 1.832 0 0 1-2.592 0l-6.33-6.33a1.831 1.831 0 0 1 0-2.593 1.831 1.831 0 0 1 2.592 0l5.034 5.035 12.564-12.564a1.831 1.831 0 0 1 2.592 0 1.831 1.831 0 0 1 0 2.592Z"
      fill="#004E34"
    />
  </Svg>
);
