import { RecipeListResponseDataItem } from '@showme-fit/smf-app-gateway-client';
import { StyleSheet, Text, View } from 'react-native';
import { Colours } from '../../../../colours';
import { Fonts } from '../../../../fonts';
import { useRecipeContext } from '../context';

const styles = StyleSheet.create({
  mealTypes: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  mealType: {
    color: Colours.accentLight55.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: -0.25
  },
  title: {
    color: Colours.primaryDark55.$,
    fontFamily: Fonts.DMSans_500Medium,
    fontSize: 28,
    lineHeight: 31,
    letterSpacing: -0.75
  },
  intro: {
    color: Colours.accentLight55.$,
    fontFamily: Fonts.DMSans_500Medium,
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: -0.25,
    marginBottom: 24
  },
  space: {
    marginBottom: 9
  },
  recipeAndCollaborator: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  recipeBy: {
    color: Colours.primaryDark55.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 12,
    lineHeight: 18,
    letterSpacing: -0.25,
    marginRight: 8
  },
  collaborator: {
    color: Colours.accentLight75.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 12,
    lineHeight: 18,
    letterSpacing: -0.25
  }
});

const collaboratorNameWorkaround = (
  recipe: RecipeListResponseDataItem
): string | null => {
  if (!recipe.attributes?.collaborator?.data?.attributes) {
    return null;
  }

  const { attributes } = recipe.attributes.collaborator.data;

  if ('name' in attributes) {
    return (attributes as { name: string }).name;
  }

  return null;
};

export const Heading = (): JSX.Element => {
  const { recipe } = useRecipeContext();

  if (!recipe) return <></>;

  const collaborator = collaboratorNameWorkaround(recipe);

  const chef = recipe.attributes?.chef?.data?.attributes?.name ?? 'SMF Team';

  return (
    <>
      <View style={[styles.mealTypes, styles.space]}>
        <Text style={styles.mealType}>{recipe.attributes?.mealType}</Text>
      </View>

      <Text style={[styles.title, styles.space]} accessibilityRole="header">
        {recipe.attributes?.dishName}
      </Text>

      {!!recipe.attributes?.introduction && (
        <Text style={styles.intro}>{recipe.attributes?.introduction}</Text>
      )}

      <View style={styles.recipeAndCollaborator}>
        <Text style={styles.recipeBy}>Recipe by {chef}</Text>
        {!!collaborator && (
          <Text style={styles.collaborator}>Collaborator: {collaborator}</Text>
        )}
      </View>
    </>
  );
};
