import { StatusBar } from 'expo-status-bar';
import { useCallback, useEffect } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { useSmfApi } from '../../../../api';
import { Colours } from '../../../../colours';
import { PrimaryButton } from '../../../../components/primary-button';
import { useSession } from '../../../../components/user-manager';
import { Fonts } from '../../../../fonts';
import { nameof } from '../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colours.white.$
  },
  content: {
    alignItems: 'stretch',
    padding: 8
  },
  main: {
    borderRadius: 20,
    padding: 22,
    backgroundColor: Colours.primaryLight5.$,
    alignItems: 'flex-start'
  },
  body: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 16,
    color: Colours.accentLight55.$,
    letterSpacing: -0.2,
    marginVertical: 10
  },
  button: {
    marginVertical: 20,
    paddingHorizontal: '12.5%'
  }
}).override(
  { large: true, medium: true },
  {
    content: {
      padding: 28
    },
    main: {
      padding: 32
    },
    button: {
      marginVertical: 30,
      paddingHorizontal: '6.25%'
    }
  }
);

export const DeleteAccountScreen = (): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const { call, inProgress, result } = useSmfApi(
    'deleteApiSessionsBySessionId'
  );

  const { currentSession, setSessionAndStore } = useSession();

  const onPress = useCallback(() => {
    if (!currentSession?.id) {
      return;
    }

    call({ sessionId: currentSession.id });
  }, [currentSession, call]);

  useEffect(() => {
    if (!result?.success) {
      return;
    }

    setSessionAndStore(null);
  }, [result]);

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      testID={nameof({ DeleteAccountScreen })}
    >
      <StatusBar style="dark" />
      <View style={styles.main}>
        <Text style={styles.body}>
          Deleting your account may take 48 hours to process.
        </Text>
        <PrimaryButton
          style={styles.button}
          onPress={onPress}
          title="Delete account"
          destructive
          disabled={inProgress}
        />
      </View>
    </ScrollView>
  );
};
