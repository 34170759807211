import { Children, ReactElement, ReactNode, useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { EagerMount } from './EagerMount';
import { LazyMount } from './LazyMount';
import { MountProps } from './MountProps';
import { Tab } from './Tab';
import { TabBar } from './TabBar';
import { TabbedPageProps } from './TabbedPage';

interface TabbedPageElement extends ReactElement {
  props: TabbedPageProps;
}

function isTabbedPageElement(func: ReactNode): func is TabbedPageElement {
  return func !== null && typeof func === 'object' && 'props' in func;
}

export type TabbedViewProps = ViewProps;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column'
  }
});

export const TabbedView = ({
  style,
  children
}: TabbedViewProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <View style={[styles.container, style]}>
      <TabBar>
        {Children.map(children, (child, index) => {
          if (!isTabbedPageElement(child)) return null;
          return (
            <Tab
              title={child.props.title ?? `Tab${index}`}
              isActive={activeIndex === index}
              onPress={() => setActiveIndex(index)}
            />
          );
        })}
      </TabBar>
      {Children.map(children, (child, index) => {
        if (!isTabbedPageElement(child)) return null;

        const { component, lazy = true } = child.props;

        const props: MountProps = { visible: index === activeIndex, component };

        return !lazy ? (
          <EagerMount key={index} {...props} />
        ) : (
          <LazyMount key={index} {...props} />
        );
      })}
    </View>
  );
};
