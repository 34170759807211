import { Image, Pressable, StyleSheet, View } from 'react-native';
import { Colours } from '../../colours';
import { Text } from '../text';
import { nameof } from '../../lib/name-of';
import { RecipeTileTags } from '../recipe-tile-tags/RecipeTileTags';
import { RecipeTileProps } from './RecipeTileProps';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colours.white.$,
    borderRadius: 15,
    flexDirection: 'column'
  },
  image: {
    borderRadius: 10,
    width: 322,
    height: 166,
    margin: 8
  },
  titleAndTags: {
    justifyContent: 'space-between',
    marginHorizontal: 8,
    marginBottom: 10
  },
  title: {
    color: Colours.primaryDark55.$,
    margin: 8,
    flexWrap: 'wrap',
    flex: 1
  },
  tags: {
    marginTop: 8,
    alignSelf: 'flex-end'
  }
});

export const LargeRecipeTile = ({
  recipe,
  style,
  ...rest
}: RecipeTileProps): JSX.Element => {
  return (
    <Pressable
      style={[styles.container, style]}
      {...rest}
      testID={nameof({ LargeRecipeTile })}
    >
      <Image
        source={{
          uri: recipe.mainImageUrl
        }}
        style={[styles.image]}
      />
      <View style={styles.titleAndTags}>
        <Text.Body style={styles.title} accessibilityRole="header">
          {recipe.dishName}
        </Text.Body>
        <RecipeTileTags style={styles.tags} recipe={recipe} />
      </View>
    </Pressable>
  );
};
