import { useNetInfo } from '@react-native-community/netinfo';
import { ReactNode, useMemo } from 'react';
import { ApiOptions, ApiProvider } from '../lib/api-hooked';
import { ConnectivityProvider } from '../lib/api-hooked/ConnectivityProvider';
import { useLaunchArguments } from '../lib/launch-arguments';
import { createLogger } from '../lib/logging';
import { nameof } from '../lib/name-of';
import { MMKVCacheProvider } from './MMKVCacheProvider';

export type SmfApiProviderProps = LaunchArguments;

const netInfoConnectivityProvider: ConnectivityProvider = {
  useIsConnected: useNetInfo
};

export const SmfApiProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const { reset } = useLaunchArguments<LaunchArguments>();

  const options = useMemo<ApiOptions>(
    () => ({
      cacheExpiry: 24 * 60 * 60 * 1000,
      cacheProvider: new MMKVCacheProvider(reset ?? false),
      connectivityProvider: netInfoConnectivityProvider,
      fetchTimeoutMs: 10000,
      fetchRetries: 3,
      logger: createLogger(nameof({ SmfApiProvider }))
    }),
    []
  );

  return <ApiProvider options={options}>{children}</ApiProvider>;
};
