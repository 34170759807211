import { MMKV } from 'react-native-mmkv';
import { createLogger } from '../logging';
import { IdFields, SkipUpdate, Table } from './types';

export const table = <TModel extends Record<string, unknown>>(
  name: string,
  idField: IdFields<TModel>
): Table<TModel, typeof idField> => {
  const log = createLogger(`${name} table`);

  const storage = new MMKV();

  const prefix = `${name ?? 'TABLE_NO_NAME'}/`;

  const keyForId = (id: string) => `${prefix}${id}`;

  const isKey = (maybeKey: string) => maybeKey.includes(prefix);

  const readByKey = (key: string): TModel | null => {
    const data = storage.getString(key);

    if (typeof data === 'undefined') {
      return null;
    }

    return JSON.parse(data) as TModel;
  };

  return {
    read: id => readByKey(keyForId(id as string)),
    readAll: () => {
      return storage
        .getAllKeys()
        .filter(isKey)
        .compactMap(key => {
          const data = storage.getString(key);

          if (typeof data === 'undefined') {
            return null;
          }

          return JSON.parse(data) as TModel;
        });
    },
    update: (id, fn) => {
      const key = keyForId(id as string);
      const existing = readByKey(key);
      const updated = fn(existing);
      if (updated === SkipUpdate) {
        return existing;
      }
      const json = JSON.stringify(updated);
      storage.set(key, json);
      log.debug('Update', { key });
      return updated;
    },
    delete: id => {
      const key = keyForId(id as string);
      storage.delete(key);
      log.debug('Delete', { key });
    },
    deleteAll: () => {
      storage
        .getAllKeys()
        .filter(isKey)
        .forEach(key => storage.delete(key));
      log.debug('Delete all');
    }
  };
};
