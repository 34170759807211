import { Formik } from 'formik';
import { useEffect, useMemo } from 'react';
import { View, ViewProps } from 'react-native';
import { LoginFormValues } from './LoginFormValues';
import { validate } from './validate';
import {
  Normalisers,
  TextField
} from '../../../../../../components/text-field';
import { nameof } from '../../../../../../lib/name-of';
import { useSmfApi } from '../../../../../../api';
import { useDeviceInfo } from '../../../../../../components/device-info-manager';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet,
  useSizeClass
} from '../../../../../../lib/responsive-style-sheet';
import { Colours } from '../../../../../../colours';
import { newRandomUuid } from '../../../../../../lib/base-62';
import { Text } from '../../../../../../components/text';
import { PrimaryButton } from '../../../../../../components/primary-button';

const rStyles = ResponsiveStyleSheet.create({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginVertical: 20
  },
  fields: {
    display: 'flex',
    paddingVertical: 0
  },
  error: {
    paddingHorizontal: 20
  },
  field: {
    marginVertical: 36
  },
  body: {
    textAlign: 'center',
    color: Colours.accentLight75.$,
    marginVertical: 0
  },
  button: {
    marginHorizontal: 77,
    backgroundColor: Colours.primaryLight100.$
  }
})
  .override(
    { medium: true, large: true },
    {
      root: {
        justifyContent: 'space-around'
      },
      fields: {
        justifyContent: 'center'
      },
      field: {
        width: 460,
        alignSelf: 'center'
      },
      button: {
        width: 460,
        alignSelf: 'center'
      }
    }
  )
  .override(
    { large: true },
    {
      root: {
        justifyContent: 'space-evenly'
      }
    }
  );

export type LoginFormProps = Omit<ViewProps, 'children'> & {
  initialUsername?: string;
  onSuccess(emailAddress: string): void;
};

const newCommandId = () => `cmd_${newRandomUuid()}`;

export const LoginForm = ({
  initialUsername,
  onSuccess,
  style,
  ...rest
}: LoginFormProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);
  const size = useSizeClass();

  const device = useDeviceInfo();

  const { args, call, inProgress, result } = useSmfApi(
    'putApiIdentityCommandsSendMagicLinkByCommandId'
  );

  const error = useMemo(() => {
    if (!result || inProgress) {
      return null;
    }

    if (!result.success) {
      if (result.reason === 'OFFLINE_NO_CACHE') {
        return 'You are currently offline. You need an active internet connection to sign in.';
      }

      return "We're having trouble signing you in, please try again.";
    }

    switch (result.data.response.status) {
      case 201:
        return null;
      default:
        return "We're having trouble signing you in, please try again.";
    }
  }, [result, inProgress]);

  useEffect(() => {
    if (
      !args ||
      !result ||
      !result.success ||
      result.data.response.status !== 201
    ) {
      return;
    }

    onSuccess(args.body.emailAddress);
  }, [result]);

  const initialValues: LoginFormValues = {
    emailAddress: initialUsername ?? ''
  };

  return (
    <View style={[style, styles.root]} testID={nameof({ LoginForm })} {...rest}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={({ emailAddress }) =>
          call({
            commandId: newCommandId(),
            body: {
              emailAddress,
              platformType: device.platformType
            }
          })
        }
      >
        {({ handleSubmit, ...formProps }) => (
          <>
            <View style={styles.fields}>
              <Text.Body style={styles.body}>
                Please provide your email address, then we’ll send you a message
                with a ‘magic link’ for password-free entry to Daemon Cooks
              </Text.Body>
              <TextField
                {...formProps}
                autoCapitalize="none"
                autoCorrect={false}
                autoFocus
                clearButtonMode="while-editing"
                editable={!inProgress}
                enablesReturnKeyAutomatically
                name={nameof<LoginFormValues>('emailAddress')}
                normaliser={[Normalisers.trim, Normalisers.lowercase]}
                placeholder="Email address"
                returnKeyType="send"
                style={styles.field}
                onSubmitEditing={() => handleSubmit()}
                errorMessage={error ?? undefined}
              />
            </View>
            {size === 'small' ? null : (
              <PrimaryButton
                style={styles.button}
                title="Send magic link"
                onPress={() => handleSubmit()}
                disabled={inProgress}
              />
            )}
          </>
        )}
      </Formik>
    </View>
  );
};
