import { ComponentType } from 'react';
import { StyleSheet, View } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { Colours } from '../../../../colours';

export type TabBarButtonProps = {
  icon: ComponentType<SvgProps>;
  focussed: boolean;
};

const styles = StyleSheet.create({
  container: {
    width: 60,
    height: 60,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  active: {
    backgroundColor: '#EBFAF2'
  }
});

export const TabBarButton = ({
  icon: Icon,
  focussed
}: TabBarButtonProps): JSX.Element => {
  return (
    <View style={[styles.container, focussed && styles.active]}>
      <Icon fill={focussed ? Colours.primaryDark55.$ : '#B3CAC2'} />
    </View>
  );
};
