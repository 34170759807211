import { View, ViewProps } from 'react-native';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';
import { RecipeTileData } from '../recipe-tile/RecipeTileData';
import { DietSplit } from './DietSplit';
import { PrepAndCookingTime } from './PrepAndCookingTime';
import { PriceGuide } from './PriceGuide';

export type RecipeTileTagsProps = ViewProps & {
  recipe: RecipeTileData;
};

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  tag: {
    marginHorizontal: 4,
    marginVertical: 2
  }
}).override(
  { large: true },
  {
    container: {
      justifyContent: 'flex-end'
    }
  }
);

export const RecipeTileTags = ({
  recipe,
  style,
  ...rest
}: RecipeTileTagsProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <View style={[styles.container, style]} {...rest}>
      <PrepAndCookingTime recipe={recipe} style={styles.tag} />
      <DietSplit recipe={recipe} style={styles.tag} />
      <PriceGuide recipe={recipe} style={styles.tag} />
    </View>
  );
};
