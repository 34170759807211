import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export const Timer = (props: SvgProps) => (
  <Svg {...props} width={16} height={16} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M10 .666H6v1.333h4V.666ZM7.333 9.333h1.334v-4H7.333v4Zm5.354-4.407.946-.947c-.286-.34-.6-.66-.94-.94l-.946.947A5.975 5.975 0 0 0 8 2.666a6 6 0 1 0 6 6 5.975 5.975 0 0 0-1.313-3.74ZM8 13.333a4.663 4.663 0 0 1-4.667-4.667A4.663 4.663 0 0 1 8 3.999a4.663 4.663 0 0 1 4.667 4.667A4.663 4.663 0 0 1 8 13.333Z"
        fill="#A6C1B8"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
