import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export const FamilyFriendly = (props: SvgProps) => (
  <Svg {...props} width={16} height={16} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M10.667 2.667c0-.74.593-1.333 1.333-1.333s1.333.593 1.333 1.333S12.74 4.001 12 4.001s-1.333-.594-1.333-1.334Zm2.666 12v-4H15l-1.693-5.086a1.34 1.34 0 0 0-1.267-.914h-.08c-.573 0-1.087.367-1.267.914L10.12 7.3c.72.4 1.213 1.153 1.213 2.033v5.333h2Zm-5-7c.554 0 1-.446 1-1 0-.553-.446-1-1-1-.553 0-1 .447-1 1 0 .554.447 1 1 1ZM3.667 4.001C4.407 4 5 3.407 5 2.667c0-.74-.593-1.333-1.333-1.333s-1.334.593-1.334 1.333.594 1.334 1.334 1.334ZM5 14.667v-4.666h1V6c0-.734-.6-1.334-1.333-1.334h-2c-.734 0-1.334.6-1.334 1.334v4h1v4.666H5Zm4.333 0v-2.666H10V9.334c0-.547-.453-1-1-1H7.667c-.547 0-1 .453-1 1v2.667h.666v2.666h2Z"
        fill="#A6C1B8"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
