import Svg, { SvgProps, Path } from 'react-native-svg';

export const CloseIcon = (props: SvgProps) => (
  <Svg {...props} width={24} height={24} fill="none">
    <Path
      d="m16.948 7.052-9.896 9.896M7.052 7.052l9.896 9.896"
      stroke="#004E34"
      strokeWidth={2.375}
    />
  </Svg>
);
