import { createLogger } from '../logging';
import { nameof } from '../name-of';

export const retryPromise = <T>(
  fn: () => Promise<T>,
  {
    maxAttempts = 3,
    signal
  }: {
    maxAttempts?: number;
    signal?: AbortSignal;
  }
): Promise<T> => {
  const log = createLogger(nameof({ retryPromise }));

  return new Promise((resolve, reject) => {
    void (async () => {
      let attempt = 0;
      let error: unknown;
      while (attempt < maxAttempts) {
        try {
          attempt += 1;
          // eslint-disable-next-line no-await-in-loop
          const result = await fn();
          resolve(result);
          return;
        } catch (e: unknown) {
          if (signal?.aborted) {
            log.debug('Aborted');
            reject(e);
            return;
          }

          log.info('Retrying', { attempt, maxAttempts, error: e });
          error = e;
        }
      }
      reject(error);
    })();
  });
};
