import { useMemo } from 'react';
import { boolValue, mapValue, keyValueStore } from '../../lib/key-value-store';
import { UserSession } from './UserSession';
import { UserSubscription } from './UserSubscription';

const GlobalSettings = {
  tourComplete: boolValue,
  userSession: mapValue<UserSession | null>(null),
  userSubscription: mapValue<UserSubscription | null>(null)
};

export const useGlobalSettings = () => {
  const store = useMemo(
    () => keyValueStore(GlobalSettings, 'global-store'),
    []
  );

  return store;
};
