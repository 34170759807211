import { RecipeIngredientComponent } from '@showme-fit/smf-app-gateway-client';
import { StyleSheet, Text, View } from 'react-native';
import { Colours } from '../../../../colours';
import { Fonts } from '../../../../fonts';
import { useRecipeContext } from '../context';
import { fractionify } from '../../../../humanisers/fractionify/fractionify';

const mapIngredient = (i: RecipeIngredientComponent): string => {
  if (i.displayOverride) {
    return i.displayOverride;
  }

  return [
    i.quantity ? fractionify(i.quantity) : i.quantity,
    i.product?.data?.attributes?.unit,
    i.product?.data?.attributes?.name,
    i.preparationNote ? `(${i.preparationNote})` : null
  ]
    .compact()
    .join(' ');
};

const styles = StyleSheet.create({
  item: {
    color: Colours.accentLight100.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 14,
    lineHeight: 25
  }
});

export const Ingredients = (): JSX.Element => {
  const { recipe } = useRecipeContext();

  return (
    <View>
      {recipe?.attributes?.ingredients?.map(i => (
        <Text key={i.id} style={styles.item}>
          {mapIngredient(i)}
        </Text>
      ))}
    </View>
  );
};
