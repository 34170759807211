import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { useCallback, useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Colours } from '../../../../colours';
import { SearchBar } from '../../../../components/search-bar';
import { Fonts } from '../../../../fonts';
import { useDebounce } from '../../../../lib/use-debounce';
import { PlannerNavigatorParamList } from '../../PlannerNavigatorParamList';
import { CloseButton } from './CloseButton';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F2FDF9',
    paddingTop: 22,
    paddingBottom: 12,
    paddingHorizontal: 16,
    flexDirection: 'column',
    alignItems: 'center'
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    left: 16
  },
  title: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 16,
    color: Colours.accentLight100.$
  },
  searchBar: {
    marginTop: 20,
    alignSelf: 'stretch'
  }
});

export type AddRecipeScreenHeaderProps = {
  onFocussedChanged?: (focussed: boolean) => void;
};

export const AddRecipeScreenHeader = ({
  onFocussedChanged
}: AddRecipeScreenHeaderProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');

  const route = useRoute<RouteProp<PlannerNavigatorParamList, 'addRecipe'>>();

  useEffect(() => {
    setSearchValue(route.params.search ?? '');
  }, []);

  const debouncedSearchValue = useDebounce(searchValue, 250);

  const navigation =
    useNavigation<NavigationProp<PlannerNavigatorParamList, 'addRecipe'>>();

  useEffect(() => {
    navigation.setParams({ ...route.params, search: debouncedSearchValue });
  }, [debouncedSearchValue]);

  const onClear = useCallback(() => {
    setSearchValue('');
    navigation.setParams({ ...route.params, search: '' });
  }, [navigation, setSearchValue]);

  return (
    <View style={styles.container}>
      <Text style={styles.title} accessibilityRole="header">
        Add recipe to Plan
      </Text>
      <SearchBar
        style={styles.searchBar}
        placeholder="Search recipes"
        onChangeText={setSearchValue}
        onFocussedChanged={onFocussedChanged}
        value={searchValue}
        onClear={onClear}
      />
      <CloseButton
        style={styles.closeButton}
        onPress={() => navigation.goBack()}
      />
    </View>
  );
};
