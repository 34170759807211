import { useContext } from 'react';
import { ActivityContext } from './ActivityContext';

export const useActivity = () => {
  const hookValue = useContext(ActivityContext);
  if (!hookValue) {
    throw new Error(
      'useActivity() hook requires a ActivityContext within the component hierarchy.'
    );
  }
  return hookValue;
};
