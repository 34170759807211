import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import { useCallback } from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colours } from '../../../../colours';
import { Text } from '../../../../components/text';
import { nameof } from '../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { Carousel } from '../../components';
import { WelcomeNavigatorParamsList } from '../../WelcomeNavigatorParamsList';
import { LoginForm } from './components';

export type SignInScreenProps = StackScreenProps<
  WelcomeNavigatorParamsList,
  'signIn'
>;

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    backgroundColor: Colours.primaryLight35.$,
    paddingVertical: 24
  },
  content: {
    flex: 1,
    alignItems: 'stretch',
    padding: 26
  },
  title: {
    textAlign: 'center',
    color: Colours.accentLight100.$,
    marginTop: 40
  },
  form: {
    flexGrow: 1
  }
})
  .override(
    { medium: true, large: true },
    {
      container: {
        paddingVertical: 50
      },
      carousel: {
        alignSelf: 'center',
        marginTop: 50
      }
    }
  )
  .override(
    { small: true },
    {
      carousel: {
        display: 'none'
      }
    }
  );

export const SignInScreen = ({
  navigation
}: SignInScreenProps): JSX.Element => {
  const onNext = useCallback(
    (emailAddress: string) => navigation.push('emailSent', { emailAddress }),
    [navigation]
  );
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <View style={styles.container} testID={nameof({ SignInScreen })}>
      <StatusBar style="dark" />
      <SafeAreaView style={styles.content}>
        <Text.Title style={styles.title}>Sign in</Text.Title>
        <LoginForm style={styles.form} onSuccess={onNext} />
        <Carousel style={styles.carousel} />
      </SafeAreaView>
    </View>
  );
};
