const FRACTIONS: { [key: number]: string } = {
  0.125: '⅛',
  0.14: '⅐',
  0.25: '¼',
  0.333: '⅓',
  0.375: '⅜',
  0.5: '½',
  0.625: '⅝',
  0.666: '⅔',
  0.75: '¾',
  0.875: '⅞'
};

export const fractionify = (quantity: number): string => {
  const decimal = quantity - Math.floor(quantity);

  const integer = quantity - decimal;

  const fraction = FRACTIONS[decimal];

  if (!fraction) {
    return `${quantity}`;
  }

  if (integer === 0) {
    return fraction;
  }

  return `${integer}${fraction}`;
};
