import { Image, Pressable, StyleSheet, View } from 'react-native';
import { Colours } from '../../colours';
import { Text } from '../text';
import { nameof } from '../../lib/name-of';
import { RecipeTileTags } from '../recipe-tile-tags/RecipeTileTags';
import { RecipeTileProps } from './RecipeTileProps';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colours.white.$,
    borderRadius: 15,
    padding: 8
  },
  imageAndTitleContainer: {
    flexDirection: 'row'
  },
  image: {
    borderRadius: 10,
    width: 82,
    height: 82,
    margin: 4
  },
  title: {
    color: Colours.primaryDark55.$,
    margin: 8,
    marginVertical: 19,
    flexWrap: 'wrap',
    flex: 1
  },
  tags: {
    margin: 6
  }
});

export const SmallRecipeTile = ({
  recipe,
  style,
  ...rest
}: RecipeTileProps): JSX.Element => {
  return (
    <Pressable
      style={[styles.container, style]}
      {...rest}
      testID={nameof({ SmallRecipeTile })}
    >
      <View style={styles.imageAndTitleContainer}>
        <Image
          source={{
            uri: recipe.mainImageUrl
          }}
          style={[styles.image]}
        />
        <Text.Body style={styles.title} accessibilityRole="header">
          {recipe.dishName}
        </Text.Body>
      </View>
      <RecipeTileTags style={styles.tags} recipe={recipe} />
    </Pressable>
  );
};
