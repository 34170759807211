import { RecipeListResponseDataItem } from '@showme-fit/smf-app-gateway-client';
import { TagProps } from '../../../tag';

export const mapEquipment = (
  recipe: RecipeListResponseDataItem
): TagProps[] => {
  return (
    recipe.attributes?.equipment?.data
      ?.compactMap(e => e.attributes?.name)
      .map(name => ({
        values: [name],
        colour: 'Grey'
      })) ?? []
  );
};
