import { TabbedPage, TabbedView } from '../../../tabs';
import { Ingredients } from '../ingredients';
import { Method } from '../method';

export const Body = (): JSX.Element => (
  <TabbedView>
    <TabbedPage title="Ingredients" lazy={false} component={Ingredients} />
    <TabbedPage title="Method" lazy={false} component={Method} />
  </TabbedView>
);
