import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import { Pressable, ScrollView, Text, View } from 'react-native';
import { Colours } from '../../../../colours';
import { WebLink } from '../../../../components/web-link/WebLink';
import { Fonts } from '../../../../fonts';
import { nameof } from '../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { MoreInfoNavigatorParamList } from '../../MoreInfoNavigatorParamList';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colours.white.$
  },
  content: {
    alignItems: 'stretch',
    padding: 8
  },
  main: {
    borderRadius: 20,
    padding: 22,
    backgroundColor: Colours.primaryLight5.$,
    alignItems: 'flex-start'
  },
  title: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 16,
    color: Colours.primaryDark55.$,
    letterSpacing: -0.2,
    marginVertical: 10
  },
  link: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 16,
    color: Colours.accentLight55.$,
    letterSpacing: -0.2,
    marginVertical: 10
  }
}).override(
  { large: true, medium: true },
  {
    content: {
      padding: 28
    },
    main: {
      padding: 32,
      flexDirection: 'row'
    },
    section: {
      margin: 32,
      flexBasis: 1,
      flexGrow: 1
    }
  }
);

export type IndexScreenProps = StackScreenProps<
  MoreInfoNavigatorParamList,
  'index'
>;

export const IndexScreen = ({ navigation }: IndexScreenProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      testID={nameof({ IndexScreen })}
    >
      <StatusBar style="dark" />
      <View style={styles.main}>
        <View style={styles.section}>
          <Text style={styles.title}>Legal information</Text>
          <WebLink
            textStyle={styles.link}
            text="Terms and Conditions"
            href="https://dev.daemon-cooks.com/terms-conditions.html"
          />
          <WebLink
            textStyle={styles.link}
            text="Privacy Policy"
            href="https://dev.daemon-cooks.com/privacy.html"
          />
          <WebLink
            textStyle={styles.link}
            text="End user license agreement"
            href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.title}>Profile</Text>
          <Pressable onPress={() => navigation.navigate('delete-account')}>
            <Text style={styles.link}>Delete Account</Text>
          </Pressable>
        </View>
        <View style={styles.section}>
          <Text style={styles.title}>Support & help</Text>
          <WebLink
            textStyle={styles.link}
            text="Contact us"
            href="mailto:cookes@dae.mn"
          />
        </View>
      </View>
    </ScrollView>
  );
};
