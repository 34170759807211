import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import { Platform, View } from 'react-native';
import {
  SafeAreaView,
  useSafeAreaInsets
} from 'react-native-safe-area-context';
import { openInbox } from 'react-native-email-link';
import { Colours } from '../../../../colours';
import { Fonts } from '../../../../fonts';
import { nameof } from '../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { WelcomeNavigatorParamsList } from '../../WelcomeNavigatorParamsList';
import { MailboxIcon } from './MailBoxIcon';
import { Text } from '../../../../components/text';
import { PrimaryButton } from '../../../../components/primary-button';

export type EmailSentScreenProps = StackScreenProps<
  WelcomeNavigatorParamsList,
  'emailSent'
>;

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    backgroundColor: Colours.primaryLight35.$,
    paddingVertical: 24,
    paddingHorizontal: 45
  },
  content: {
    flex: 1,
    alignItems: 'center'
  },
  title: {
    textAlign: 'center',
    color: Colours.accentLight100.$
  },
  body: {
    textAlign: 'center'
  },
  space: {
    marginVertical: 20,
    alignItems: 'center'
  },
  strong: {
    fontFamily: Fonts.DMSans_700Bold,
    marginTop: 0,
    marginBottom: 20
  },
  form: {
    flexGrow: 1
  }
})
  .override(
    { medium: true, large: true },
    {
      container: {
        paddingVertical: 98
      }
    }
  )
  .override(
    { small: true },
    {
      content: {
        marginTop: 60
      },
      button: { marginTop: '25%' }
    }
  );

export const EmailSentScreen = ({
  route: { params }
}: EmailSentScreenProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const { bottom } = useSafeAreaInsets();

  return (
    <View style={styles.container} testID={nameof({ EmailSentScreen })}>
      <StatusBar style="dark" />
      <SafeAreaView style={styles.content}>
        <Text.Title style={[styles.title, styles.space]}>
          Check your inbox
        </Text.Title>
        <MailboxIcon style={styles.space} />
        <View style={styles.space}>
          <Text.Body>We've sent an email to</Text.Body>
          <Text.Body style={styles.strong}>
            {params?.emailAddress ?? 'your email address'}
          </Text.Body>
        </View>

        <Text.Body style={[styles.body, styles.space]}>
          Click the ‘magic link’ in the email for password-free entry to
          Daemon Cooks
        </Text.Body>
        {Platform.OS !== 'web' && (
          <PrimaryButton
            style={[styles.button, { bottom }]}
            title="Open mail app"
            onPress={() => {
              void openInbox().catch(() => {});
            }}
          />
        )}
      </SafeAreaView>
    </View>
  );
};
