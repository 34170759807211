import Svg, { SvgProps } from 'react-native-svg';

export const MainIconDark = (props: SvgProps) => (
  <Svg
    {...props}
    width={158}
    height={50}
    viewBox="0 0 100 32"
    fill="none"
  ></Svg>
);
