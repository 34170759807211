import { ReactNode, useEffect, useState } from 'react';
import { useLaunchArguments } from '../../lib/launch-arguments';
import { createLogger } from '../../lib/logging';
import { nameof } from '../../lib/name-of';
import { RetainSplash } from '../../lib/retain-splash';
import { useGlobalSettings } from '../global-settings';
import { UserSession } from '../global-settings/UserSession';
import { UserSubscription } from '../global-settings/UserSubscription';

export const UserLaunchArgumentsHandler = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const log = createLogger(nameof({ UserLaunchArgumentsHandler }));

  const [init, setInit] = useState(true);

  const launchArgs = useLaunchArguments<LaunchArguments>();

  const settings = useGlobalSettings();

  // startUp
  useEffect(() => {
    if (!init) {
      return;
    }

    setInit(false);

    if (launchArgs.userSessionJson) {
      const session = JSON.parse(launchArgs.userSessionJson) as UserSession;

      log.info('Using user session from launch arguments', { session });
      settings.set('userSession', session);
    }

    if (launchArgs.userSubscriptionJson) {
      const subscription = JSON.parse(
        launchArgs.userSubscriptionJson
      ) as UserSubscription;

      log.info('Using user subscription from launch arguments', {
        subscription
      });
      settings.set('userSubscription', subscription);
    }
  }, []);

  if (init) {
    return <RetainSplash />;
  }

  return <>{children}</>;
};
