import { ComponentType } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { Colours } from '../../../../colours';

export type IconBubbleProps = Omit<ViewProps, 'children'> & {
  iconComponent: ComponentType<SvgProps>;
};

const styles = StyleSheet.create({
  bubble: {
    backgroundColor: Colours.primaryLight35.$,
    borderRadius: 10,
    padding: 6
  }
});

export const IconBubble = ({
  iconComponent: Icon,
  style,
  ...rest
}: IconBubbleProps): JSX.Element => (
  <View style={[styles.bubble, style]} {...rest}>
    <Icon />
  </View>
);
