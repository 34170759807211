import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export const Freezer = (props: SvgProps) => (
  <Svg {...props} width={16} height={16} fill="none">
    <G clipPath="url(#a)">
      <Path
        d="M14.667 7.334h-2.78l2.16-2.16-.94-.947L10 7.334H8.667V6.001l3.106-3.107-.946-.94-2.16 2.16v-2.78H7.333v2.78l-2.16-2.16-.946.94 3.106 3.107v1.333H6L2.893 4.227l-.94.947 2.16 2.16h-2.78v1.333h2.78l-2.16 2.16.94.947L6 8.667h1.333v1.334l-3.106 3.106.946.94 2.16-2.16v2.78h1.334v-2.78l2.16 2.16.946-.94-3.106-3.106V8.667H10l3.107 3.107.94-.947-2.16-2.16h2.78V7.334Z"
        fill="#A6C1B8"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
