import { ComponentType } from 'react';
import { Pressable, Text, TouchableHighlightProps } from 'react-native';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import { nameof } from '../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';

const styles = ResponsiveStyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colours.white.$,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 10
  },
  title: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 14,
    color: Colours.accentLight100.$,
    lineHeight: 16
  }
}).override(
  { medium: true, large: true },
  {
    container: {
      paddingVertical: 12,
      paddingHorizontal: 32,
      borderRadius: 15
    },
    title: {
      fontSize: 16,
      lineHeight: 24
    }
  }
);

export type MiniButtonProps = TouchableHighlightProps &
  (
    | {
        title: string;
      }
    | { component: ComponentType }
  );

export const MiniButton = ({
  style: styleProp,
  ...props
}: MiniButtonProps): JSX.Element => {
  const style = useResponsiveStyleSheet(styles);

  return (
    <Pressable
      testID={nameof({ BackButton: MiniButton })}
      accessibilityRole="button"
      style={[style.container, styleProp]}
      {...props}
    >
      {'title' in props ? (
        <Text style={style.title}>{props.title}</Text>
      ) : (
        <props.component />
      )}
    </Pressable>
  );
};
