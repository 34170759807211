import { CacheProvider, CacheResult } from '../lib/api-hooked';
import { createLogger } from '../lib/logging';
import { nameof } from '../lib/name-of';
import { table } from '../lib/table-store';

type CacheItem = {
  key: string;
  data: unknown;
  cachedAt: number;
  expiresAt: number;
  userId: string | null;
};

export class MMKVCacheProvider implements CacheProvider {
  private readonly cacheTable = table<CacheItem>('api-cache', 'key');

  private readonly log = createLogger(nameof({ MMKVCacheProvider }));

  constructor(reset: boolean) {
    this.log.debug('Running cache evictions', { reset });

    const now = new Date().getTime();

    const evictionCount = this.cacheTable.readAll().sum(value => {
      if (reset || value.expiresAt > now) {
        this.cacheTable.delete(value.key);
        return 1;
      }

      return 0;
    });

    this.log.info('Eviction complete', { evictionCount });
  }

  read<T>(key: string) {
    const item = this.cacheTable.read(key);

    if (!item) return null;

    if (item.expiresAt < new Date().getTime()) {
      this.cacheTable.delete(key);

      return null;
    }

    return item as CacheResult<T>;
  }

  write(key: string, userId: string, data: unknown, expiresAt: number) {
    this.cacheTable.update(key, () => ({
      key,
      data,
      userId,
      expiresAt,
      cachedAt: new Date().getTime()
    }));
  }
}
