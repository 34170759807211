import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

export const ShoppingListIcon = ({ fill, ...rest }: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...rest}>
    <G clipPath="url(#a)">
      <Path
        d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49A.996.996 0 0 0 20.01 4H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45ZM6.16 6h12.15l-2.76 5H8.53L6.16 6ZM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2Zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2Z"
        fill={fill}
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
