import { StyleSheet, Text, View } from 'react-native';
import { Colours } from '../../../../colours';
import { Fonts } from '../../../../fonts';
import { useRecipeContext } from '../context';
import { Video } from '../video';

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: 'row',
    marginVertical: 8,
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  quantityColumn: {
    width: 32
  },
  quantityBubble: {
    backgroundColor: Colours.primaryLight35.$,
    paddingVertical: 4,
    paddingHorizontal: 8,
    alignSelf: 'flex-start',
    borderRadius: 5
  },
  quantity: {
    color: Colours.accentLight100.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 11
  },
  textColumn: {
    flexShrink: 1
  },
  text: {
    color: Colours.accentLight100.$,
    fontFamily: Fonts.DMSans_500Medium,
    fontSize: 14,
    lineHeight: 21,
    flex: 1,
    flexWrap: 'wrap'
  }
});

export const Method = (): JSX.Element => {
  const { recipe } = useRecipeContext();

  return (
    <View style={[styles.container]}>
      {recipe?.attributes?.methodLine?.map((i, index) => (
        <View key={i.id} style={styles.row}>
          <View style={styles.quantityColumn}>
            <View style={styles.quantityBubble}>
              <Text style={styles.quantity}>{index + 1}</Text>
            </View>
          </View>
          <View style={styles.textColumn}>
            <Text style={styles.text}>{i.body}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};
