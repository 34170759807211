import { Pressable, StyleSheet, View, ViewProps } from 'react-native';
import { Colours } from '../../colours';
import { CheckedIcon } from './CheckedIcon';

export type CheckBoxProps = Omit<ViewProps, 'children'> & {
  checked: boolean | null;
  onCheckChanged?: (checked: boolean) => void;
};

const styles = StyleSheet.create({
  container: {
    width: 25,
    height: 25,
    borderColor: Colours.primaryLight100.$,
    borderWidth: 2.5,
    borderRadius: 6
  },
  checked: {
    backgroundColor: Colours.primaryLight100.$,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: Colours.black.$,
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 9.4 },
    shadowRadius: 5,
    elevation: 1
  }
});

export const CheckBox = ({
  style,
  checked,
  onCheckChanged,
  ...rest
}: CheckBoxProps): JSX.Element => {
  const Element = onCheckChanged ? Pressable : View;

  return (
    <Element
      onPress={() => onCheckChanged?.(!checked)}
      style={[styles.container, checked && styles.checked, style]}
      {...rest}
    >
      {checked && <CheckedIcon />}
    </Element>
  );
};
