import { ApiOptions } from './ApiOptions';
import { inMemoryCacheProvider } from './inMemoryCacheProvider';
import { NO_OP } from './constants';

export const defaultOptions: () => Required<ApiOptions> = () => ({
  authorizer: {
    getToken: () => null,
    getUserId: () => null,
    notifyAuthFailed: NO_OP
  },
  cacheExpiry: 60 * 60 * 1000,
  cacheProvider: inMemoryCacheProvider(),
  cacheStrategy: 'NO_CACHE',
  connectivityProvider: { useIsConnected: () => ({ isConnected: true }) },
  fetchRetries: 3,
  fetchTimeoutMs: 10000,
  logger: console
});
