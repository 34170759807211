import { StackScreenProps } from '@react-navigation/stack';
import { useCallback } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Image } from 'react-native';
import { Colours } from '../../../../colours';
import { PrimaryButton } from '../../../../components/primary-button';
import { Text } from '../../../../components/text';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { Carousel } from '../../components';
import { WelcomeNavigatorParamsList } from '../../WelcomeNavigatorParamsList';
import imageSource from './image.png';
import { MainIcon } from '../../../../components/icons';

export type ReadyScreenProps = StackScreenProps<
  WelcomeNavigatorParamsList,
  'ready'
>;

const styles = ResponsiveStyleSheet.create({
  logo: {
    width: 279,
    height: 89
  },
  container: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingVertical: 24,
    backgroundColor: Colours.primaryDark75.$
  },
  text: {
    color: Colours.accentLight5.$,
    textAlign: 'center',
    marginHorizontal: 50
  },
  daemon: { height: 65, width: 135 }
})
  .override(
    { small: true },
    {
      logo: { marginTop: 20 },
      button: {
        alignSelf: 'stretch',
        marginHorizontal: 78
      },
      carousel: {
        alignSelf: 'center',
        marginBottom: 50,
        marginTop: 20
      }
    }
  )
  .override(
    { medium: true, large: true },
    {
      button: {
        width: 460
      },
      text: {
        maxWidth: 288
      },
      carousel: {
        alignSelf: 'center',
        marginBottom: 0,
        marginTop: 20
      },
      daemon: { width: 200 }
    }
  );

export const ReadyScreen = ({ navigation }: ReadyScreenProps): JSX.Element => {
  const onGetStarted = useCallback(
    () => navigation.push('headlines'),
    [navigation]
  );
  const style = useResponsiveStyleSheet(styles);

  return (
    <SafeAreaView style={style.container}>
      <MainIcon style={style.logo} />
      <Carousel style={style.carousel} />
      <Text.Title style={style.text}>Ready for a healthier you?</Text.Title>
      <PrimaryButton
        title="Get Started!"
        onPress={onGetStarted}
        style={style.button}
      />
      <Image source={imageSource} style={style.daemon} />
    </SafeAreaView>
  );
};
