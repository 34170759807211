import 'ts-array-extensions';
import { SafeAreaProviderCompat } from '@react-navigation/elements';
import { ReactNode } from 'react';
import { ActivityManager } from './lib/activity-context';
import { LaunchArgumentsContextProvider } from './lib/launch-arguments';
import { SharedStateScope } from './lib/use-shared-state';
import { SmfApiProvider } from './api';
import { RetainSplashContextProvider } from './lib/retain-splash';
import { FontsContainer } from './components/fonts-container';
import { ConfigManager } from './components/config';
import { DeviceInfoManager } from './components/device-info-manager';
import { UserManager } from './components/user-manager';
import { PubSubBroker } from './lib/pub-sub/PubSubBroker';

export type AppContextProps = {
  children: ReactNode;
  launchArguments: LaunchArguments;
};

export const AppContext = ({
  children,
  launchArguments
}: AppContextProps): JSX.Element => (
  <RetainSplashContextProvider>
    <LaunchArgumentsContextProvider value={launchArguments}>
      <PubSubBroker>
        <SharedStateScope>
          <FontsContainer>
            <SafeAreaProviderCompat>
              <ActivityManager>
                <ConfigManager>
                  <DeviceInfoManager>
                    <UserManager>
                      <SmfApiProvider>{children}</SmfApiProvider>
                    </UserManager>
                  </DeviceInfoManager>
                </ConfigManager>
              </ActivityManager>
            </SafeAreaProviderCompat>
          </FontsContainer>
        </SharedStateScope>
      </PubSubBroker>
    </LaunchArgumentsContextProvider>
  </RetainSplashContextProvider>
);
