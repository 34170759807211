import { useEffect } from 'react';
import { Platform } from 'react-native';
import { createLogger } from '../logging';
import { nameof } from '../name-of';

export const usePurchaseUpdate = () => {
  const log = createLogger(nameof({ usePurchaseUpdate }));

  useEffect(() => {
    log.debug('Not checking for in app purchase updates', {
      platform: Platform.OS
    });
  }, []);
};
