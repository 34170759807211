import { HeaderBackButtonProps } from '@react-navigation/elements';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { MiniButton } from '../../../../components/mini-button';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    marginLeft: 24
  }
}).override(
  { medium: true, large: true },
  {
    container: {
      marginLeft: 45
    }
  }
);

export const HeaderBackButton = (props: HeaderBackButtonProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  if (!props.canGoBack) return <></>;

  return <MiniButton title="Back" {...props} style={styles.container} />;
};
