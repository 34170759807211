import { AccessibilityRole, Text as Orig, TextProps } from 'react-native';
import { ArrayElement } from 'ts-array-extensions';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';

const rStyles = ResponsiveStyleSheet.create({
  body: {
    fontFamily: Fonts.DMSans_500Medium,
    fontSize: 16,
    color: Colours.accentLight75.$,
    marginVertical: 6,
    lineHeight: 24
  },
  title: {
    fontFamily: Fonts.DMSans_500Medium,
    fontSize: 32,
    color: Colours.accentLight100.$,
    lineHeight: 36,
    marginVertical: 6
  },
  hint: {
    fontFamily: Fonts.DMSans_500Medium,
    fontSize: 12,
    color: Colours.accentLight55.$,
    marginVertical: 4,
    lineHeight: 24
  }
}).override(
  { medium: true },
  {
    title: {
      fontSize: 40,
      lineHeight: 44
    }
  }
);

const makeComponent = (
  styleKey: keyof ArrayElement<typeof rStyles['styles']>['styles'],
  accessibilityRole: AccessibilityRole
): React.FC<TextProps> => {
  const id = `Text.${styleKey}`;
  const Component = ({ style, ...props }: TextProps): JSX.Element => {
    const styles = useResponsiveStyleSheet(rStyles);

    return (
      <Orig
        style={[styles[styleKey], style]}
        accessibilityRole={accessibilityRole}
        {...props}
      />
    );
  };
  Component.displayName = id;
  return Component;
};

export const Text = {
  Body: makeComponent('body', 'text'),
  Hint: makeComponent('hint', 'text'),
  Title: makeComponent('title', 'header')
};
