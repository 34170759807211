import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { RootNavigatorParamList } from './RootNavigatorParamList';
import { WelcomeNavigator } from '../welcome/WelcomeNavigator';
import { MainNavigator } from '../main/MainNavigator';
import { useSession, useSubscription } from '../../components/user-manager';

const Stack = createNativeStackNavigator<RootNavigatorParamList>();

export const RootNavigator = (): JSX.Element => {
  const { currentSession } = useSession();

  const { subscriptionType } = useSubscription();

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      {!currentSession || !subscriptionType || subscriptionType === 'NONE' ? (
        <Stack.Screen name="welcome" component={WelcomeNavigator} />
      ) : (
        <Stack.Screen name="main" component={MainNavigator} />
      )}
    </Stack.Navigator>
  );
};
