import { StyleProp, Text, View, ViewStyle } from 'react-native';
import { Colours } from '../../../../colours';
import { Fonts } from '../../../../fonts';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet,
  useSizeClass
} from '../../../../lib/responsive-style-sheet';
import { LinkPressable } from './LinkPressable';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginVertical: 4,
    borderRadius: 10
  },
  containerActive: {
    backgroundColor: '#EBFAF2'
  },
  wrapper: {
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 18
  },
  label: {
    marginRight: 32
  },
  labelText: {
    color: Colours.accentLight100.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 14,
    letterSpacing: -0.25
  }
});

type Props = {
  label:
    | string
    | ((props: { focused: boolean; color: string }) => React.ReactNode);
  icon?: (props: {
    focused: boolean;
    size: number;
    color: string;
  }) => React.ReactNode;
  to?: string;
  focused?: boolean;
  onPress: () => void;
  activeTintColor?: string;
  inactiveTintColor?: string;
  activeBackgroundColor?: string;
  inactiveBackgroundColor?: string;
  pressColor?: string;
  pressOpacity?: number;
  style?: StyleProp<ViewStyle>;
  allowFontScaling?: boolean;
};

/**
 * A component used to show an action item with an icon and a label in a navigation drawer.
 */
export default function DrawerItem(props: Props) {
  const styles = useResponsiveStyleSheet(rStyles);

  const sizeClass = useSizeClass();

  const {
    icon,
    label,
    to,
    focused = false,
    allowFontScaling,
    style,
    onPress,
    pressColor,
    pressOpacity,
    ...rest
  } = props;

  const color = focused ? Colours.primaryDark55.$ : '#B3CAC2';

  const iconNode = icon ? icon({ size: 24, focused, color }) : null;

  return (
    <View
      collapsable={false}
      {...rest}
      style={[styles.container, focused && styles.containerActive, style]}
    >
      <LinkPressable
        onPress={onPress}
        style={styles.wrapper}
        accessibilityRole="button"
        accessibilityState={{ selected: focused }}
        pressColor={pressColor}
        pressOpacity={pressOpacity}
        to={to}
      >
        <>
          {iconNode}
          {sizeClass === 'large' && (
            <View
              style={[
                styles.label,
                { marginLeft: iconNode ? 32 : 0, marginVertical: 5 }
              ]}
            >
              {typeof label === 'string' ? (
                <Text
                  numberOfLines={1}
                  allowFontScaling={allowFontScaling}
                  style={[
                    {
                      color
                    },
                    styles.labelText
                  ]}
                >
                  {label}
                </Text>
              ) : (
                label({ color, focused })
              )}
            </View>
          )}
        </>
      </LinkPressable>
    </View>
  );
}
