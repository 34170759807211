import { useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { Colours } from '../../../../colours';
import { ClearListIcon } from './ClearListIcon';
import { ConfirmClearModal } from './ConfirmClearModal';

const styles = StyleSheet.create({
  button: {
    backgroundColor: Colours.primaryLight35.$,
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginRight: 24
  }
});

export const ClearButton = (): JSX.Element => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Pressable style={styles.button} onPress={() => setOpened(true)}>
        <ClearListIcon />
      </Pressable>
      <ConfirmClearModal opened={opened} onClose={() => setOpened(false)} />
    </>
  );
};
