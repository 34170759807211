import { Text, TextProps } from 'react-native';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';

const rStyles = ResponsiveStyleSheet.create({
  root: {
    color: Colours.errorDark.$,
    fontSize: 12,
    fontFamily: Fonts.DMSans_700Bold
  }
}).override(
  { medium: true, large: true },
  {
    root: {
      fontSize: 14
    }
  }
);

export const Error = ({ style, ...rest }: TextProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return <Text testID="Error" style={[styles.root, style]} {...rest} />;
};
