import { createLogger } from './createLogger';
import { Logger } from './Logger';

export const createChildLogger = (
  logger: Logger,
  childComponent: string,
  additionalFields?: Record<string, unknown>
): Logger => {
  return createLogger(childComponent, {
    ...logger.staticFields,
    ...additionalFields
  });
};
