import { ComponentType } from 'react';
import { Text, View, ViewProps } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { Colours } from '../../colours';
import { Fonts } from '../../fonts';
import { nameof } from '../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../lib/responsive-style-sheet';

export type TagProps = ViewProps & {
  values?: string[];
  iconComponent?: ComponentType<SvgProps>;
  iconText?: string;
  colour?: 'Green' | 'Grey';
};

const rStyles = ResponsiveStyleSheet.create({
  container: {
    alignItems: 'center',
    borderRadius: 10,
    flexDirection: 'row',
    paddingLeft: 6,
    paddingRight: 10,
    paddingVertical: 4
  },
  green: {
    backgroundColor: Colours.primaryLight5.$
  },
  grey: {
    backgroundColor: '#E7ECEA'
  },
  text: {
    color: Colours.accentLight75.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 12,
    lineHeight: 18,
    marginLeft: 4
  },
  icon: {
    marginRight: 4
  },
  iconText: {
    color: Colours.accentLight35.$,
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 10,
    lineHeight: 18,
    marginRight: 4,
    paddingLeft: 2,
    paddingRight: 2
  }
});

export const Tag = ({
  style,
  values,
  iconComponent: Icon,
  iconText,
  colour = 'Green',
  children,
  ...rest
}: TagProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  return (
    <View
      testID={nameof({ Tag })}
      style={[
        styles.container,
        colour === 'Green' ? styles.green : styles.grey,
        style
      ]}
      {...rest}
    >
      {Icon && <Icon style={styles.icon} />}
      {iconText && <Text style={styles.iconText}>{iconText}</Text>}
      {values && <Text style={styles.text}>{values.join(' | ')}</Text>}
      {children}
    </View>
  );
};
