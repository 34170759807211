import { createContext, ReactNode, useMemo } from 'react';
import { Platform } from 'react-native';
import {
  getBuildNumber,
  getUniqueId,
  getManufacturer,
  getDeviceName,
  getDeviceId,
  getSystemVersion,
  getVersion,
  isEmulator
} from 'react-native-device-info';
import { RetainSplash } from '../../lib/retain-splash';
import { usePromise } from '../../lib/use-promise';
import { DeviceData } from './DeviceData';

type DeviceInfoManagerContextValue = DeviceData;

export const DeviceInfoManagerContext =
  createContext<DeviceInfoManagerContextValue | null>(null);

export const DeviceInfoManager = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const manufacturer = usePromise(getManufacturer());
  const deviceName = usePromise(getDeviceName());
  const emulator = usePromise(isEmulator());

  const data: DeviceData = useMemo(
    () => ({
      deviceManagedId: getUniqueId(),
      appVersion: `${getVersion()}.${getBuildNumber()}`,
      deviceType: `${manufacturer.value ?? '<error>'} ${getDeviceId()}${
        emulator ? ' (Emulator)' : ''
      }`,
      name: deviceName.value ?? '<error>',
      platformType: Platform.OS,
      systemVersion: getSystemVersion()
    }),
    [manufacturer, deviceName, emulator]
  );

  if (manufacturer.loading || deviceName.loading || emulator.loading) {
    return <RetainSplash />;
  }

  return (
    <DeviceInfoManagerContext.Provider value={data}>
      {children}
    </DeviceInfoManagerContext.Provider>
  );
};
