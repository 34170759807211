import { ReactNode } from 'react';
import { UserSessionApiProvider } from './UserSessionApiProvider';
import { UserSessionContextProvider } from './UserSessionContext';
import { UserLaunchArgumentsHandler } from './UserLaunchArgumentsHandler';
import { UserSubscriptionContextProvider } from './UserSubscriptionContext';

export const UserManager = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  return (
    <UserLaunchArgumentsHandler>
      <UserSessionContextProvider>
        <UserSessionApiProvider>
          <UserSubscriptionContextProvider>
            {children}
          </UserSubscriptionContextProvider>
        </UserSessionApiProvider>
      </UserSessionContextProvider>
    </UserLaunchArgumentsHandler>
  );
};
