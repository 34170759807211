export function sleep(
  ms: number,
  { signal }: { signal: AbortSignal }
): Promise<void> {
  return new Promise(resolve => {
    let cancel: number;

    const abort = () => {
      clearTimeout(cancel);
      resolve();
    };

    cancel = setTimeout(() => {
      signal.removeEventListener('abort', abort);
      resolve();
    }, ms);

    signal.addEventListener('abort', abort);
  });
}
