import { StatusBar } from 'expo-status-bar';
import { ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colours } from '../../colours';
import { HorizontalRule } from '../horizontal-rule';
import { nameof } from '../../lib/name-of';
import {
  Heading,
  Body,
  Tags,
  Video,
  useRecipeContext,
  HeroImage
} from './components';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colours.white.$
  },
  content: {
    alignItems: 'stretch'
  },
  card: {
    borderRadius: 20,
    backgroundColor: Colours.white.$,
    marginTop: -20,
    padding: 23
  },

  divider: {
    marginVertical: 24
  }
});

export const SmallRecipeShowScreen = (): JSX.Element => {
  const { recipe } = useRecipeContext();

  if (recipe?.attributes?.video?.data?.attributes)
    return (
      <View style={styles.container}>
        <StatusBar style="auto" />
        <SafeAreaView edges={['top']}>
          <Video style={{ alignSelf: 'center' }} />
        </SafeAreaView>
        <ScrollView
          contentContainerStyle={styles.content}
          testID={nameof({ SmallRecipeShowScreen })}
        >
          <View style={styles.card}>
            <SafeAreaView edges={['bottom']}>
              <Heading />
              <HorizontalRule
                color={Colours.accentLight5.$}
                style={styles.divider}
              />
              <Tags />
              <HorizontalRule
                color={Colours.accentLight5.$}
                style={styles.divider}
              />
              <Body />
            </SafeAreaView>
          </View>
        </ScrollView>
      </View>
    );

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      testID={nameof({ SmallRecipeShowScreen })}
    >
      <StatusBar style="auto" />
      <SafeAreaView edges={['top']}>
        <HeroImage />
      </SafeAreaView>
      <View style={styles.card}>
        <SafeAreaView edges={['bottom']}>
          <Heading />
          <HorizontalRule
            color={Colours.accentLight5.$}
            style={styles.divider}
          />
          <Tags />
          <HorizontalRule
            color={Colours.accentLight5.$}
            style={styles.divider}
          />
          <Body />
        </SafeAreaView>
      </View>
    </ScrollView>
  );
};
