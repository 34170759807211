import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { useCallback, useEffect, useState } from 'react';
import Animated, {
  SharedValue,
  useAnimatedStyle
} from 'react-native-reanimated';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colours } from '../../../../colours';
import { SearchBar } from '../../../../components/search-bar';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { useDebounce } from '../../../../lib/use-debounce';
import { RecipesNavigatorParamList } from '../../RecipesNavigatorParamList';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    backgroundColor: Colours.white.$,
    borderBottomWidth: 0,
    shadowColor: '#34715D',
    shadowRadius: 36,
    shadowOffset: { width: 0, height: 6 }
  },
  searchBar: {
    marginTop: 8,
    marginBottom: 16,
    marginHorizontal: 29
  }
});

export type RecipeListScreenHeaderProps = {
  elevation?: SharedValue<number>;
  onFocussedChanged?: (focussed: boolean) => void;
};

export const RecipeListScreenHeader = ({
  elevation,
  onFocussedChanged
}: RecipeListScreenHeaderProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const elevationStyle = useAnimatedStyle(() => ({
    elevation: elevation?.value ? elevation.value * 30 : 0,
    shadowOpacity: elevation?.value ? elevation.value * 0.3 : 0
  }));

  const [searchValue, setSearchValue] = useState('');

  const route = useRoute<RouteProp<RecipesNavigatorParamList, 'list'>>();

  useEffect(() => {
    setSearchValue(route.params?.search ?? '');
  }, []);

  const debouncedSearchValue = useDebounce(searchValue, 250);

  const navigation =
    useNavigation<NavigationProp<RecipesNavigatorParamList, 'list'>>();

  useEffect(() => {
    navigation.setParams({ search: debouncedSearchValue });
  }, [debouncedSearchValue]);

  const onClear = useCallback(() => {
    setSearchValue('');
    navigation.setParams({});
  }, [navigation, setSearchValue]);

  return (
    <Animated.View style={[styles.container, elevationStyle]}>
      <SafeAreaView mode="padding" edges={['top', 'left', 'right']}>
        <SearchBar
          style={styles.searchBar}
          placeholder="Search recipes"
          onChangeText={setSearchValue}
          onFocussedChanged={onFocussedChanged}
          value={searchValue}
          onClear={onClear}
        />
      </SafeAreaView>
    </Animated.View>
  );
};
