import { StatusBar } from 'expo-status-bar';
import { ActivityIndicator, View } from 'react-native';
import {
  SafeAreaView,
  useSafeAreaInsets
} from 'react-native-safe-area-context';
import { Colours } from '../../../../colours';
import { PrimaryButton } from '../../../../components/primary-button';
import { Text } from '../../../../components/text';
import { useSubscription } from '../../../../components/user-manager';
import { nameof } from '../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    backgroundColor: Colours.primaryLight35.$,
    paddingVertical: 24
  },
  content: {
    flex: 1,
    alignItems: 'center',
    marginTop: 80
  },
  title: {
    textAlign: 'center',
    color: Colours.accentLight100.$,
    marginVertical: 20
  },
  button: {
    position: 'absolute'
  }
}).override(
  { medium: true, large: true },
  {
    container: {
      paddingVertical: 98
    }
  }
);

export const ChoosePlanScreen = (): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const { bottom } = useSafeAreaInsets();

  const { polling } = useSubscription();

  return (
    <View style={styles.container} testID={nameof({ ChoosePlanScreen })}>
      <StatusBar style="dark" />
      <SafeAreaView style={styles.content}>
        {polling ? (
          <ActivityIndicator />
        ) : (
          <>
            <Text.Title style={styles.title}>Nearly there!</Text.Title>
            <Text.Body>Choose one of our plans</Text.Body>
            <PrimaryButton
              style={[styles.button, { bottom }]}
              title="Continue"
              onPress={() => {}}
            />
          </>
        )}
      </SafeAreaView>
    </View>
  );
};
