import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import { useCallback, useEffect, useMemo } from 'react';
import { Pressable, ScrollView, Text, View } from 'react-native';
import { Colours } from '../../../../colours';
import { CheckBox } from '../../../../components/check-box';
import { Fonts } from '../../../../fonts';
import { fractionify } from '../../../../humanisers/fractionify/fractionify';
import { nameof } from '../../../../lib/name-of';
import {
  ResponsiveStyleSheet,
  useResponsiveStyleSheet
} from '../../../../lib/responsive-style-sheet';
import { SkipUpdate } from '../../../../lib/table-store';
import { useShoppingList } from '../../../../resources/shopping-list/useShoppingList';
import { MainNavigatorParamList } from '../../MainNavigatorParamList';
import { ClearButton } from './ClearButton';

const rStyles = ResponsiveStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colours.white.$
  },
  content: {
    alignItems: 'stretch',
    padding: 8
  },
  emptyContainer: {
    borderRadius: 20,
    padding: 22,
    backgroundColor: Colours.primaryLight5.$
  },
  emptyText: {
    fontFamily: Fonts.DMSans_500Medium,
    fontSize: 16,
    letterSpacing: -0.2,
    color: Colours.accentLight75.$,
    textAlign: 'center'
  },
  group: {
    padding: 29
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8
  },
  rowText: {
    fontFamily: Fonts.DMSans_700Bold,
    fontSize: 14,
    color: Colours.accentLight100.$,
    marginHorizontal: 14,
    letterSpacing: -0.2
  }
}).override(
  { large: true, medium: true },
  {
    content: {
      padding: 28
    },
    emptyContainer: {
      padding: 32,
      flexDirection: 'row'
    }
  }
);

type ShoppingListScreenProps = StackScreenProps<
  MainNavigatorParamList,
  'shopping-list'
>;

export const ShoppingListScreen = ({
  navigation
}: ShoppingListScreenProps): JSX.Element => {
  const styles = useResponsiveStyleSheet(rStyles);

  const { rows, update } = useShoppingList();

  const isEmpty = useMemo(() => rows.none(), [rows]);

  const groups = useMemo(() => {
    return rows
      .groupBy(r => r.productGroupName)
      .map(({ key: groupName, values: items }) => {
        return {
          groupName,
          products: items
            .groupBy(v => v.productName)
            .map(({ key: productName, values }) => {
              const quantityRequired = values.sum(v => v.quantityRequired);
              const quantityChecked = values.sum(v => v.quantityChecked);

              const checkState = (() => {
                if (quantityChecked === quantityRequired) return true;
                if (quantityChecked === 0) return false;
                return null;
              })();

              const text = [
                fractionify(quantityRequired),
                values.first(v => !!v.unit)?.unit,
                productName
              ]
                .compact()
                .join(' ');

              return {
                text,
                checkState,
                keys: values.map(v => v.compositeKey)
              };
            })
        };
      });
  }, [rows]);

  const onRowPress = useCallback(
    (
      keys: (
        | `${string}-breakfast-${number}`
        | `${string}-lunch-${number}`
        | `${string}-dinner-${number}`
      )[],
      newState: boolean
    ) => {
      keys.forEach(key => {
        update(key, old => {
          if (!old) return SkipUpdate;

          return {
            ...old,
            quantityChecked: newState ? old.quantityRequired : 0
          };
        });
      });
    },
    [rows]
  );

  const showClearButton = useMemo(() => {
    return groups.some(g => g.products.some(p => !!p.checkState));
  }, [groups]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (showClearButton ? <ClearButton /> : <></>)
    });
  }, [showClearButton]);

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      testID={nameof({ ShoppingListPage: ShoppingListScreen })}
    >
      <StatusBar style="dark" />
      {isEmpty && (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>
            Ingredients for the recipes you add to your Planner will appear
            here.
          </Text>
        </View>
      )}
      {groups.map(({ groupName, products }) => (
        <View key={groupName} style={styles.group}>
          <Text>{groupName}</Text>
          {products.map(({ text, checkState, keys }) => (
            <Pressable
              key={text}
              style={styles.row}
              onPress={() => onRowPress(keys, !checkState)}
            >
              <CheckBox checked={checkState} />
              <Text style={styles.rowText}>{text}</Text>
            </Pressable>
          ))}
        </View>
      ))}
    </ScrollView>
  );
};
