import Svg, { SvgProps, Path } from 'react-native-svg';

export const MailboxIcon = (props: SvgProps) => (
  <Svg {...props} width={50} height={49} fill="none">
    <Path
      d="M42.967 4.9a1.633 1.633 0 0 0-.463.07 1.63 1.63 0 0 0-.13.042L6.461 16.432v.007A1.634 1.634 0 0 0 5.4 17.967a1.634 1.634 0 0 0 .788 1.394L17.1 28.02l21.575-17.195L21.48 32.4l8.652 10.907a1.634 1.634 0 0 0 2.33.502c.273-.19.482-.458.598-.77h.007l11.43-35.943a1.633 1.633 0 0 0 .102-.561A1.633 1.633 0 0 0 42.967 4.9Z"
      fill="#004E34"
    />
  </Svg>
);
