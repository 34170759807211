type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value ? P : never]: T[P];
};

export type IdFields<T> = keyof PickByType<T, string>;

export const SkipUpdate = Symbol('SKIP');

export interface Table<
  TModel extends Record<string, unknown>,
  Id extends IdFields<TModel>
> {
  read: (id: TModel[Id]) => TModel | null;
  readAll: () => TModel[];
  update: (
    id: TModel[Id],
    fn: (data: TModel | null) => TModel | typeof SkipUpdate
  ) => TModel | null;
  delete: (id: TModel[Id]) => void;
  deleteAll: () => void;
}
