import Svg, { SvgProps, Path } from 'react-native-svg';

export const SadSearchIcon = (props: SvgProps) => (
  <Svg {...props} width={35} height={34} fill="none">
    <Path
      d="M14.667 2.833c-6.243 0-11.334 5.09-11.334 11.333S8.424 25.5 14.667 25.5c2.867 0 5.485-1.081 7.484-2.848l1.016 1.016V25.5l5.666 5.666 2.834-2.833L26 22.666h-1.832l-1.015-1.015c1.766-2 2.847-4.617 2.847-7.485 0-6.242-5.09-11.333-11.333-11.333Zm0 2.833c4.71 0 8.5 3.79 8.5 8.5 0 4.712-3.79 8.5-8.5 8.5a8.479 8.479 0 0 1-8.5-8.5c0-4.71 3.789-8.5 8.5-8.5Zm-4.25 5.667a1.417 1.417 0 1 0 0 2.834 1.417 1.417 0 0 0 0-2.834Zm8.5 0a1.417 1.417 0 1 0 0 2.834 1.417 1.417 0 0 0 0-2.834Zm-4.25 4.245a8.477 8.477 0 0 0-4.931 1.568l1.646 2.308c1.928-1.377 4.638-1.377 6.566 0l1.647-2.308a8.469 8.469 0 0 0-4.928-1.569Z"
      fill="#739E90"
    />
  </Svg>
);
