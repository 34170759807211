/* eslint-disable no-underscore-dangle */
import { MMKV } from 'react-native-mmkv';
import { AnyValue, KeyValueStore } from './types';
import { assertNever } from '../assert-never';
import { createLogger } from '../logging';
import { nameof } from '../name-of';

export const keyValueStore = <TModel extends Record<string, AnyValue>>(
  model: TModel,
  name: string
): KeyValueStore<TModel> => {
  const log = createLogger(nameof({ keyValueStore }), { name });

  const storage = new MMKV();

  const prefix = `${name ?? 'KVS_NO_NAME'}/`;

  return {
    get: originalKey => {
      const type = model[originalKey];

      const key = `${prefix}${originalKey as string}`;

      switch (type._type) {
        case 'string':
          return storage.getString(key) ?? null;
        case 'number':
          return storage.getNumber(key) ?? null;
        case 'map':
        case 'array': {
          const serialized = storage.getString(key);

          if (typeof serialized === 'undefined') {
            return type.defaultValue;
          }

          return JSON.parse(serialized) as typeof model[typeof key];
        }
        case 'boolean':
          return storage.getBoolean(key) ?? null;
        default:
          return assertNever(type);
      }
    },
    set: (originalKey, value) => {
      const type = model[originalKey];
      const key = `${prefix}${originalKey as string}`;
      log.debug('Set', { key });
      switch (type._type) {
        case 'string':
          storage.set(key, value as string);
          return;
        case 'number':
          storage.set(key, value as number);
          return;
        case 'map':
        case 'array':
          storage.set(key, JSON.stringify(value));
          return;
        case 'boolean':
          storage.set(key, value as boolean);
          return;
        default:
          assertNever(type);
      }
    },
    clearAll: () => {
      storage.clearAll();
      log.debug('clear all');
    }
  };
};
