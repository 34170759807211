import { useMemo } from 'react';
import { View } from 'react-native';
import { MountProps } from './MountProps';

export const EagerMount = ({
  visible,
  component: Component
}: MountProps): JSX.Element => {
  const memoizedChildren = useMemo(() => <Component />, []);

  return (
    <View style={{ display: visible ? 'flex' : 'none' }}>
      {memoizedChildren}
    </View>
  );
};
